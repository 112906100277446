import { Button, Dialog, DialogActions, DialogContent, ThemeProvider, Typography, useMediaQuery, useTheme } from "@mui/material";

interface GameCompleteDialogProps {
    open: boolean;
    onClose: (success: boolean) => void;
    success: boolean;
    score: number;
    bp: number;
}

/* 
Dialog that shows when the team finishes a game showing how many points + bonus points they earned. We used to have the default
popup that appeared but this one looks alot nicer. Mainly just styling here
*/

const GameCompleteDialog: React.FC<GameCompleteDialogProps> = ({open, onClose, success, score, bp}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={theme}>
        <Dialog open={open} maxWidth="sm" fullWidth={true} PaperProps={{
        style: {
          backgroundColor: theme.palette.background.paper,
          borderRadius: '15px',
          padding: '20px'
        }
      }}>
            <DialogContent>
            <Typography variant='h4' textAlign={"center"}>
                {success ? `Congratulations! Your score is ${score} (including ${bp} bonus points)!` : `Game Over! Your score is ${score} (including ${bp} bonus points)!`}
            </Typography>
            </DialogContent>
            <DialogActions style={{justifyContent: 'center'}}>
            <Button onClick={() => onClose(success)} variant={"contained"} color="turquoise" 
            style={{ backgroundColor: theme.palette.turquoise.main,
            color: theme.palette.turquoise.contrastText,
            padding: isSmallScreen ? '10px 20px' : '10px 40px',
            borderRadius: '25px',
            fontWeight: 'bold'}}>Close</Button>
            </DialogActions>
        </Dialog>
        </ThemeProvider>
    )
}

export default GameCompleteDialog;