import React, {useState, useEffect, useRef} from 'react';
import {
    TextField,
    Button,
    Snackbar,
    Alert,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import {useSocket} from './contexts/SocketContext';
import {theme} from './palette';
import {ThemeProvider} from '@mui/material/styles';
import {useNavigate} from 'react-router-dom';

const dingSound = require('./assets/ding.mp3');

interface AlertHistoryItem {
    team: string;
    message: string;
    timestamp: string;
}

const FacilitatorDashboard: React.FC = () => {
    const [message, setMessage] = useState<string>('');
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [confirm, setConfirm] = useState<boolean>(false);
    const {socket} = useSocket();
    const socketRef = useRef(socket);
    const navigate = useNavigate();

    const [zoomLinks, setZoomLinks] = useState([
        {team: 'Team 1', tid: 'team1'},
        {team: 'Team 2', tid: 'team2'},
        {team: 'Team 3', tid: 'team3'},
        {team: 'Team 4', tid: 'team4'},
        {team: 'Team 5', tid: 'team5'},
        {team: 'Team 6', tid: 'team6'}
    ]);

    const [alertHistory, setAlertHistory] = useState<AlertHistoryItem[]>([]);

    const playDing = () => {
        const audio = new Audio(dingSound);
        audio.play();
    };

    useEffect(() => {
        if (!socket) return;
        const token = localStorage.getItem('jwt');
        if (!token) return;

        socket.on('broadcastMessage', (data: { message: string }) => {
            setSnackbarMessage(data.message);
            setSnackbarOpen(true);
        });

        socket.on('navigateToFinale', () => {
            navigate('/finale-game');
        });

        socket.on('facilitatorAlert', (data) => {
            setSnackbarMessage(data.message);
            setSnackbarOpen(true);
            playDing();
            const newAlert = {
                team: data.teamName,
                message: data.message,
                timestamp: new Date().toLocaleString()
            };
            setAlertHistory((prevHistory) => [newAlert, ...prevHistory]);
        });

        socketRef.current = socket;
        return () => {
            socket.off('broadcastMessage');
            socket.off('facilitatorAlert');
            socket.off('navigateToFinale');
        };
    }, [socket]);

    const handleSendMessage = () => {
        if (message.trim() && socketRef.current) {
            socketRef.current.emit('broadcastMessage', {target: 'all', message});
            setMessage('');
        }
    };

    const handleChangeTeamLeader = (teamName: string) => {
        if (socket) {
            socket.emit('randomSelectFac', {teamName: teamName});
        }
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleWipeData = () => {
        if (socket) {
            socket.emit('resetButton');
        }
        setConfirm(false);
    }

    const handleOpenConfirm = () => {
        setConfirm(true);
    }

    const handleCloseConfirm = () => {
        setConfirm(false);
    }

    const handleNavigateToFinale = () => {
        if (socketRef.current) {
            socketRef.current.emit('navigateToFinale');
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1, mt: 4}}>
                <Typography variant="h6">Facilitator Dashboard</Typography>
                <TextField
                    data-cy="broadcast-message"
                    label="Broadcast Message to All Teams"
                    fullWidth
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    sx={{my: 2}}
                />
                <Button onClick={handleSendMessage} variant="contained" color="turquoise" data-cy="send-message-button">
                    Send Message
                </Button>
                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Team Lists</Typography>
                        <List>
                            {zoomLinks.map((zoom, index) => (
                                <ListItem key={index} sx={{display: 'flex', alignItems: 'center'}}>
                                    <ListItemText primary={zoom.team}/>
                                    <Button variant="contained" color="turquoise"
                                            onClick={() => handleChangeTeamLeader(zoom.tid)}
                                            data-cy={`change-team-leader-${zoom.team}`}>
                                        Change Team Leader
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">Alert History</Typography>
                        <List sx={{maxHeight: 400, overflow: 'auto'}}>
                            {alertHistory.map((alert, index) => (
                                <ListItem key={index}>
                                    <ListItemText primary={`${alert.team} - ${alert.message}`}
                                                  secondary={alert.timestamp}
                                                  data-cy={`alert-history-${index}`}/>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
                <Button onClick={handleOpenConfirm} data-cy="reset-database-button">{"Reset Points & Database"}</Button>
                <Button onClick={handleNavigateToFinale} variant="contained" color="turquoise" data-cy="navigate-finale-button">
                    Navigate to Finale
                </Button>
                <Snackbar open={snackbarOpen} autoHideDuration={null} onClose={handleCloseSnackbar} data-cy="snackbar">
                    <Alert onClose={handleCloseSnackbar} severity="info" sx={{width: '100%'}}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
            <Dialog open={confirm} data-cy="confirm-reset-dialog">
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">This will reset all teams' points and players, which may cause issues if
                        the game is still being played.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} color="tur2" data-cy="cancel-reset-button">Cancel</Button>
                    <Button onClick={handleWipeData} color="turquoise" data-cy="confirm-reset-button">Yes</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default FacilitatorDashboard;
