import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

/* 
One of the features requested was not being able to back when playing the game, this is because lets say once you finish one game you can essentially click the back button
and replay the whole game thus doubling your points. This uses location and pop states by pushing the same state to the user if they click the backbutton. Stopping them
from playing the game again. Doesnt work how I wanted it too but at least they cant play again.

*/

const usePreventNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      // Prevent back navigation by pushing the same state again
      event.preventDefault();
      window.history.pushState(null, document.title, location.pathname);
      navigate(location.pathname, { replace: true });
    };

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = ''; // Most browsers show a confirmation dialog
    };

    // Push the current state to the history stack to prevent back navigation
    window.history.pushState(null, document.title, location.pathname);
    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, location]);
};

export default usePreventNavigation;
