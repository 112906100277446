import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Box, Card, CardContent, AppBar, Toolbar, Button, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled, ThemeProvider } from '@mui/material/styles';
import LockIcon from '@mui/icons-material/Lock';
import { useSocket } from '../contexts/SocketContext';
import { useTeam } from '../contexts/TeamContext';
import StaticLeaderboard from '../components/StaticLeaderboard';
import { theme } from '../palette';
import Logos from '../components/Logos';
import TeamList from '../components/TeamList';
import image from '../assets/lobby5.png';
import usePreventNavigation from '../components/PreventNavigation';

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(5),
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexGrow: 1,
}));

const FifthLobby: React.FC = () => {
  usePreventNavigation();
  const [passwordInput, setPasswordInput] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { teamName } = useTeam();
  const [accessGranted, setAccessGranted] = useState(true);
  const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
  const [alerted, setAlerted] = useState(false);
  const [waiting, setWaiting] = useState(true);

  useEffect(() => {
    if(socket) {
      socket.emit('joinLobby');
    }
  }, [])

  useEffect(() => {
    if (accessGranted) {
      navigate('/insider-insights');
    }

    if (socket) {
      socket.on('broadcastMessage', (data) => {
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
      });

      socket.on('stopWaiting', () => {
        setWaiting(false);
      });

      return () => {
        socket.off('stopWaiting')
        socket.off('broadcastMessage');
      };
    }
  }, [socket, accessGranted, navigate]);

  const handleAlertFacilitator = () => {
    if (socket) {
      socket.emit('alertFacilitator', { teamName });
      setAlerted(true);
      setTimeout(() => {
        setAlerted(false);
      }, 1000)
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordInput(e.target.value);
  };

  const handlePasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (passwordInput === 'proclaim-chimney') {
      setAccessGranted(true);
    } else {
      setSnackbarMessage('Incorrect password, please try again.');
      setSnackbarOpen(true);
    }
  };

  const handleNoPasswordSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAccessGranted(true);
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar style={{top: 0}} position="sticky" color="turquoise">
          <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" component="div" sx={{ mr: 2 }}>
                Escape Room Lobby
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }} gap={2}>
              <TeamList isLeader={false}/>
              <Button variant="contained" color="tur2" onClick={handleAlertFacilitator} sx={{width: 200,  ml: 2}}>
                {alerted ? "Facilitator Alerted" : "Alert Facilitator"}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" component="div" sx={{ mr: 2 }} visibility={"hidden"}>
                Escape Room Lobby
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>

        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2) }}>
          <img src={image} alt="Fifth Lobby" style={{ width: '100%', maxHeight: '300px', objectFit: 'cover' }} />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'stretch', padding: theme.spacing(2), flexGrow: 1 }}>
          <Box sx={{ flex: 1, marginRight: theme.spacing(2), display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch' }}>
            <StyledCard sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <CardContent>
                <StaticLeaderboard refresh={refreshLeaderboard} />
              </CardContent>
            </StyledCard>
          </Box>

          <Box sx={{ flex: 1, marginLeft: theme.spacing(2), display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'stretch' }}>
            <StyledCard sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <CardContent>
                <Typography variant="h5" gutterBottom textAlign={"center"}>
                {waiting ? "Please wait for all players to finish..." : "Advance to the Next Room"}
                </Typography>
                <StyledForm onSubmit={handleNoPasswordSubmit}>
                  <SubmitButton variant="contained" color="turquoise" type="submit">
                    Continue
                  </SubmitButton>
                </StyledForm>
              </CardContent>
            </StyledCard>
          </Box>
        </Box>

        <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto' }}>
          <Logos />
        </Box>
      </Box>

      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={null} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default FifthLobby;
