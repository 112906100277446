import React, {useEffect, useState} from 'react';
import {Box, Container, Typography, Paper} from '@mui/material';
import {useSocket} from '../contexts/SocketContext';
import {useTeam} from '../contexts/TeamContext';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from '../palette';
import axios from 'axios';
import StaticLeaderboard from '../components/StaticLeaderboard'; // Make sure the path is correct
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize'; // For responsive confetti
import usePreventNavigation from '../components/PreventNavigation';
import goldtrophy from '../assets/goldtrophy.png';
import silvertrophy from '../assets/silvertrophy.png';
import bronzetrophy from '../assets/bronzetrophy.png';

/* 
This is the endscreen showing the top3 teams and the leaderboard once the game ends. T
*/


// Import trophy image
import trophy from '../assets/trophy.png';

interface Team {
    teamName: string;
    points: number;
}

const medalColors = ['#FFD700', '#C0C0C0', '#CD7F32']; // Gold, Silver, Bronze

const EndScreenPodium: React.FC = () => {
    usePreventNavigation();
    const {socket} = useSocket();
    const [leaderboard, setLeaderboard] = useState<Team[]>([]);
    const {width, height} = useWindowSize(); // For responsive confetti
    const [confettiActive, setConfettiActive] = useState(true); // Confetti state
    const trophyImages = [goldtrophy, silvertrophy, bronzetrophy];

    useEffect(() => {
        const fetchTopThreeTeams = async () => {
            try {
                const response = await axios.get('/api/leaderboard/top3');
                setLeaderboard(response.data);
            } catch (error) {
                console.error('Error fetching top three teams:', error);
            }
        };

        fetchTopThreeTeams();

        if (socket) {
            socket.on('showEndScreen', fetchTopThreeTeams);
            return () => {
                socket.off('showEndScreen', fetchTopThreeTeams);
            };
        }
    }, [socket]);

    // Stop confetti after 6 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setConfettiActive(false);
        }, 6000);
        return () => clearTimeout(timer);
    }, []);

    // Define the order in which to display the podium positions
    const displayOrder = [1, 0, 2]; // Second place, First place, Third place

    const podiumMedalColors = displayOrder.map((i) => medalColors[i]);
    const podiumTrophyImages = displayOrder.map((i) => trophyImages[i]);

    return (
        <ThemeProvider theme={theme}>
            {confettiActive && <Confetti width={width} height={height}/>}
            <Box
                sx={{backgroundColor: theme.palette.turquoise.main, minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', py: 4}}>
                <Container>
                    <Typography variant="h4" align="center" gutterBottom sx={{color: 'white'}}>
                        Congratulations on Finishing the Escape Room!
                    </Typography>
                    <Box sx={{display: 'flex', width: '100%', maxWidth: 1200, mt: 4}}>
                        <Paper elevation={4}
                               sx={{padding: 4, borderRadius: 2, backgroundColor: theme.palette.common.white, flex: 1, marginRight: 2, height: '100%'}}>
                            <Box
                                sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
                                <Typography variant="h4" align="center" gutterBottom
                                            sx={{color: theme.palette.turquoise.dark,
                                                marginBottom: '60px'}}>
                                    Top Teams!
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-end',
                                        mt: 4,
                                        position: 'relative',
                                    }}
                                >
                                    {displayOrder.map((positionIndex, displayIndex) => {
                                        const team = leaderboard[positionIndex];
                                        if (!team) return null; // In case there are fewer than 3 teams
                                        return (
                                            <Box
                                                key={positionIndex}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    mx: 2,
                                                    px: 3,
                                                    py: 4,
                                                    borderRadius: 2,
                                                    boxShadow: 3,
                                                    width: 150,
                                                    // Adjust the height to make the first-place team taller and in the middle
                                                    height:
                                                        displayIndex === 1
                                                            ? 250 // First place (now in the middle)
                                                            : displayIndex === 0
                                                                ? 200 // Second place (now on the left)
                                                                : 150, // Third place (now on the right)
                                                    backgroundColor: podiumMedalColors[displayIndex],
                                                    position: 'relative',
                                                }}
                                            >
                                                <Typography variant="h4" sx={{color: 'white', mb: 1}}>
                                                    {positionIndex + 1}
                                                </Typography>
                                                <Typography variant="h6" sx={{fontWeight: 'bold', color: 'white'}}>
                                                    {team.teamName}
                                                </Typography>
                                                <Typography variant="body1" sx={{color: 'white'}}>
                                                    {team.points} Points
                                                </Typography>

                                                <img
                                                    src={podiumTrophyImages[displayIndex]}
                                                    alt={`Trophy`}
                                                    style={{
                                                        width: '50px',
                                                        position: 'absolute',
                                                        top:
                                                            displayIndex === 1
                                                                ? '-78px' // Adjust trophy position for first place
                                                                : displayIndex === 0
                                                                    ? '-78px' // Adjust trophy position for second place
                                                                    : '-80px', // Adjust trophy position for third place
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </Paper>
                        <Box sx={{flex: 1, marginLeft: 2, height: '100%'}}>
                            <Box sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                                <StaticLeaderboard refresh={false}/>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default EndScreenPodium;
