import React, { useState, useEffect } from 'react';
import { useSocket } from '../contexts/SocketContext';
import axios from 'axios';
import { Box, Button, Collapse, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import { theme } from '../palette';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

// This component contains the dropdown leaderboard
// that updates in real-time.

interface Team {
  teamName: string;
  points: number;
}

// Styling

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  border: '1px solid #ddd',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2),
  maxHeight: 400,
  overflowY: 'auto',
  borderRadius: '8px',
  width: '200px', 
}));

const StyledTable = styled(Table)(({ theme }) => ({
  '& thead th': {
    backgroundColor: theme.palette.turquoise.main,
    color: theme.palette.common.white,
  },
  '& tbody tr:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Leaderboard: React.FC<{ refresh: boolean }> = ({ refresh }) => {
  const [leaderboard, setLeaderboard] = useState<Team[]>([]);
  const [open, setOpen] = useState(false);
  const { socket } = useSocket();

  useEffect(() => {
    // fetch the latest leaderboard data using API endpoint (see server.js) & refresh leaderboard state
    const fetchLeaderboard = async () => {
      try {
        const response = await axios.get('/api/leaderboard');
        setLeaderboard(response.data);
        console.log("Leaderboard Fetched: ", response.data);
      } catch (error) {
        console.error('Error fetching leaderboard:', error);
      }
    };

    fetchLeaderboard();
  }, [refresh]);

  // event handlers

  useEffect(() => {
    if (socket) {
      socket.on('scoreUpdated', (updatedLeaderboard) => {
        setLeaderboard(updatedLeaderboard);
      });

      return () => {
        socket.off('scoreUpdated');
      };
    }
  }, []);

  // close leaderboard when clicked outside

  const handleClickOut = (ev: any) => {
    if(ev.target.id !== "leaderboardButton") {
      setOpen(false);
    }
  }

  return (
    <ThemeProvider theme = {theme}>
    <Box sx={{ position: 'relative' }}>
      <Button variant="contained" color="tur2" id="leaderboardButton" onClick={() => setOpen(!open)} startIcon={<LeaderboardIcon />}>
      {/* <LeaderboardIcon /> */}
        {open ? 'Leaderboard' : 'Leaderboard'}
      </Button>
      <ClickAwayListener onClickAway={handleClickOut}>
      <Collapse in={open} sx={{ position: 'absolute', top: '100%', zIndex: 1, transform: 'translateX(-25%)', width: '100%' }}>
        <StyledPaper sx={{ mt: 1 }}>
          <Typography variant="h6" gutterBottom>
            Leaderboard
          </Typography>
          <StyledTable size="small">
            <TableHead>
              <TableRow>
                <TableCell>Team Name</TableCell>
                <TableCell align="right">Score</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboard.map((team, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {team.teamName}
                  </TableCell>
                  <TableCell align="right">{team.points}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </StyledPaper>
      </Collapse>
      </ClickAwayListener>
    </Box>
    </ThemeProvider>
  );
};

export default Leaderboard;
