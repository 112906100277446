export const ItemTypes = {
    CARD: 'card',
};

export interface Card {
    id: number;
    text: string;
    policy: string;
    img: string
}

export interface CardComponentProps {
    id: number;
    text: string;
    policy: string;
    img: string;
    isLeader: boolean;
}

export interface BucketProps {
    policy: string;
    label: string;
    onDrop: (policy: string) => void;
}
