import React, {useState, useEffect,ChangeEvent} from 'react';
import {
    Container, Typography, Button, Box, Select, MenuItem, Grid,
    IconButton, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Alert, LinearProgress
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import {useTeam} from '../../contexts/TeamContext';
import {useSocket} from '../../contexts/SocketContext';
import InstructionPopup from '../../components/InstructionPopup';
import {theme} from '../../palette';
import {ThemeProvider} from '@mui/material/styles';
import Logos from '../../components/Logos';
import axios from 'axios';
import usePreventNavigation from '../../components/PreventNavigation';

// all the clue images

import clue1 from './clues/clue1.png';
import clue2 from './clues/clue2.png';
import clue3 from './clues/clue3.png';
import clue4 from './clues/clue4.png';
import clue5 from './clues/clue5.png';
import clue6 from './clues/clue6.png';
import clue7 from './clues/clue7.png';
import clue8 from './clues/clue8.png';
import clue9 from './clues/clue9.png';
import clue10 from './clues/clue10.png';
import clue11 from './clues/clue11.png';
import clue12 from './clues/clue12.png';
import clue13 from './clues/clue13.png';
import clue14 from './clues/clue14.png';
import clue15 from './clues/clue15.png';
import clue16 from './clues/clue16.png';
import clue17 from './clues/clue17.png';
import clue18 from './clues/clue18.png';
import clue19 from './clues/clue19.png';
import clue20 from './clues/clue20.png';
import blankclue from './clues/blankclue.png';
import GameCompleteDialog from '../../components/GameCompleteDialog';
import FeedbackDialog from './FeedbackDialog';
import GameHeader from "../../components/GameHeader";


const suspects = ["Alice", "Bob", "Eve", "Mallory", "Oscar", "Trent"];
const descriptions = ["Disgruntled employee spreading company rumours.", "Recent hire who knows more than he should about the company.", "Data Analyst who feels underappreciated.",
    "Contractor with privileged access.", "System administrator with too much power.", "Software developer paying top dollar for rent."];
const weapons = ["USB Drive", "Keylogger", "Rootkit", "Zero-Day Exploit", "Backdoor", "Unauthorized Access"];
const rooms = ["CEO's Office", "CFO's Office", "HR's Office", "Reception", "Games Room", "Kitchen"];

// All the clues for the game
const clues = [
    "Clue 1: Alice was seen near the CEO's office but does not have the knowledge to use a rootkit.",
    "Clue 2: Bob was not in the kitchen on the day of the leak and does not know how to use a zero-day exploit.",
    "Clue 3: Eve was spotted in the reception area but has no access to stolen credentials.",
    "Clue 4: Malory was working in the CFO's office but has no experience with a key logger.",
    "Clue 5: Oscar was not near the games room but is proficient with rootkit attacks.",
    "Clue 6: Trent was seen in the kitchen but has no means to perform a zero-day exploit.",
    "Clue 7: The leak did not occur in the CEO's office using a key logger.",
    "Clue 8: Bob was seen in the games room on the day of the leak.",
    "Clue 9: Alice has been known to shoulder surf (watch colleagues type their password) but was never in the CFO's office.",
    "Clue 10: A keylogger has not been found on any computers at the reception desk.",
    "Clue 11: Eve has never used a rootkit attack and was not in the games room.",
    "Clue 12: Malory was not seen in the kitchen and has always been careful to look away when someone is typing in their password.",
    "Clue 13: Oscar had access to the CFO's office and is commonly heard talking about new zero-day attacks.",
    "Clue 14: Trent was not near the CEO's office and has lectured coworkers about the importance of never sharing their password.",
    "Clue 15: There is no evidence of a rootkit being used on any computers in the games room.",
    "Clue 16: The CEO never works in her office and always keeps her laptop with her.",
    "Clue 17: There was no evidence of malware coming from the CFO's office.",
    "Clue 18: There was a laptop found in the games room that was logged in with the credentials of a user that was away on vacation.",
    "Clue 19: The janitor reported that he found a sticky note with a password written on it crumpled up under Bob's desk.",
    "Clue 20: Bob handed in his resignation a week later, saying he suddenly came into a windfall of money.",
    "All clues have been shown. Please make your decision now."
];


const images = [
    clue1, clue2, clue3, clue4, clue5, clue6, clue7, clue8, clue9, clue10, clue11, clue12, clue13, clue14, clue15, clue16, clue17, clue18, clue19, clue20, blankclue
]

// Styling
const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 150,
        },
    },
};

interface ddTableProps {
    isLeader: boolean;
}

interface FormData {
    [key: string]: string; // This allows us to use arbitrary strings as keys in formData
}

// Detective notepad to tick or cross the options
const DropdownTable: React.FC<ddTableProps> = ({isLeader}) => {
    const {socket} = useSocket();
    const [suspectSelections, setSuspectSelections] = useState(suspects.map(() => ''));
    const [weaponSelections, setWeaponSelections] = useState(weapons.map(() => ''));
    const [roomSelections, setRoomSelections] = useState(rooms.map(() => ''));
    const [formData, setFormData] = useState<FormData>({});

    useEffect(() => {
        if (socket) {
            socket.on("broadcastSuspect", (data) => {
                setSuspectSelections(data.suspects);
            })
            socket.on("broadcastWeapon", (data) => {
                setWeaponSelections(data.weapons);
            })
            socket.on("broadcastRoom", (data) => {
                setRoomSelections(data.rooms);
            })
        }
    }, [socket]);

    useEffect(() => {
        if (socket) {
            // Listen for updates from the server and update the form data accordingly
            socket.on('updateField', (data: { fieldId: string; value: string }) => {
                setFormData((prev) => ({...prev, [data.fieldId]: data.value}));
            });
        }

        return () => {
            if (socket) {
                socket.off('updateField');
            }
        };
    }, [socket]);

    // we dont use these anymore since i changed it to a matrix grid not a table like before. Its now a text feild where you write letters


    // this is what we used instead

    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldId: string
    ) => {
        const value = e.target.value;
        setFormData((prev) => ({...prev, [fieldId]: value}));

        // Emit the input value to the server to broadcast it to other team members
        if (socket && isLeader) {
            socket.emit('inputChange', {fieldId, value});
        }
    };


// drop down table UI this was a pain to make...
    return (
        <ThemeProvider theme={theme}>
            <Box display="flex" justifyContent="center" mt={3} width="100%" sx={{paddingBottom: '80px'}}>
                <table style={{borderCollapse: 'collapse', width: '100%', tableLayout: 'fixed'}}>
                    <thead>
                    <tr>
                        <th
                            rowSpan={2}
                            style={{
                                border: '1px solid black',
                                padding: '10px',
                                fontSize: '14px',
                                backgroundColor: '#0099cc',
                                color: 'white',
                                textAlign: 'center',
                                width: '120px',
                                writingMode: 'vertical-rl',
                            }}
                        >
                            Suspects
                        </th>
                        <th
                            colSpan={rooms.length}
                            style={{
                                border: '1px solid black',
                                padding: '10px',
                                fontSize: '14px',
                                backgroundColor: '#33cccc',
                                color: 'white',
                                textAlign: 'center',
                                width: '220px',
                            }}
                        >
                            Room
                        </th>
                        <th
                            colSpan={weapons.length}
                            style={{
                                border: '1px solid black',
                                padding: '10px',
                                fontSize: '14px',
                                backgroundColor: '#809fff',
                                color: 'white',
                                textAlign: 'center',
                                width: '220px',
                            }}
                        >
                            Weapon
                        </th>
                    </tr>
                    <tr>
                        {rooms.map((room, index) => (
                            <th
                                key={index}
                                style={{
                                    border: '1px solid black',
                                    backgroundColor: '#33cccc',
                                    width: '40px', // Adjusted width for vertical text
                                    height: '160px', // Adjusted height to fit rotated text
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '150px',  // Width to accommodate the rotated text
                                        transform: 'translateX(-54px) rotate(-90deg)',
                                        transformOrigin: 'center',
                                        whiteSpace: 'nowrap',
                                        fontSize: '14px',
                                        color: 'white',
                                        textAlign: 'center',
                                    }}
                                >
                                    {room}
                                </div>
                            </th>
                        ))}
                        {weapons.map((weapon, index) => (
                            <th
                                key={index}
                                style={{
                                    border: '1px solid black',
                                    backgroundColor: '#809fff',
                                    width: '40px',
                                    height: '160px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '150px',
                                        transform: 'translateX(-54px) rotate(-90deg)',
                                        transformOrigin: 'center',
                                        whiteSpace: 'nowrap',
                                        fontSize: '14px',
                                        color: 'white',
                                        textAlign: 'center',
                                    }}
                                >
                                    {weapon}
                                </div>
                            </th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {suspects.map((suspect, sIndex) => (
                        <tr key={sIndex}>
                            <td
                                style={{
                                    border: '1px solid black',
                                    padding: '10px',
                                    fontSize: '14px',
                                    backgroundColor: '#33cccc',
                                    color: 'white',
                                    textAlign: 'center',
                                }}
                            >
                                {suspect}
                            </td>
                            {rooms.map((_, rIndex) => {
                                const fieldId = `suspect_${sIndex}_room_${rIndex}`;
                                return (
                                    <td key={rIndex} style={{
                                        border: '1px solid black',
                                        padding: '0px',
                                        textAlign: 'center',
                                        width: '80px'
                                    }}>
                                        <TextField
                                            fullWidth
                                            inputProps={{
                                                style: {
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    padding: '5px'
                                                }
                                            }}
                                            variant="outlined"
                                            size="small"
                                            value={formData[fieldId] || ''}
                                            onChange={(e) => handleInputChange(e, fieldId)}
                                            style={{margin: 0}}
                                            disabled={!isLeader}  // Disable the input for non-leaders
                                        />
                                    </td>
                                );
                            })}
                            {weapons.map((_, wIndex) => {
                                const fieldId = `suspect_${sIndex}_weapon_${wIndex}`;
                                const isGrayOut = sIndex >= weapons.length; // This condition checks for the bottom-right quadrant

                                return (
                                    <td
                                        key={wIndex}
                                        style={{
                                            border: '1px solid black',
                                            padding: '0px',
                                            textAlign: 'center',
                                            width: '80px',
                                            backgroundColor: isGrayOut ? '#d3d3d3' : 'inherit', // Gray out the cell
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            inputProps={{
                                                style: {
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    padding: '5px'
                                                }
                                            }}
                                            variant="outlined"
                                            size="small"
                                            value={formData[fieldId] || ''}
                                            onChange={(e) => handleInputChange(e, fieldId)}
                                            style={{margin: 0}}
                                            disabled={isGrayOut || !isLeader}  // Disable the input for gray out cells or non-leaders
                                        />
                                    </td>
                                );
                            })}
                        </tr>
                    ))}

                    {weapons.map((weapon, wIndex) => (
                        <tr key={wIndex}>
                            <td
                                style={{
                                    border: '1px solid black',
                                    padding: '10px',
                                    fontSize: '14px',
                                    backgroundColor: '#809fff',
                                    color: 'white',
                                    textAlign: 'center',
                                }}
                            >
                                {weapon}
                            </td>
                            {rooms.map((_, rIndex) => {
                                const fieldId = `weapon_${wIndex}_room_${rIndex}`;
                                const isGrayOut = wIndex >= suspects.length; // This condition checks for the bottom-right quadrant

                                return (
                                    <td
                                        key={rIndex}
                                        style={{
                                            border: '1px solid black',
                                            padding: '0px',
                                            textAlign: 'center',
                                            width: '80px',
                                            backgroundColor: isGrayOut ? '#d3d3d3' : 'inherit', // Gray out the cell
                                        }}
                                    >
                                        <TextField
                                            fullWidth
                                            inputProps={{
                                                style: {
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    padding: '5px'
                                                }
                                            }}
                                            variant="outlined"
                                            size="small"
                                            value={formData[fieldId] || ''}
                                            onChange={(e) => handleInputChange(e, fieldId)}
                                            style={{margin: 0}}
                                            disabled={isGrayOut || !isLeader}  // Disable the input for gray out cells or non-leaders
                                        />
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Box>
        </ThemeProvider>
    );


}


// Make an accusation UI
const AccusationPopup: React.FC<{
    open: boolean,
    isLeader: boolean,
    onClose: () => void,
    onSubmit: (suspect: string, weapon: string, room: string) => void
}> = ({open, isLeader, onClose, onSubmit}) => {
    const [selectedSuspect, setSelectedSuspect] = useState('');
    const [selectedWeapon, setSelectedWeapon] = useState('');
    const [selectedRoom, setSelectedRoom] = useState('');
    const {socket} = useSocket();
    // broadcasts all of this stuff to the other team members. When an accusation is made

    useEffect(() => {
        if (socket) {
            socket.on("broadcastSelectedSuspect", (data) => {
                setSelectedSuspect(data.selection);
            });
            socket.on("broadcastSelectedWeapon", (data) => {
                setSelectedWeapon(data.selection);
            });
            socket.on("broadcastSelectedRoom", (data) => {
                setSelectedRoom(data.selection);
            });
        }
    }, [socket])

    const handleSubmit = () => {
        onSubmit(selectedSuspect, selectedWeapon, selectedRoom);
    };

    const handleSetSelectedSuspect = (e: string) => {
        if (socket && isLeader) {
            socket.emit("broadcastSelectedSuspect", {selection: e})
        }
    }
    const handleSetSelectedWeapon = (e: string) => {
        if (socket && isLeader) {
            socket.emit("broadcastSelectedWeapon", {selection: e})
        }
    }
    const handleSetSelectedRoom = (e: string) => {
        if (socket && isLeader) {
            socket.emit("broadcastSelectedRoom", {selection: e})
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>Accusation Submission</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>Suspect</Typography>
                            <Select
                                fullWidth
                                value={selectedSuspect}
                                onChange={(e) => handleSetSelectedSuspect(e.target.value as string)}
                            >
                                {suspects.map((suspect, index) => (
                                    <MenuItem key={index} value={suspect}>{suspect}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Weapon</Typography>
                            <Select
                                fullWidth
                                value={selectedWeapon}
                                onChange={(e) => handleSetSelectedWeapon(e.target.value as string)}
                            >
                                {weapons.map((weapon, index) => (
                                    <MenuItem key={index} value={weapon}>{weapon}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Room</Typography>
                            <Select
                                fullWidth
                                value={selectedRoom}
                                onChange={(e) => handleSetSelectedRoom(e.target.value as string)}
                            >
                                {rooms.map((room, index) => (
                                    <MenuItem key={index} value={room}>{room}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="tur2" disabled={!isLeader}>Cancel</Button>
                    <Button onClick={handleSubmit} color="turquoise" disabled={!isLeader}>Submit</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

const TIME_LIMIT = 10 * 60;

// Using the contexts e.g., SocketContext and TeamContext to allow them to persist throughout the games
const InsiderInsightsGame: React.FC = () => {
    usePreventNavigation();
    const navigate = useNavigate();
    const {teamName} = useTeam();
    const {socket} = useSocket();
    const [currentClueIndex, setCurrentClueIndex] = useState(0);
    const [maxClueIndex, setMaxClueIndex] = useState(0);
    const [isAccusationPopupOpen, setIsAccusationPopupOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [accusationCount, setAccusationCount] = useState(0);
    const [score, setScore] = useState(0);
    const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(false);
    const [remainingTime, setRemainingTime] = useState(6000); // in seconds
    const [isLeader, setIsLeader] = useState(false);
    const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
    const [thirtySecondAlertShown, setThirtySecondAlertShown] = useState(false);
    const [halfTimeAlertShown, setHalfTimeAlertShown] = useState(false);
    const [alerted, setAlerted] = useState(false);
    const [finalAlertOpen, setFinalAlertOpen] = useState(false);
    const [feedbackOpen, setFeedbackOpen] = useState(false);
    const [displayString, setDisplayString] = useState("");
    const [displayScore, setDisplayScore] = useState(0);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [timerCanCount, setTimerCanCount] = useState(true);
    const [leaderName, setLeaderName] = useState("Not Selected");


    useEffect(() => {
        if (socket) {
            socket.emit("randomSelect", {game: "insider", sid: socket.id});
        }
    }, []);

    // Receiving message from the facilitator
    useEffect(() => {
        if (socket) {
            socket.on('broadcastMessage', (data: any) => {
                setSnackbarMessage(data.message);
                setSnackbarOpen(true);
            });

            socket.on('randomSelect', (data) => {
                console.log(`Picked: ${data.picked}, username: ${data.username}, recipient: ${data.recipient}, id: ${socket.id}`);
                if (data.username === data.picked && data.recipient === socket.id) {
                    console.log("Username " + data.username + " matches the picked socket.")
                    setIsLeader(true);
                    socket.emit("broadcastTeamList");
                } else if (data.username !== data.picked && data.recipient === socket.id) {
                    setIsLeader(false);
                }
                setLeaderName(data.picked);
            });

            socket.on('broadcastClueIndex', (data) => {
                setCurrentClueIndex(data.index);
            });

            socket.on('broadcastMaxClueIndex', (data) => {
                setMaxClueIndex(data.index);
            })

            socket.on('broadcastAccusationCount', (data) => {
                setAccusationCount(data.count);
            });

            socket.on('startTimer', () => {
                if (isLeader) {
                    setTimerCanCount(true);
                }
            });

            socket.on('broadcastScoreChange', (data) => {
                setScore(data.score);
            })

            socket.on('broadcastAccusationPopup', (data) => {
                setIsAccusationPopupOpen(data.isOpen);
            })

            socket.on('broadcastFeedback', (data) => {
                setDisplayString(data.msg);
                setFeedbackOpen(data.isOpen);
            })

            socket.on('completeInsider', (data) => {
                setDisplayScore(data.score);
                setDisplaySuccess(true);
                setTimerCanCount(false);
                setFinalAlertOpen(true);
            });

            socket.on('broadcastIncorrectAlert', (data) => {
                let string = `Oops, your accusation was incorrect! `
                if (data.suspectCorrect || data.weaponCorrect || data.roomCorrect) {
                    string = string + `But...\n`;
                    if (data.suspectCorrect) {
                        string = string + `You got the suspect right!\n`
                    }
                    if (data.weaponCorrect) {
                        string = string + `You got the weapon right!\n`
                    }
                    if (data.roomCorrect) {
                        string = string + `You got the room right!\n`
                    }
                }
                string = string + `Please try again.`
                if (socket && isLeader) {
                    socket.emit('broadcastFeedback', {isOpen: true, msg: string});
                }
            })

            return () => {
                socket.off('broadcastMessage');
                socket.off('completeInsider');
                socket.off('broadcastScoreChange');
                socket.off('randomSelect');
                socket.off('broadcastFeedback');
                socket.off('broadcastIncorrectAlert');
            };
        }
    }, [socket, isLeader]);

    const onCloseLastDialog = (success: boolean) => {
        setFinalAlertOpen(false);
        if (success) {
            navigate('/insider-insights-end');
        } else {
            navigate('/lobby');
        }
    }

    const handleNextClue = () => {
        if (socket && isLeader) {
            socket.emit("broadcastClueIndex", {index: currentClueIndex + 1});
            if (currentClueIndex + 1 > maxClueIndex) {
                socket.emit("broadcastMaxClueIndex", {index: currentClueIndex + 1});
            }
        }
    };

    const handlePrevClue = () => {
        if (socket && isLeader) {
            socket.emit("broadcastClueIndex", {index: currentClueIndex - 1});
        }
    }

    // Checking if they get the correct answer
    const handleAccusation = (suspect: string, weapon: string, room: string) => {
        if (socket && isLeader) {
            socket.emit("broadcastAccusationPopup", {isOpen: false});
            socket.emit("broadcastAccusationCount", {count: accusationCount + 1});
        }
        if (suspect === "Bob" && weapon === "Unauthorized Access" && room === "Games Room") {
            calculateScore();
        } else {
            if (socket && isLeader) {
                socket.emit("broadcastIncorrectAlert", {
                    suspectCorrect: suspect === "Bob",
                    weaponCorrect: weapon === "Unauthorized Access",
                    roomCorrect: room === "Games Room"
                });
            }
        }

    };

    const calculateBonusPoints = () => {
        return Math.floor(remainingTime / 30);
    }

    // Score calculation as provided in the game flow. Base score of 20 - n where n is the number of incorrect accusations
    // + bonus points for the time + bonus points for the number of clue cards not clicked
    const calculateScore = async () => {
        let finalScore = 0;
        const cluesLeft = clues.length - maxClueIndex - 1;
        let didGuess = false;

        if (cluesLeft >= 24) {
            didGuess = true;
        }

        if (!didGuess) {
            if (cluesLeft >= 10) {
                finalScore += 2;
            } else if (cluesLeft >= 5) {
                finalScore += 1;
            }

            if (accusationCount === 0) {
                finalScore += 10;
            } else if (accusationCount === 1 || accusationCount === 2) {
                finalScore += 8;
            } else if (accusationCount === 3) {
                finalScore += 6;
            } else if (accusationCount === 4) {
                finalScore += 3;
            } else if (accusationCount === 5) {
                finalScore += 1;
            } else if (accusationCount >= 7) {
                finalScore += 0;
            }
            const bonusPoints = calculateBonusPoints();
            finalScore += bonusPoints
        }

        if (socket && isLeader) {
            socket.emit("broadcastScoreChange", {teamName: teamName, score: finalScore})
        }
        // alert(`Correct! You solved the case! Your score is: ${finalScore}`);

        if (isLeader) {
            try {
                console.log("Updating leaderboard...");
                await axios.post('/api/update-score', {teamName, score: 0});
                setRefreshLeaderboard(!refreshLeaderboard);
            } catch (error) {
                console.error('Error updating score:', error);
            }
        } else {
            setRefreshLeaderboard(!refreshLeaderboard);
        }

        if (socket) {
            socket.emit("completeInsider", {score: finalScore})
        }
    };

    const calculateScoreLoss = async () => {
        const baseScore = 20 - accusationCount;
        const cluesScore = clues.length - maxClueIndex;
        const timeScore = Math.floor(remainingTime / 30);
        const finalScore = baseScore + cluesScore + timeScore;
        if (socket && isLeader) {
            socket.emit("broadcastScoreChange", {teamName: teamName, score: finalScore})
        }
        if (isLeader) {
            try {
                console.log("Updating leaderboard...");
                await axios.post('/api/update-score', {teamName, score: 0});
                setRefreshLeaderboard(!refreshLeaderboard);

            } catch (error) {
                console.error('Error updating score:', error);
            }
        } else {
            setRefreshLeaderboard(!refreshLeaderboard)
        }
    }

    // Navigate to the lobby when time is 0
    const handleTimeUp = () => {
        setDisplayScore(score);
        setDisplaySuccess(false);
        setTimerCanCount(false);
        setFinalAlertOpen(true);
    };

    const handleInstructionClose = () => {
        setIsInstructionPopupOpen(false);
        if (isLeader) {
            setTimerCanCount(true);
        }
    };

    // Alert the facilitator button
    const handleAlertFacilitator = () => {
        if (socket) {
            socket.emit('alertFacilitator', {teamName});
            setAlerted(true);
            setTimeout(() => {
                setAlerted(false);
            }, 1000)
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleTimerUpdate = (remaining: number) => {
        if (timerCanCount) {
            setRemainingTime(remaining);
        }
        if (remaining <= (TIME_LIMIT) / 2 && !halfTimeAlertShown) {
            setSnackbarMessage('Half the time remaining!');
            setSnackbarOpen(true);
            setHalfTimeAlertShown(true);
        }
        if (remaining <= 60 && !thirtySecondAlertShown) {
            setSnackbarMessage('60 seconds remaining!');
            setSnackbarOpen(true);
            setThirtySecondAlertShown(true);
        }
    };

    const handleOpenAccusationPopup = () => {
        if (socket && isLeader) {
            socket.emit("broadcastAccusationPopup", {isOpen: true});
        }
    }

    const handleCloseAccusationPopup = () => {
        if (socket && isLeader) {
            socket.emit("broadcastAccusationPopup", {isOpen: false});
        }
    }

    const onCloseFeedback = () => {
        if (socket && isLeader) {
            socket.emit("broadcastFeedback", {isOpen: false, msg: ""});
        }
    }

    // Game UI
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <GameHeader
                    isLeader={isLeader}
                    setIsInstructionPopupOpen={setIsInstructionPopupOpen}
                    handleAlertFacilitator={handleAlertFacilitator}
                    refreshLeaderboard={refreshLeaderboard}
                    alerted={alerted}
                    timerCanCount={timerCanCount}
                    teamName={teamName}
                    TIME_LIMIT={TIME_LIMIT}
                    handleTimeUpdate={handleTimerUpdate}
                    onTimeUp={handleTimeUp}
                />

                <Container maxWidth="lg" sx={{flex: '1 0 auto'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8}>
                            <Box display="flex" justifyContent="center" alignItems="center" mt={3} mb={1}>
                                <img src={images[currentClueIndex]} alt="Suspects"
                                     style={{width: 'auto', height: '350px'}}/>

                            </Box>
                            <Box display="flex" justifyContent="center" mt={-3}>
                                <Card style={{
                                    width: '50%',  // Adjust width to make the card narrower
                                    padding: '10px',
                                    textAlign: 'center',
                                    backgroundColor: '#ffffff',  // Set background color to white
                                    borderRadius: '50px',  // Rounded edges
                                    border: '2px solid black',  // Black border
                                    clipPath: 'none',  // Disable polygon clipping
                                    boxShadow: '0px 0px 0px 3px black',  // Extra border thickness
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <CardContent>
                                        <Typography variant="body1" align="center">
                                            {clues[currentClueIndex]}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Box>
                            <Box display="flex" justifyContent="center" mt={3} gap={3}>
                                <IconButton color="tur2" onClick={handlePrevClue} disabled={currentClueIndex === 0}
                                            style={{
                                                fontSize: '20px',
                                                border: '2px solid',
                                                padding: '10px',
                                                borderRadius: '10px'
                                            }}>
                                    <SearchIcon/>
                                    <Typography variant="body2" sx={{marginLeft: '5px'}}>Previous Clue</Typography>
                                </IconButton>
                                <IconButton color="tur2" onClick={handleNextClue}
                                            disabled={currentClueIndex === clues.length - 1} style={{
                                    fontSize: '20px',
                                    border: '2px solid',
                                    padding: '10px',
                                    borderRadius: '10px'
                                }}>
                                    <SearchIcon/>
                                    <Typography variant="body2" sx={{marginLeft: '5px'}}>Next Clue</Typography>
                                </IconButton>
                            </Box>
                            <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={3}>
                                <LinearProgress variant="determinate" color="turquoise"
                                                value={maxClueIndex >= 21 ? 100 : (100 * ((maxClueIndex + 1) / 21))}
                                                style={{width: '50%'}}/>
                                <Box display="flex" flexDirection={"column"} alignItems={"center"} mt={3}>
                                    <Typography variant="body2" style={{textAlign: 'center', whiteSpace: 'nowrap'}}>Clues used: {maxClueIndex + 1} / 21 </Typography>
                                </Box>
                            </Box>

                            <Box display="flex" justifyContent="center" mt={3}>
                                <Button variant="contained" color="turquoise" onClick={handleOpenAccusationPopup}>
                                    Make an Accusation
                                </Button>
                            </Box>
                            <Box display="flex" justifyContent="center" mt={3}>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DropdownTable isLeader={isLeader}/>
                        </Grid>
                    </Grid>

                    <AccusationPopup
                        open={isAccusationPopupOpen}
                        isLeader={isLeader}
                        onClose={handleCloseAccusationPopup}
                        onSubmit={handleAccusation}
                    />
                </Container>
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                >
                    <Alert onClose={handleCloseSnackbar} severity="info" sx={{width: '100%'}}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <InstructionPopup
                    open={isInstructionPopupOpen}
                    onClose={handleInstructionClose}
                    title="Insider Insights Instructions"
                    boldText={`The team leader this round is: ${leaderName}`}

                    instructions={[
                        <span><b>1. Read the clues:</b></span>,
                        "Each clue will help you determine which Suspect matches with which Room and Weapon, and which Room matches which Weapon.",
                        "",
                        "The clues may be direct (e.g., “Alice is not in the office”) or indirect (e.g., “The person in the kitchen is not Bob”).",
                        "",
                        <span><b>2. Mark what you know:</b></span>,
                        "Use symbols like an \"X\" to indicate what is not true and a checkmark “Y\" for what is true.\n",
                        "Example:",
                        "If the clue is \"Alice is not in HR’s Office,\" put an \"X\" where Alice and HR’s Office intersect.",
                        "",
                        "Use the intersections of Suspects, Rooms and Weapons to make your accusation."
                    ]}

                />
                <GameCompleteDialog open={finalAlertOpen} onClose={onCloseLastDialog} success={displaySuccess}
                                    score={displayScore} bp={0}/>
                <FeedbackDialog open={feedbackOpen} isLeader={isLeader} onClose={onCloseFeedback} msg={displayString}/>
                <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto'}}>
                    <Logos/>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default InsiderInsightsGame;
