import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSocket } from './SocketContext';

/*
One of the main changes that was made was the facilitor is able to bring eveyone to the finale game. This component is a global event listener which all sockets
recieve when the facilitator wants to naviagte all the sockets to the finale game. It essentially just listens for a socket event and navigates everyone, not too 
much going on here.

*/

const GlobalEventListener: React.FC = () => {
    const navigate = useNavigate();
    const { socket } = useSocket();

    useEffect(() => {
        if (socket) {
            socket.on('navigateToFinale', () => {
                navigate('/finale-game');
            });

            socket.on('navigateToFinaleStart', () => {
                navigate('/finale-game-start');
            });
            

            return () => {
                socket.off('navigateToFinale');
                socket.off('navigateToFinaleStart')
            };
        }
    }, [socket, navigate]);

    return null;
};

export default GlobalEventListener;
