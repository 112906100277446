import React, {useState, useEffect, useRef} from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Snackbar,
    Alert,
    IconButton,
    LinearProgress,
    Dialog,
    DialogContent,
    DialogActions
} from '@mui/material';
import {ThemeProvider, styled} from '@mui/material/styles';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {theme} from '../../palette'; // Ensure this import path is correct based on your project structure
import InstructionPopup from '../../components/InstructionPopup';
import {useSocket} from '../../contexts/SocketContext';
import {useTeam} from '../../contexts/TeamContext';
import Logos from '../../components/Logos';
import axios from 'axios';
import ReactAudioPlayer from 'react-audio-player';
import {useNavigate} from 'react-router-dom';
import usePreventNavigation from '../../components/PreventNavigation';
import GameCompleteDialog from '../../components/GameCompleteDialog';
import UnanimousYesPopup from "../../components/UnanimousYesPopup";
import GameHeader from '../../components/GameHeader';

// all the clips here with the answers and the explanations

const audioClips = [
    {
        src: require('./audio/Clip1.mp3'),
        isScam: true,
        explanation: 'Core Concept: Exercise caution when sharing sensitive information over the phone. Banks will never request details like your account number or PIN. Scammers exploit their victims trust and sense of urgency to obtain sensitive information and steal money from their accounts.​'
    },
    {
        src: require('./audio/Clip2.mp3'),
        isScam: true,
        explanation: 'Core Concept: Exercise caution when sharing sensitive information over the phone. Banks will never request details like your account number or PIN. Scammers exploit their victims trust and sense of urgency to obtain sensitive information and steal money from their accounts.​'
    },
    {
        src: require('./audio/Clip3.mp3'),
        isScam: false,
        explanation: 'Core Concept: The caller does not ask for any personal information, and only asks for them to confirm what they have on file is accurate.'
    },
    {
        src: require('./audio/Clip4.mp3'), // explanation doesn't match the audio clip. Its about a delivery
        isScam: false,
        explanation: 'Core Concept: The caller does not ask for any personal information, and only asks for them to confirm what they have on file is accurate.'
    },
    {
        src: require('./audio/Clip5.mp3'),
        isScam: true,
        explanation: 'Core Concept: Exercise caution when sharing sensitive information over the phone. The caller has asked for a variety of personally identifiable information for identity theft and fraudulent activities.​'
    },
    {
        src: require('./audio/Clip6.mp3'),
        isScam: false,
        explanation: 'Core Concept:  The caller does not ask for any personal information or relay information that would be harmful to the individual.'
    },
    {
        src: require('./audio/Clip7.mp3'), // explanation doesnt match audio clip. It talks about unpaid taxes
        isScam: true,
        explanation: 'Core Concept: The call expresses a sense of urgency and indicates legal action would be taken for non-compliance. A good practice if you are unsure if a caller is legitimate is to call them back on a known phone number prior to giving them any sensitive information.​'
    },
    {
        src: require('./audio/Clip8.mp3'),
        isScam: false,
        explanation: 'Core Concept:  The caller does not ask for the full credit card number. Providing the last four digits of a credit card is not enough information for an individual to maliciously use your credit card. Additionally, they provide information regarding the fraudulent purchase, and direct the customer to contact them using means that can be verified to be secure.​'
    },
    {
        src: require('./audio/Clip9.mp3'),
        isScam: true,
        explanation: 'Core Concept:  The caller asks for personally identifiable information as well as to pay a fine immediately. Any links sent to you by email should be verified for authenticity prior to providing any sensitive information.​'
    },
    {
        src: require('./audio/Clip10.mp3'),
        isScam: true,
        explanation: 'Core Concept:  The caller asks for personally identifiable information, including full credit card details. Often when a deal is “too good to be true” and only available to you, it may in fact, not be true. ​'
    },
];

// UI stuff
const PlayPauseButton = styled(Button)(({theme}) => ({
    backgroundColor: theme.palette.turquoise.main,
    color: theme.palette.turquoise.contrastText,
    marginTop: '10px',
    '&:hover': {
        backgroundColor: theme.palette.turquoise.dark,
    },
}));

const GuessButton = styled(Button)(({theme}) => ({
    margin: '0 10px',
    padding: '15px 25px',
    backgroundColor: theme.palette.turquoise.main,
    color: theme.palette.turquoise.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.turquoise.dark,
    },
}));

const TIME_LIMIT = 8 * 60;

const PhantomLines: React.FC = () => {
    const [currentClipIndex, setCurrentClipIndex] = useState(0);
    usePreventNavigation();
    const [isPlaying, setIsPlaying] = useState(false);
    const [score, setScore] = useState(0);
    const [gameScore, setGameScore] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'info'>('info');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info'>('info');
    const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(true);
    const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
    const [thirtySecondAlertShown, setThirtySecondAlertShown] = useState(false);
    const [halfTimeAlertShown, setHalfTimeAlertShown] = useState(false);
    const [isPlayDisabled, setIsPlayDisabled] = useState(false);
    const audioPlayerRef = useRef<ReactAudioPlayer>(null);
    const [audioProgress, setAudioProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const {teamName} = useTeam();
    const {socket} = useSocket();
    const [isLeader, setIsLeader] = useState(false);
    const [remainingTime, setRemainingTime] = useState(TIME_LIMIT); // Example initial time
    const navigate = useNavigate();
    const [gameWon, setGameWon] = useState(false);
    const [cont, setCont] = useState(0);
    const [alerted, setAlerted] = useState(false);
    const [finalAlertOpen, setFinalAlertOpen] = useState(false);
    const [displayScore, setDisplayScore] = useState(0);
    const [displayBP, setDisplayBP] = useState(0);
    const [timerCanCount, setTimerCanCount] = useState(false);
    const [leaderName, setLeaderName] = useState("Not Selected");
    const [explanationOpen, setExplanationOpen] = useState(false);
    const [currentExplanation, setCurrentExplanation] = useState('');
    const [guessResult, setGuessResult] = useState<'Correct' | 'Incorrect'>('Correct');
    const [nextScoreChange, setNextScoreChange] = useState(0);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [pendingAction, setPendingAction] = useState<{ isCorrect: boolean, userGuess: string } | null>(null);


    useEffect(() => {
        if (score || cont) checkIfFinished();
    }, [score, cont]);

    useEffect(() => {
        if (gameWon) handleEndGame(true);
    }, [gameWon]);

    // picking leader
    useEffect(() => {
        if (socket) {
            socket.emit("randomSelect", {game: "phantom-lines", sid: socket.id});
            socket.on('randomSelect', (data) => {
                if (data.username === data.picked && data.recipient === socket.id) {
                    setIsLeader(true);
                    setIsLeader(true);
                    socket.emit("broadcastTeamList");
                } else if (data.username !== data.picked && data.recipient === socket.id) {
                    setIsLeader(false);
                }
                setLeaderName(data.picked);
            });
            return () => {
                socket.off('randomSelect');
            };
        }
    }, [socket]);

    useEffect(() => {
        if (socket) {
            // broadcasts progress bar and correct answer to everyone. ALl the connected sockets
            socket.on('broadcastPLChange', (data) => {
                setAlertMessage(data.result);
                setAlertSeverity(data.result === 'Correct' ? 'success' : 'error');
                setGameScore(data.gameScore);
                setCurrentClipIndex(data.clipIndex);
                setAudioProgress(0); // Reset progress bar for the new clip
                setCurrentTime(0); // Reset current time for the new clip
                setIsPlayDisabled(false); // Reset play button for the new clip
                if (isLeader && data.scoreChange !== 0) {
                    socket.emit("broadcastScoreChange", {teamName: teamName, score: data.scoreChange});
                } else if (isLeader && data.scoreChange === 0) {
                    setCont(data.cont);
                }
                setTimeout(() => {
                    setAlertMessage('');
                }, 3000);
            });
            // alert message we dont use

            socket.on('broadcastMessage', (data) => {
                setAlertMessage(data.message);
                setAlertSeverity('info');
                setTimeout(() => {
                    setAlertMessage('');
                }, 3000);
            });

            socket.on('broadcastScoreChange', (data) => {
                setScore(data.score);
            });

            socket.on('startTimer', () => {
                if (isLeader) {
                    setTimerCanCount(true);
                }
            });

            // plays the audio for everyone
            socket.on('playAudio', () => {
                const player = audioPlayerRef.current?.audioEl.current;
                if (player) {
                    player.play().then(() => {
                        setIsPlaying(true);
                    }).catch((error: any) => {
                        console.error('Error playing audio:', error);
                    });
                }
            });

            socket.on("leaderActionSubmitted", (data) => {
                console.log("Now upon receiving leaderActionSubmitted, data is: ", data);
                const {isCorrect, userGuess} = data;
                console.log("the isCorrect var is: ", isCorrect, " and the userGuess var is: ", userGuess);
                setPendingAction({isCorrect, userGuess});
                setIsPopupOpen(true);
            })

            // closes the popup when next is clicked
            socket.on('closeExplanationPopup', () => {
                setExplanationOpen(false);
            });

            // pauses audio for everyone

            socket.on('pauseAudio', () => {
                const player = audioPlayerRef.current?.audioEl.current;
                if (player) {
                    player.pause();
                    setIsPlaying(false);
                }
            });

            socket.on('completePhantomLines', (data) => {
                setDisplayScore(data.score);
                setDisplayBP(data.bp);
                setTimerCanCount(false);
                setFinalAlertOpen(true);
            });

            socket.on('showExplanation', (data) => {
                setCurrentClipIndex(data.clipIndex);
                setCurrentExplanation(data.explanation);
                setGuessResult(data.result);
                setExplanationOpen(true);
            });

            return () => {
                socket.off('broadcastPLChange');
                socket.off('broadcastMessage');
                socket.off('broadcastScoreChange');
                socket.off('playAudio');
                socket.off('pauseAudio');
                socket.off('completePhantomLines');
                socket.off('showExplanation');
                socket.off('closeExplanationPopup');
                socket.off('leaderActionSubmitted');
            };
        }
    }, [socket, navigate, isLeader, teamName]);

    // navigate to next page
    const onCloseLastDialog = (success: boolean) => {

        if (socket) {
            const sid = socket.id;
            axios.put('/api/update-room', {
                sid: sid,
                room: 'lobby'
            }).then((response) => {
                if (response.data.success) {
                    console.log('Room updated successfully from phantom to lobby');
                } else {
                    console.log('Error updating room');
                }
            }).catch((error) => {
                console.error('Error updating room:', error);
            });
        }

        console.log("navigating to next game");
        setFinalAlertOpen(false);
        navigate('/phantom-end');
    };

    useEffect(() => {
        // handles audio playing
        const player = audioPlayerRef.current?.audioEl.current;
        const handleSeeking = (e: any) => {
            e.preventDefault();
            if (player) {
                player.currentTime = 0;
            }
        };

        if (player) {
            player.addEventListener('seeking', handleSeeking);
            player.addEventListener('seeked', handleSeeking);
        }

        return () => {
            if (player) {
                player.removeEventListener('seeking', handleSeeking);
                player.removeEventListener('seeked', handleSeeking);
            }
        };
    }, [currentClipIndex]);

    useEffect(() => {
        if (audioPlayerRef.current?.audioEl?.current) {
            audioPlayerRef.current.audioEl.current.pause();
        }
        setIsPlaying(false);
    }, [currentClipIndex]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPlaying) {
                handleAudioTimeUpdate();
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [isPlaying]);

    const checkIfFinished = () => {
        if (currentClipIndex >= audioClips.length) {
            setGameWon(true);
        }
    };

    const handlePlay = () => {
        if (isLeader && socket) {
            setIsPlaying(true);
            socket.emit('playAudio', {teamName});
        }
    };

    const handlePause = () => {
        if (isLeader && socket) {
            socket.emit('pauseAudio', {teamName});
        }
    };

    const handleGuess = async (userGuess: string) => {
        if (!isLeader || !socket) return;
        const isCorrect = userGuess === (audioClips[currentClipIndex].isScam ? 'SCAM' : 'REAL');


        let team_size = 1;

        try {
            const response = await axios.get(`/api/team-in-game`, {
                params: {
                    teamName: teamName
                }
            });

            const {team_size_response} = response.data;

            team_size = team_size_response;
        } catch (e) {
            console.error("couldn't get teamsize: ", e);
        }

        if (team_size === 1) {
            handleEval(isCorrect);
        } else {

            setPendingAction({isCorrect, userGuess});
            socket.emit('leaderActionSubmit', {isCorrect, userGuess});
        }
    }


    const handleEval = (isCorrectDirect?: boolean) => {

        if (!socket) return;

        let isCorrectAnswer;
        if (!pendingAction) {
            if (isCorrectDirect !== undefined) {
                isCorrectAnswer = isCorrectDirect;
            } else {
                console.log("no pending action and no direct data");
                return;
            }
        } else {
            const {isCorrect} = pendingAction;
            isCorrectAnswer = isCorrect;
        }


        // Extract to new function.
        // Set the explanation
        setCurrentExplanation(audioClips[currentClipIndex].explanation);
        const result = isCorrectAnswer ? 'Correct' : 'Incorrect';
        setGuessResult(result);
        setNextScoreChange(isCorrectAnswer ? 2 : 0);


        // Show the explanation modal


        socket.emit('showExplanation', {
            teamName,
            clipIndex: currentClipIndex,
            explanation: audioClips[currentClipIndex].explanation,
            result: result
        });

        if (isCorrectAnswer) {
            let scoreIncrement = 2;

            const player = audioPlayerRef.current?.audioEl.current;
            let timeRemaining = 0;
            if (player) {
                timeRemaining = player.duration - player.currentTime;
            }

            if (timeRemaining > 20) {
                scoreIncrement++;
            }
            setGameScore((prevScore) => prevScore + scoreIncrement);
        }
    };
    const handleExplanationClose = () => {
        if (!socket) return;
        setExplanationOpen(false);

        if (isLeader) {
            socket.emit('closeExplanationPopup', {teamName});
        }

        let newClipIndex = currentClipIndex;
        if (currentClipIndex < audioClips.length) {
            newClipIndex += 1;
        }

        // Move to the next clip
            socket.emit('broadcastPLChange', {
                result: '',
                clipIndex: newClipIndex,
                scoreChange: nextScoreChange,
                gameScore: gameScore,
                cont: cont
            });

        if (newClipIndex >= audioClips.length) {
            handleEndGame(true);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleTimeUpdate = (timeLeft: number) => {
        if (timerCanCount) {
            setRemainingTime(timeLeft);
        }
        if (timeLeft <= (TIME_LIMIT) / 2 && !halfTimeAlertShown) {
            setSnackbarMessage('Half the time remaining!');
            setSnackbarOpen(true);
            setHalfTimeAlertShown(true);
        }

        if (timeLeft <= 60 && !thirtySecondAlertShown) {
            setSnackbarMessage('60 seconds remaining!');
            setSnackbarOpen(true);
            setThirtySecondAlertShown(true);
        }
    };

    const handleInstructionClose = async () => {
        setIsInstructionPopupOpen(false);
        if (isLeader) {
            setTimerCanCount(true);
        }


        try {
            const response = await axios.get(`/api/game-state`, {
                params: {
                    teamName: teamName,
                    currentGame: 'lines'
                }
            });

            console.log(" if (!isInstructionPopupOpen) {\n" +
                "                        setTimerCanCount(true);\n" +
                "                    }before sync, currentClipIndex is: ", currentClipIndex, ", and gameScore is, ", gameScore);
            const {
                currentClipIndex: newClipIndex,
                gameScore: newGameScore,
                shouldPopupOpen: shouldPopupOpen
            } = response.data;

            if (shouldPopupOpen) {
                setIsPopupOpen(true);
            }

            console.log("raw response data by server in PL is: ", response.data);
            if (isLeader) {
                if (newClipIndex > currentClipIndex) setCurrentClipIndex(newClipIndex);
                if (newGameScore > gameScore) setCurrentClipIndex(newClipIndex);
            } else {
                setCurrentClipIndex(newClipIndex);
                setGameScore(newGameScore);
            }
            console.log("Game details synced for PL");
            console.log("newClipIndex is: ", newClipIndex, ", and needed to end is: ", audioClips.length);

            if (newClipIndex >= audioClips.length) {
                console.log("handling end game after sync");
                setGameWon(true);
                onCloseLastDialog(true);
            }

        } catch (error) {
            console.log("error getting game details for policyGame");
        }


    };

    const onTimeUp = () => {
        setTimerCanCount(false);
        handleEndGame(false);
    };

    const handleAlertFacilitator = () => {
        if (socket) {
            socket.emit('alertFacilitator', {teamName});
            setAlerted(true);
            setTimeout(() => {
                setAlerted(false);
            }, 1000);
        }
    };

    const handleDecision = (decision: boolean) => {
        console.log("called handleDecision function in PhantomLines.");
        if (!pendingAction) console.log("no pendingAction, early returning");
        if (!pendingAction) return;

        if (decision) {
            handleEval();
        }

        // Clear the pending action and close the popup
        setPendingAction(null);
        setIsPopupOpen(false);
    }

    const handleEndGame = async (success: boolean) => {
        const bonusPoints = Math.max(Math.floor(remainingTime / 30), 0);
        // const totalScore = score + bonusPoints;
        const totalScore = gameScore + bonusPoints;
        if (isLeader) {
            try {
                await axios.post('/api/update-score', {teamName, score: totalScore});
                setRefreshLeaderboard(!refreshLeaderboard); // Toggle refresh state to reload leaderboard
            } catch (error) {
                console.error('Error updating score:', error);
            }
            if (socket) {
                socket.emit("completePhantomLines", {score: totalScore, bp: bonusPoints, success: success});
            }
        }
    };

    const handleAudioTimeUpdate = () => {
        const player = audioPlayerRef.current?.audioEl.current;
        if (player) {
            const progress = (player.currentTime / player.duration) * 100;
            setAudioProgress(progress);
            setCurrentTime(player.currentTime);
            setTotalDuration(player.duration);

            // Force the progress bar to update to 100% when the audio ends
            if (player.currentTime >= player.duration) {
                setAudioProgress(100);
                setCurrentTime(player.duration);
            }
        }
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <GameHeader
                    isLeader={isLeader}
                    setIsInstructionPopupOpen={setIsInstructionPopupOpen}
                    handleAlertFacilitator={handleAlertFacilitator}
                    refreshLeaderboard={refreshLeaderboard}
                    alerted={alerted}
                    timerCanCount={timerCanCount}
                    teamName={teamName}
                    TIME_LIMIT={TIME_LIMIT}
                    handleTimeUpdate={handleTimeUpdate}
                    onTimeUp={onTimeUp}
                />
                <Container sx={{mt: 5, textAlign: 'center'}}>
                    <Typography variant="h6" gutterBottom>
                        Score: {gameScore}
                    </Typography>
                    <Typography variant="h4"
                                style={{visibility: currentClipIndex >= audioClips.length ? 'hidden' : 'visible'}}
                                gutterBottom>
                        Line {Math.min(currentClipIndex + 1, audioClips.length)}
                    </Typography>
                    <IconButton aria-label="volume" sx={{fontSize: 100, color: 'grey'}} disabled={!isLeader}>
                        <VolumeUpIcon fontSize="inherit"/>
                    </IconButton>
                    <PlayPauseButton onClick={isPlaying ? handlePause : handlePlay}
                                     disabled={!isLeader || isPlayDisabled}>
                        {isPlaying ? 'Pause' : 'Play'}
                    </PlayPauseButton>

                    <Box>
                        <ReactAudioPlayer
                            autoPlay={false}
                            src={currentClipIndex >= audioClips.length ? audioClips[0].src : audioClips[currentClipIndex].src}
                            ref={audioPlayerRef}
                            onPlay={() => handlePlay()}
                            onPause={() => handlePause()}
                            onListen={handleAudioTimeUpdate}
                            controls={false}
                            style={{visibility: currentClipIndex >= audioClips.length ? 'hidden' : 'visible'}}
                            onEnded={() => setAudioProgress(100)} // Ensure progress is 100% when the audio ends
                        />
                        <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
                            <LinearProgress variant="determinate" value={audioProgress} sx={{flexGrow: 1}}/>
                            <Typography variant="body2" sx={{ml: 2}}>
                                {formatTime(currentTime)} / {formatTime(totalDuration)}
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{mt: 3}}>
                        <GuessButton onClick={() => handleGuess('SCAM')} variant="contained" disabled={!isLeader}>
                            SCAM
                        </GuessButton>
                        <GuessButton onClick={() => handleGuess('REAL')} variant="contained" disabled={!isLeader}>
                            REAL
                        </GuessButton>
                    </Box>

                    {alertMessage && (
                        <Box sx={{mt: 3}}>
                            <Alert severity={alertSeverity}>
                                {alertMessage}
                            </Alert>
                        </Box>
                    )}

                    <Box sx={{mt: 3}}>
                        <Typography variant="h6">
                            Current Clip: {Math.min(currentClipIndex + 1, audioClips.length)} / {audioClips.length}
                        </Typography>
                    </Box>
                </Container>

                <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={snackbarOpen}
                          autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                {isPopupOpen && socket && (
                    <UnanimousYesPopup
                        open={isPopupOpen}
                        leaderAction={pendingAction ? `The leader has chosen that this line is "${pendingAction.userGuess}"` : ""}
                        isLeader={isLeader}
                        sid={socket.id || ""}
                        teamName={teamName}
                        onDecision={handleDecision}
                        onClose={() => setIsPopupOpen(false)}
                    />
                )}
                <InstructionPopup
                    open={isInstructionPopupOpen}
                    onClose={handleInstructionClose}
                    title="Phantom Lines Instructions"
                    boldText={`The team leader this round is: ${leaderName}`}
                    instructions={[
                        "1. Listen to the audio clip and decide if it's a scam or real.",
                        "2. Use the buttons to make your guess.",
                        "3. If your guess is correct, you'll earn points.",
                        "4. The game ends when all clips are played or time runs out.",
                        "5. Alert the facilitator if you need assistance.",
                        "",
                        "The team leader controls what happens on-screen.",
                        "Other players can see what the team leader does.",
                        "",
                        "Scoring: 2 points for correct answer. Plus 1 bonus point if there is more than 20 seconds remaining in the clip",
                    ]}
                />
                <GameCompleteDialog open={finalAlertOpen} onClose={onCloseLastDialog} success={true}
                                    score={displayScore} bp={displayBP}/>

                <Dialog open={explanationOpen} onClose={handleExplanationClose}>
                    <DialogContent>
                        <Typography variant="h6" sx={{color: guessResult === 'Correct' ? 'green' : 'red', mb: 2}}>
                            {guessResult}!
                        </Typography>
                        <Typography>{currentExplanation}</Typography>
                    </DialogContent>
                    <DialogActions>
                        {/* Only the leader can click the Next button */}
                        <Button onClick={handleExplanationClose} color="primary" disabled={!isLeader}>
                            Next
                        </Button>
                    </DialogActions>
                </Dialog>

                <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto'}}>
                    <Logos/>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default PhantomLines;

