import React, { useState, useEffect } from 'react';
import { Container, Typography, AppBar, Toolbar, Button, Box, Grid, Collapse, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import bobImage from './img/Bob.png';
import { theme } from '../../palette';
import { ThemeProvider } from '@mui/material/styles';
import Logos from '../../components/Logos';
import Leaderboard from '../../components/Leaderboard';
import InstructionPopup from '../../components/InstructionPopup';
import TeamList from '../../components/TeamList';
import { useTeam } from '../../contexts/TeamContext';
import { useSocket } from '../../contexts/SocketContext';
import usePreventNavigation from '../../components/PreventNavigation';
import InfoIcon from '@mui/icons-material/Info';

// end page for II. Looks the same as all other endpages...

const InsiderInsightsEnd: React.FC = () => {
  const navigate = useNavigate();
  usePreventNavigation();
  const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(false);
  const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
  const { teamName } = useTeam();
  const [leaderboardOpen, setLeaderboardOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { socket } = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on('broadcastMessage', (data) => {
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
      });

      return () => {
        socket.off('broadcastMessage');
      };
    }
  }, [socket]);

  const handleNextPage = () => {
    navigate('/sixth-lobby'); // Update the path to the next page as needed
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar style={{top: 0}} position="sticky" color="turquoise">
          <Toolbar style={{ justifyContent: 'space-between' }}>
            {/* <Typography variant="h6" component="div">
              {teamName} Insider Insights
            </Typography> */}
            <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }} gap={2}>
              <Leaderboard refresh={refreshLeaderboard} />
              <TeamList isLeader={false} />
              <Button variant="contained" color="tur2" onClick={() => setIsInstructionPopupOpen(true)} startIcon={<InfoIcon />}>
              Instructions
            </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" component="div" sx={{ mr: 2 }} visibility={"hidden"}>
                {teamName} Escape Room Lobby
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>

        <Container maxWidth="lg" sx={{ flex: '1 0 auto' }}>
          <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginTop: '50px' }}>
            <Grid item xs={12} md={4}>
              <img src={bobImage} alt="Bob" style={{ maxWidth: '100%', height: 'auto' }} />
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
              <Typography variant="h4" component="div" style={{ fontWeight: 'bold' }}>
                Congratulations! You have solved the case.
              </Typography>
              <Button
                variant="contained"
                color="turquoise"
                style={{ marginTop: '20px' }}
                onClick={handleNextPage}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Collapse in={leaderboardOpen}>
          <Leaderboard refresh={refreshLeaderboard} />
        </Collapse>

        <InstructionPopup
          open={isInstructionPopupOpen}
          onClose={() => setIsInstructionPopupOpen(false)}
          title="Insider Insights Instructions"
          instructions={[
              <span><b>1. Read the clues:</b></span>,
              "Each clue will help you determine which Suspect matches with which Room and Weapon, and which Room matches which Weapon.",
              "",
              "The clues may be direct (e.g., “Alice is not in the office”) or indirect (e.g., “The person in the kitchen is not Bob”).",
              "",
              <span><b>2. Mark what you know:</b></span>,
              "Use symbols like an \"X\" to indicate what is not true and a checkmark “Y\" for what is true.\n",
              "Example:",
              "If the clue is \"Alice is not in HR’s Office,\" put an \"X\" where Alice and HR’s Office intersect.",
              "",
              "Use the intersections of Suspects, Rooms and Weapons to make your accusation."
          ]}
        />

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={null}
          onClose={(event, reason) => { if (reason !== 'clickaway') handleCloseSnackbar(); }}
        >
          <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto' }}>
          <Logos />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default InsiderInsightsEnd;
