import { Button, Dialog, DialogActions, DialogContent, ThemeProvider, Typography, useMediaQuery, useTheme } from "@mui/material";

interface GameCompleteDialogProps {
    open: boolean;
    isLeader: boolean;
    onClose: () => void;
    msg: string;
}

// not too sure why this is in II. This is the game complete dialog taht is when the team finishes a game. Only styling and UI

const GameCompleteDialog: React.FC<GameCompleteDialogProps> = ({open, isLeader, onClose, msg}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const splitMsg = msg.split('\n');

    return (
        <ThemeProvider theme={theme}>
        <Dialog open={open} maxWidth="sm" fullWidth={true} PaperProps={{
        style: {
          backgroundColor: theme.palette.background.paper,
          borderRadius: '15px',
          padding: '20px'
        }
      }}>
            <DialogContent>
                {
                    splitMsg.map((line, index) => <Typography variant="h5" textAlign={"center"}>{line}</Typography>)
                }
            </DialogContent>
            <DialogActions style={{justifyContent: 'center'}}>
            <Button onClick={onClose} variant={"contained"} color="turquoise" 
            style={{ backgroundColor: theme.palette.turquoise.main,
            color: theme.palette.turquoise.contrastText,
            padding: isSmallScreen ? '10px 20px' : '10px 40px',
            borderRadius: '25px',
            fontWeight: 'bold'}} disabled={!isLeader}>Close</Button>
            </DialogActions>
        </Dialog>
        </ThemeProvider>
    )
}

export default GameCompleteDialog;