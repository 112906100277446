import React, {useState, useEffect, ChangeEvent} from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Snackbar,
    Alert,
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogContent,
    DialogActions
} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import {theme} from '../../palette'; // Ensure this import path is correct based on your project structure
import {useTeam} from '../../contexts/TeamContext';
import {useSocket} from '../../contexts/SocketContext';
import Logos from '../../components/Logos';
import InstructionPopup from '../../components/InstructionPopup';
import axios from 'axios';
import scenario1 from './img/scenario1.png';
import scenario2 from './img/scenario2.png';
import scenario3 from './img/scenario3.png';
import scenario4 from './img/scenario4.png';
import scenario5 from './img/scenario5.png';
import scenario6 from './img/scenario6.png';
import scenario7 from './img/scenario7.png';
import scenario8 from './img/scenario8.png';
import scenario9 from './img/scenario9.png';
import scenario10 from './img/scenario10.png';
import {useNavigate} from 'react-router-dom';
import usePreventNavigation from '../../components/PreventNavigation';
import GameCompleteDialog from '../../components/GameCompleteDialog';
import UnanimousYesPopup from "../../components/UnanimousYesPopup";
import GameHeader from "../../components/GameHeader";


const images = [scenario1, scenario2, scenario3, scenario4, scenario5, scenario6, scenario7, scenario8, scenario9, scenario10];

const questions: string[] = ["On your way to the office, you meet a coworker in the elevator. They engage in a conversation with you regarding a new confidential business opportunity. How do you respond?",
    "You arrive at the front door of the office and realize that you have forgotten your access card. How do you proceed?",
    "On your way to a meeting, you see someone in the office that you have never seen before. They look out of place, and you see them taking confidential printouts from the printer. What should you do?",
    "It's the end of your workday and you are about to go home. You lock your laptop in your desk, but you notice your coworker who has already gone home has left their laptop unsecured on their desk. What should you do?",
    "On your way out of the building you see that the office door is propped open. There is nobody else in the vicinity. What should you do?",
    "You receive the following email, and you are not sure if it is legitimate or if it is a phishing message. Determine which aspect of this message tells you it is a phishing message:",
    "You receive the following email, and you are not sure if it is legitimate or if it is a phishing message. Determine if this is a phishing or legitimate message:",
    "You receive the following email, and you are not sure if it is legitimate or if it is a phishing message. Determine the best reason why this is a phishing message:",
    "Jojo is taking a flight after her workday and arrived at the airport earlier to check-in. She can work remotely and decides to use Wi-Fi to connect to his work network. Which network appears to be the safest to connect to?",
    "Eve is travelling with her family on holidays to a restricted location. Which of the following should she do to protect Visier's data during her travels?"
];

const whys: string[][] = [
    ["Visier's acceptable use policy states that users must not discuss or share confidential company information outside of Visier offices and/or with Visier team(s) that are not authorized to access such information.", "Offices generally have more secure environments compared to public or home settings. Conversations in the office are less likely to be overheard by unauthorized individuals."],
    ["Visier's Physical Security Policy states that each person must only use their own access card to enter controlled doors within Visier offices. The sharing of access cards and tailgating is prohibited.", "Access cards help to ensure that only authorized individuals access areas within the office that are appropriate for that individual. This ensure that the Visier office remains a safe, and trusted environment."],
    ["Unauthorized individuals within the Visier office pose a risk of encountering confidential information which they could use for malicious purposes, or personal gain."],
    ["Locking your laptop prevents unauthorized individuals from using your laptop and potentially accessing sensitive information, installing malicious software, or making unauthorized changes.", "Even trusted colleagues could accidentally access or alter important files if your laptop is left unsecured."],
    ["An open door can compromise the security of the Visier offices and allow unauthorized individuals to enter the office."],
    ["Malicious individuals will craft email addresses and URLs to closely resemble the legitimate site/email they are spoofing. It is important to always verify that emails are coming from a known and expected address."],
    ["The from email address is 'gitbub' rather than 'github'. As with the previous example, changes to legitimate addresses may be hardly noticeable, but can result in a successful compromise."],
    ["The URL that the 'Accept Invite' button take you to is a malicious address that closely resembles a legitimate website. Attackers use buttons within emails to disguise malicious URLs. Try to always copy and paste a link from an email, rather than clicking on a hyperlink as it may take you to a different page than you are expecting."],
    ["When connecting to Wi-Fi in a public location it is important to connect to only known, secured Wi-Fi networks. Wi-Fi networks can be setup to resemble legitimate networks but are used maliciously to intercept your private information."],
    ["When travelling, care must be taken to protect company information. If a personal phone is lost and has access to Visier accounts, the information could be obtained by malicious individuals."]
]

const options: string[][] = [
    ["A. Engage in the conversation.", "B. Mention that you should wait until you are in the office to discuss.", "C. Ignore your coworker.", "D. Report your coworker to the CFO and file an incident report."],
    ["A. Wait for a colleague to come to the door, and ask them to let you in.", "B. Wait for a colleague to open the door, and follow them in.", "C. Obtain a one-day temporary card from reception.", "D. Return home and call in sick."],
    ["A. Continue to your meeting, there must be an employee sponsor nearby.", "B. Ask whether the person is a visitor and if they need help finding the Visier employee they have an appointment with.", "C. Don't interact with them and report them to the facilities team."],
    ["A. Leave it as-is, your coworker must have had a reason to leave it out.", "B. Call your coworker to see if they were coming back to work that night. If not, lock up the laptop in your desk for them.", "C. Put the laptop in your coworker's unlocked desk cabinet.", "D. Cover the laptop with a file folder so it is hidden from public view."],
    ["A. Close the door so it is only slightly open and less obvious.", "B. Contact facilities to report the open door and ask if it should be closed.", "C. Leave the door open, it must be open for a reason.", "D. Wait at the door until someone comes by to ask about the open door."],
    ["A. The 'From' address", "B. The 'To' address", "C. The email subject", "D. The embedded link"],
    ["A. Phishing", "B. Legitimate"],
    ["A. Visier does not use ClickUp.", "B. Oscar.trent@visier.com is not clickable.", "C. The 'Accept Invite' is invalid.", "D. Visier does not capitalize names in email addresses."],
    ["A. FastWifiConnectToMe (Not Secured)", "B. Bob's iPhone (Secured)", "C. @yvrairport (Secured)", "D. YvR@irp0rt (Not Secured)"],
    ["A. Bring only hard copy confidential files.", "B. Share her detailed travel itinerary with her manager.", "C. Log out of all Visier accounts on her personal devices.", "D. Reset her Visier password multiple times throughout her travels."]
];

const answers: string[] = ["B", "C", "B", "B", "B", "A", "A", "C", "C", "C"];

const TIME_LIMIT = 8 * 60;

// component representing the Lockdown Legends game.

const LockdownLegendsGame: React.FC = () => {
    const navigate = useNavigate();
    usePreventNavigation();
    const [score, setScore] = useState(0);
    const [gameScore, setGameScore] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLeader, setIsLeader] = useState(false);
    const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
    const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(true); // State to control the instruction popup
    const [remainingTime, setRemainingTime] = useState(480);
    const [thirtySecondAlertShown, setThirtySecondAlertShown] = useState(false);
    const [halfTimeAlertShown, setHalfTimeAlertShown] = useState(false);
    const [qIndex, setQIndex] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState("")
    const [questionStatus, setQuestionStatus] = useState("neutral")
    const [attemptsUsed, setAttemptsUsed] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [popup, setPopup] = useState(false);
    const [alerted, setAlerted] = useState(false);
    const [finalAlertOpen, setFinalAlertOpen] = useState(false);
    const [displayScore, setDisplayScore] = useState(0);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [displayBP, setDisplayBP] = useState(0);
    const [timerCanCount, setTimerCanCount] = useState(false);
    const [leaderName, setLeaderName] = useState("Not Selected");
    const {teamName} = useTeam();
    const {socket} = useSocket();
    const [explanationOpen, setExplanationOpen] = useState(false);
    const [currentExplanation, setCurrentExplanation] = useState('');
    const [guessResult, setGuessResult] = useState<'Correct' | 'Incorrect'>('Correct');
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [pendingAction, setPendingAction] = useState<{ qIndex: number, selectedAnswer: string } | null>(null);


    // randomly select team leader

    useEffect(() => {
        if (socket) {
            socket.emit("randomSelect", {game: "lockdown", sid: socket.id});
        }
    }, [socket]);


    useEffect(() => {
        if (socket) {
            socket.on('randomSelect', (data) => {
                console.log(`Picked: ${data.picked}, username: ${data.username}, recipient: ${data.recipient}, id: ${socket.id}`);
                if (data.username === data.picked && data.recipient === socket.id) {
                    console.log("Username " + data.username + " matches the picked socket.")
                    setIsLeader(true);
                    socket.emit("broadcastTeamList");
                } else if (data.username !== data.picked && data.recipient === socket.id) {
                    setIsLeader(false);
                }
                setLeaderName(data.picked);
            });

            // recieve snackbar message
            socket.on('broadcastMessage', (data: any) => {
                setSnackbarMessage(data.message);
                setSnackbarOpen(true);
            });

            socket.on('broadcastPopupContent', (data: any) => {
                setGuessResult(data.result)
                setCurrentExplanation(data.explanation)
                setExplanationOpen(true);
            });

            // receive message with total score change
            socket.on('broadcastScoreChange', (data) => {
                setScore(data.score);
            });

            socket.on('startTimer', () => {
                if (isLeader) {
                    setTimerCanCount(true);
                }
            });

            // recieve message with current game score change
            socket.on('broadcastGameScoreChange', (data) => {
                setGameScore(data.score);
            });

            // recieve message with new MCQ choice
            socket.on("broadcastMCQChoice", (data) => {
                setSelectedAnswer(data.choice);
            });

            // recieve message with question status change (correct / incorrect / neutral)
            socket.on("broadcastQuestionStatus", (data) => {
                setQuestionStatus(data.status);
            });

            // recieve message with change in attempts used on question
            socket.on('broadcastAttemptsUsed', (data) => {
                setAttemptsUsed(data.attempts);
            });

            // recieve message with change in current question index
            socket.on('broadcastQIndex', (data) => {
                setQIndex(data.index);
            });

            socket.on("leaderActionSubmitted", (data) => {
                const {qIndex, selectedAnswer} = data;
                setPendingAction({qIndex, selectedAnswer});
                setIsPopupOpen(true);
            })

            // recieve message to trigger end game sequence
            socket.on('completeLockdown', (data) => {
                setDisplayScore(data.score);
                setDisplayBP(data.bp);
                setDisplaySuccess(data.success);
                setTimerCanCount(false);
                setFinalAlertOpen(true);
            })

            return () => {
                socket.off('randomSelect');
                socket.off('broadcastQIndex');
                socket.off('broadcastMessage');
                socket.off('broadcastTeamList');
                socket.off('broadcastScoreChange');
                socket.off('broadcastGameScoreChange');
                socket.off('broadcastMCQChoice');
                socket.off('broadcastQuestionStatus');
                socket.off('broadcastAttemptsUsed');
                socket.off('completeLockdown');
                socket.off('broadcastPopupContent');
                socket.off("leaderActionSubmitted");
            };
        }
    }, [socket]);

    // when points dialog is closed, navigate to next page
    const onCloseLastDialog = (success: boolean) => {

        if (socket) {
            const sid = socket.id;
            axios.put('/api/update-room', {
                sid: sid,
                room: 'lobby'
            }).then((response) => {
                if (response.data.success) {
                    console.log('Room updated successfully from lockdown to lobby');
                } else {
                    console.log('Error updating room');
                }
            }).catch((error) => {
                console.error('Error updating room:', error);
            });
        }


        setFinalAlertOpen(false);
        if (success) {
            navigate('/lockdown-end');
        } else {
            navigate('/sixth-lobby');
        }
    }


    // update timer, show half time and 60 sec alert if necessary


    const handleTimeUpdate = (timeLeft: number) => {
        if (timerCanCount) {
            setRemainingTime(timeLeft);
        }
        if (timeLeft <= (TIME_LIMIT) / 2 && !halfTimeAlertShown) {
            setSnackbarMessage('Half the time remaining!');
            setSnackbarOpen(true);
            setHalfTimeAlertShown(true);
        }

        if (timeLeft <= 60 && !thirtySecondAlertShown) {
            setSnackbarMessage('60 seconds remaining!');
            setSnackbarOpen(true);
            setThirtySecondAlertShown(true);
        }
    };

    const handleInstructionClose = async () => {
        setIsInstructionPopupOpen(false);

        if (isLeader) {
            setTimerCanCount(true);
        }


        try {
            const response = await axios.get(`/api/game-state`, {
                params: {
                    teamName: teamName,
                    currentGame: 'legends'
                }
            });

            console.log("before sync, qIndex is: ", qIndex, ", and gameScore is, ", gameScore);
            const {
                qIndex: newqIndex,
                gameScore: newGameScore,
                shouldPopupOpen: shouldPopupOpen
            } = response.data;

            if (shouldPopupOpen) {
                setIsPopupOpen(true);
            }

            console.log("raw response data by server in LL is: ", response.data);

            if (isLeader) {
                if (newqIndex > qIndex) setQIndex(newqIndex);
                if (newGameScore > gameScore) setGameScore(newGameScore);
            } else {
                setQIndex(newqIndex);
                setGameScore(newGameScore);
            }

            console.log("Game details synced for PL");

            if (newqIndex >= 10) {
                onCloseLastDialog(true);
            }

        } catch (error) {
            console.log("error getting game details for policyGame");
        }


    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };


    const onTimeUp = () => {
        setTimerCanCount(false);
        handleEndGame(false);
    };

    // handle opening or closing zoomed image

    const handlePopup = () => {
        setPopup(!popup);
    }

    // emit event for changing answer selection for current question

    const handleChangeAnswer = (event: ChangeEvent<HTMLInputElement>) => {
        if (socket && isLeader && (questionStatus !== 'success')) {
            socket.emit("broadcastMCQChoice", {choice: (event.target as HTMLInputElement).value});
        }
    }

    // calculate score, update leaderboard, emit event for showing final dialog

    const handleEndGame = async (success: boolean) => {
        console.log("end game");
        const bonusPoints = calculateBonusPoints();
        const totalScore = score + bonusPoints;
        if (isLeader) {
            try {
                await axios.post('/api/update-score', {teamName, score: totalScore - score});
                setRefreshLeaderboard(!refreshLeaderboard); // Toggle refresh state to reload leaderboard
            } catch (error) {
                console.error('Error updating score:', error);
            }
            if (socket) {
                socket.emit("completeLockdown", {score: gameScore, bp: bonusPoints, success: success});
            }
        }
    };

    const calculateBonusPoints = () => {
        return Math.max(Math.floor(remainingTime / 30), 0);
    };


    const handleExplanationClose = () => {
        setExplanationOpen(false);
    };

    const handleShowExplanation = (result: 'Correct' | 'Incorrect', explanation: string) => {
        setGuessResult(result);
        setCurrentExplanation(explanation);
        setExplanationOpen(true);

        if (socket) {
            socket.emit('broadcastPopupContent', {teamName, result, explanation});
        }
    };

    // when an answer is submitted:
    // - increment attempts used on that question by 1
    // - emit event with attempts used changes
    // - if answer is correct, emit score increment events according to scoring scheme
    // - emit event to change question status based on whether submission is correct or not
    const handleSubmitAnswer = async (qIndex: number, selectedAnswer: string) => {
        if (!isLeader || !socket) return;


        let team_size = 1;

        try {
            const response = await axios.get(`/api/team-in-game`, {
                params: {
                    teamName: teamName
                }
            });

            const {team_size_response} = response.data;

            team_size = team_size_response;
        } catch (e) {
            console.error("couldn't get teamsize: ", e);
        }

        if (team_size === 1) {
            handleEval(qIndex, selectedAnswer);
            return;
        } else {
            setPendingAction({qIndex, selectedAnswer});
            socket.emit('leaderActionSubmit', {qIndex, selectedAnswer});
        }
    }

    const handleDecision = (decision: boolean) => {
        if (!pendingAction) console.log("no pendingAction, early returning");
        if (!pendingAction) return;

        if (decision) {
            handleEval();
        }

        // Clear the pending action and close the popup
        setPendingAction(null);
        setIsPopupOpen(false);
    }

    const handleEval = (qIndexDirect?: number, selectedAnswerDirect?: string) => {
        console.log("called handleEval function in LockdownLegends.");
        if (!socket) return;
        let qIndexAnswer
        let selectedAnswerFinal;

        if (!pendingAction) {
            if (qIndexDirect !== undefined && selectedAnswerDirect !== undefined) {
                qIndexAnswer = qIndexDirect;
                selectedAnswerFinal = selectedAnswerDirect;
            } else {
                console.error("No PA and no direct data");
            }
        } else {
            const {qIndex, selectedAnswer} = pendingAction;
            qIndexAnswer = qIndex;
            selectedAnswerFinal = selectedAnswer;
        }

        if (qIndexAnswer === undefined || selectedAnswerFinal === undefined) {
            console.error("Invalid qIndex or selectedAnswer");
            return; // Avoid further execution if qIndex or selectedAnswer is undefined
        }


        const isCorrect = selectedAnswerFinal === answers[qIndexAnswer];
        const result = isCorrect ? 'Correct' : 'Incorrect';
        const explanation = isCorrect ? whys[qIndexAnswer].join(' ') : "Incorrect - please try again.";

        let newAttemptsUsed = attemptsUsed;
        newAttemptsUsed[qIndexAnswer] = newAttemptsUsed[qIndexAnswer] + 1;
        socket.emit("broadcastAttemptsUsed", {attempts: newAttemptsUsed});


        handleShowExplanation(result, explanation);

        if (isCorrect) {
            let basePoints = 4;
            if (newAttemptsUsed[qIndexAnswer] === 2) {
                basePoints = 2;
            } else if (newAttemptsUsed[qIndexAnswer] === 3) {
                basePoints = 1;
            } else if (newAttemptsUsed[qIndexAnswer] >= 4) {
                basePoints = 0;
            }

            socket.emit("broadcastScoreChange", {teamName: teamName, score: basePoints});
            socket.emit("broadcastGameScoreChange", {score: gameScore + basePoints});
            socket.emit("broadcastQuestionStatus", {status: "success"});
        } else {
            socket.emit("broadcastQuestionStatus", {status: "error"});
        }

    }

    // emit event for either ending game, or incrementing current question index (+ setting MCQ choice & question status to neutral)

    const handleGoToNextQuestion = () => {
        if (socket && isLeader) {
            const nextIndex = qIndex + 1;
            if (nextIndex >= 10) {
                handleEndGame(true);
            } else {
                socket.emit('broadcastQIndex', {index: nextIndex, gameScore: gameScore});
                socket.emit('broadcastMCQChoice', {choice: ""});
                socket.emit('broadcastQuestionStatus', {status: "neutral"});
            }
        }
    }

    const handleAlertFacilitator = () => {
        if (socket) {
            socket.emit('alertFacilitator', {teamName});
            setAlerted(true);
            setTimeout(() => {
                setAlerted(false);
            }, 1000)
        }
    };



    const maxIndex = 9;
    const displayedQIndex = qIndex === 10 ? maxIndex : qIndex;


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <GameHeader
                    isLeader={isLeader}
                    setIsInstructionPopupOpen={setIsInstructionPopupOpen}
                    handleAlertFacilitator={handleAlertFacilitator}
                    refreshLeaderboard={refreshLeaderboard}
                    alerted={alerted}
                    timerCanCount={timerCanCount}
                    teamName={teamName}
                    TIME_LIMIT={TIME_LIMIT}
                    handleTimeUpdate={handleTimeUpdate}
                    onTimeUp={onTimeUp}
                />
                <Container maxWidth={false} sx={{maxHeight: 'none', paddingTop: '10px'}}>
                    <Box display='flex' flexDirection={"column"} alignItems={"center"} gap={5}>
                        <Box display='flex' justifyContent={'center'} gap={10}>
                            <Typography variant="h5">Scenario {displayedQIndex + 1} / 10</Typography>
                            <Typography variant="h5">Score: {gameScore}</Typography>
                        </Box>
                        <Grid display='flex' container alignItems="flex-start" gap={5} justifyContent='center'>

                            {/* 'question area' - contains question and radio button group for options */}
                            <Grid item xs={4} display='flex' flexDirection="column" gap={2}>
                                <Typography overflow={"auto"}>{questions[displayedQIndex]}</Typography>
                                <RadioGroup name="answers-group" value={selectedAnswer} onChange={handleChangeAnswer}
                                            sx={{gap: 2}}>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQIndex)!.length >= 1 ? 'visible' : 'hidden'}}
                                        value="A" control={<Radio/>} label={options.at(displayedQIndex)!.at(0)}/>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQIndex)!.length >= 2 ? 'visible' : 'hidden'}}
                                        value="B" control={<Radio/>} label={options.at(displayedQIndex)!.at(1)}/>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQIndex)!.length >= 3 ? 'visible' : 'hidden'}}
                                        value="C" control={<Radio/>} label={options.at(displayedQIndex)!.at(2)}/>
                                    <FormControlLabel
                                        style={{visibility: options.at(displayedQIndex)!.length >= 4 ? 'visible' : 'hidden'}}
                                        value="D" control={<Radio/>} label={options.at(displayedQIndex)?.at(3)}/>
                                </RadioGroup>
                                <Button variant="contained" color="turquoise"
                                        onClick={questionStatus === "success" ? () => handleGoToNextQuestion() : () => handleSubmitAnswer(qIndex, selectedAnswer)}>
                                    {questionStatus === "success" ? (displayedQIndex === 9 ? "Finish!" : "Next Question") : "Submit"}
                                </Button>
                            </Grid>
                            <Box display='flex' alignItems="center" justifyContent='center' width='450px'
                                 height='350px'>
                                <figure style={{textAlign: 'center'}}>
                                    <img src={images[displayedQIndex]} alt="Suspects"
                                         style={{maxWidth: '450px', maxHeight: '350px', objectFit: 'contain'}}
                                         onClick={handlePopup}/>
                                    <figcaption>Click to zoom</figcaption>
                                </figure>
                            </Box>
                        </Grid>
                        <Alert variant={"outlined"} severity={questionStatus === "success" ? "success" : "error"}
                               style={{visibility: questionStatus !== "neutral" ? "visible" : "hidden"}}>
                            {questionStatus === "success" ?
                                <div>
                                    <Typography style={{paddingBottom: 2}}>Correct!</Typography>
                                    {whys[displayedQIndex].map((why) => (
                                        <Typography fontSize={'12px'}>{why}</Typography>
                                    ))}
                                </div>
                                : <Typography>Incorrect - please try again.</Typography>}
                        </Alert>
                    </Box>
                </Container>
                <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                          open={snackbarOpen}
                          autoHideDuration={6000}
                          onClose={handleCloseSnackbar}
                >
                    <Alert onClose={handleCloseSnackbar} severity="info" sx={{width: '100%'}}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                {isPopupOpen && socket && (
                    <UnanimousYesPopup
                        open={isPopupOpen}
                        leaderAction={""}
                        isLeader={isLeader}
                        sid={socket.id || ""}
                        teamName={teamName}
                        onDecision={handleDecision}
                        onClose={() => setIsPopupOpen(false)}
                    />
                )}
                <InstructionPopup
                    open={isInstructionPopupOpen}
                    onClose={handleInstructionClose}
                    title="Lockdown Legends Instructions"
                    boldText={`The team leader this round is: ${leaderName}`}
                    instructions={[
                        "1. During a typical day in the office, you will encounter scenarios related to physical security.",
                        "2. When presented with a scenario, select the most appropriate way to respond.",
                        "3. The more attempts you use, the less points you will get for handling the scenario",
                        "4. The game ends when all scenarios are correctly handled, or time runs out.",
                        "5. Alert the facilitator if you need assistance.",
                        "",
                        "The team leader controls what happens on-screen.",
                        "Team members can see what the team leader does.",
                        "",
                        "Scoring: 1st try: 4 points, 2nd try: 2 points, 3rd try: 1 point, 4th try: 0 points",
                    ]}

                />

                <GameCompleteDialog open={finalAlertOpen} onClose={onCloseLastDialog} success={displaySuccess}
                                    score={displayScore} bp={displayBP}/>
                {popup && (
                    <Dialog open={popup} onClick={handlePopup}>
                        <DialogContent>
                            <img src={images[displayedQIndex]} alt="Suspects" style={{width: '100%', height: 'auto'}}/>
                        </DialogContent>
                    </Dialog>
                )}
                <Dialog open={explanationOpen} onClose={handleExplanationClose}>
                    <DialogContent>
                        <Typography variant="h6" sx={{color: guessResult === 'Correct' ? 'green' : 'red', mb: 2}}>
                            {guessResult}!
                        </Typography>
                        <Typography>{currentExplanation}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleExplanationClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto'}}>
                    <Logos/>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default LockdownLegendsGame;
