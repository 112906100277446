import React, {useState, useEffect} from 'react';
import {
    Container, Typography, Button, Box, Grid, Snackbar, Alert
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import officeImage from './img/office-final.jpg'; // Update the image path as needed
import {useTeam} from '../../contexts/TeamContext';
import {useSocket} from '../../contexts/SocketContext';
import InstructionPopup from '../../components/InstructionPopup';
import {theme} from '../../palette';
import {ThemeProvider} from '@mui/material/styles';
import axios from 'axios'
import Logos from '../../components/Logos';
import usePreventNavigation from '../../components/PreventNavigation';
import GameCompleteDialog from '../../components/GameCompleteDialog';
import QuestionPopup from "./PopUp";
import UnanimousYesPopup from "../../components/UnanimousYesPopup";
import GameHeader from "../../components/GameHeader";

// This file contains the 'Home Hygiene' game and all related components.


// change this when changing the game's time limit, not anywhere else
const TIME_LIMIT = 8 * 60

// component representing the game itself
const HomeHygieneGame: React.FC = () => {
    usePreventNavigation();
    const navigate = useNavigate();
    const {teamName} = useTeam();
    const {socket} = useSocket();
    // which questions have been answered
    const [completedMap, setCompletedMap] = useState<any>({
        "Smart Thermostat": false,
        "Router": false,
        "Computer": false,
        "Printer": false,
        "Google Home": false,
        "Smart Garbage Can": false,
        "Nanny Cam": false
    });
    const [remainingTime, setRemainingTime] = useState(TIME_LIMIT);
    const [isLeader, setIsLeader] = useState(false);
    const [score, setScore] = useState(0);
    const [gameScore, setGameScore] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(true); // State to control the instruction popup
    const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
    const [thirtySecondAlertShown, setThirtySecondAlertShown] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState("");
    // how many attempts for each question
    const [attemptsUsed, setAttemptsUsed] = useState<any>({
        "Smart Thermostat": 0,
        "Router": 0,
        "Computer": 0,
        "Printer": 0,
        "Google Home": 0,
        "Smart Garbage Can": 0,
        "Nanny Cam": 0
    })
    const [isQuestionPopupOpen, setIsQuestionPopupOpen] = useState(false);
    const [itemsRemaining, setItemsRemaining] = useState(7);
    const [halfTimeAlertShown, setHalfTimeAlertShown] = useState(false);
    const [correct, setCorrect] = useState(0);
    const [alerted, setAlerted] = useState(false);
    const [finalAlertOpen, setFinalAlertOpen] = useState(false);
    const [displayScore, setDisplayScore] = useState(0);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [displayBP, setDisplayBP] = useState(0);
    const [timerCanCount, setTimerCanCount] = useState(false);
    const [leaderName, setLeaderName] = useState("Not Selected");
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [pendingAction, setPendingAction] = useState<{ question: string, answer: string } | null>(null);


    // whether the game can be ended
    const [endButtonVisible, setEndButtonVisible] = useState(false);
    // trigger state for game end sequence
    const [doEndGame, setDoEndGame] = useState(false);


    const answers: { [key: string]: string } = {
        "Smart Thermostat": "A",
        "Router": "B",
        "Computer": "D",
        "Printer": "B",
        "Google Home": "C",
        "Smart Garbage Can": "A",
        "Nanny Cam": "B"
    };


    // randomly select a team leader from not-yet-selected members.
    useEffect(() => {
        if (socket) {
            socket.emit("randomSelect", {game: "hygiene", sid: socket.id});
            socket.on('randomSelect', (data) => {
                console.log(`Picked: ${data.picked}, username: ${data.username}, recipient: ${data.recipient}, id: ${socket.id}`);
                if (data.username === data.picked && data.recipient === socket.id) {
                    console.log("Username " + data.username + " matches the picked socket.");
                    setIsLeader(true);
                    socket.emit("broadcastTeamList");
                } else if (data.username !== data.picked && data.recipient === socket.id) {
                    setIsLeader(false);
                }
                setLeaderName(data.picked);
            });
            return () => {
                socket.off('randomSelect');
            };
        }
    }, [socket]);

    // when no items left, 'end game' button should be clickable
    useEffect(() => {
        if (itemsRemaining === 0) {
            setEndButtonVisible(true);
        }
    }, [itemsRemaining])

    // trigger for the game end sequence (points display, navigation to end screen, etc.)
    useEffect(() => {
        if (doEndGame) {
            handleEndGame(true);
        }
    }, [doEndGame])

    // if score increases or some question is otherwise correct (but might have recieved 0 points), emit message to decrement items remaining by 1
    useEffect(() => {
        if (socket && isLeader) {
            let newItemsRemaining = itemsRemaining - 1;
            socket.emit("broadcastItemsRemaining", {remaining: newItemsRemaining});
        }
    }, [score, correct])


    useEffect(() => {
        if (socket) {

            // set appropriate states when a change in attempts used for questions, completion map, or game score is detected
            socket.on('broadcastHHChange', (data) => {
                setAttemptsUsed(data.attemptsUsed);
                setCompletedMap(data.map);
                setGameScore(data.gameScore);
                if (isLeader && data.scoreChange != 0) {
                    socket.emit('broadcastScoreChange', {teamName: teamName, score: data.scoreChange});
                } else if (isLeader && data.scoreChange === 0) {
                    setCorrect(data.correct)
                }
            });

            // recieve incoming text messages
            socket.on('broadcastMessage', (data: any) => {
                setSnackbarMessage(data.message);
                setSnackbarOpen(true);
            });

            // change score
            socket.on('broadcastScoreChange', (data) => {
                setScore(data.score);
            });

            // change items remaining
            socket.on('broadcastItemsRemaining', (data) => {
                setItemsRemaining(data.remaining);
            });

            // open question popup
            socket.on('broadcastAskQuestion', (data) => {
                setIsQuestionPopupOpen(data.open);
                setCurrentQuestion(data.question);
            });

            socket.on('startTimer', () => {
                if (isLeader) {
                    setTimerCanCount(true);
                }
            });

            socket.on("leaderActionSubmitted", (data) => {
                const question = data.currQuestion;
                const answer = data.selectedAnswer;
                setPendingAction({question, answer});
                setIsPopupOpen(true);
            });


            // trigger the end game sequence
            socket.on('completeHygiene', (data) => {
                setDisplayScore(data.score);
                setDisplayBP(data.bp);
                setDisplaySuccess(data.success);
                setTimerCanCount(false);
                setFinalAlertOpen(true);
            });

            return () => {
                socket.off('broadcastMessage');
                socket.off('completeHygiene');
                socket.off('broadcastItemsRemaining');
                socket.off('broadcastScoreChange');
                socket.off("leaderActionSubmitted");
            };
        }
    }, [socket, isLeader, teamName]);

    // when points popup is closed, navigate to end screen or next lobby depending on whether the game was successfully completed before time ran out
    const onCloseLastDialog = (success: boolean) => {
        console.log("reached onCloseLastDialog");
        if (socket) {
            const sid = socket.id;
            axios.put('/api/update-room', {
                sid: sid,
                room: 'lobby'
            }).then((response) => {
                if (response.data.success) {
                    console.log('Room updated successfully from hygiene to lobby');
                } else {
                    console.log('Error updating room');
                }
            }).catch((error) => {
                console.error('Error updating room:', error);
            });
        }

        setFinalAlertOpen(false);
        console.log("now navigating to next lobby");
        if (success) {
            navigate('/hygiene-end');
        } else {
            navigate('/lobby-hygiene');
        }
    }

    // close the snackbar
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    // update timer, display half time or 30 sec alerts if necessary
    const handleTimeUpdate = (timeLeft: number) => {
        if (timerCanCount) {
            setRemainingTime(timeLeft);
        }
        if (timeLeft <= (TIME_LIMIT) / 2 && !halfTimeAlertShown) {
            setSnackbarMessage('Half the time remaining!');
            setSnackbarOpen(true);
            setHalfTimeAlertShown(true);
        }

        if (timeLeft <= 60 && !thirtySecondAlertShown) {
            setSnackbarMessage('60 seconds remaining!');
            setSnackbarOpen(true);
            setThirtySecondAlertShown(true);
        }
    };

    // calculate bonus points based on time left
    const calculateBonusPoints = () => {
        return Math.max(Math.floor(remainingTime / 30), 0);
    };

    // first, calculate points. then, trigger end game sequence. success = game complete before time runs out.
    const handleEndGame = async (success: boolean) => {
        console.log("end game");
        const bonusPoints = calculateBonusPoints();
        const totalScore = score + bonusPoints;
        if (isLeader) {
            try {
                await axios.post('/api/update-score', {teamName, score: totalScore - score});
                setRefreshLeaderboard(!refreshLeaderboard); // Toggle refresh state to reload leaderboard
            } catch (error) {
                console.error('Error updating score:', error);
            }
            if (socket) {
                socket.emit("completeHygiene", {score: gameScore + bonusPoints, bp: bonusPoints, success: success});
            }
        }
    };

    const handleInstructionClose = async () => {
        setIsInstructionPopupOpen(false);
        if (isLeader) {
            setTimerCanCount(true);
        }

        try {
            const response = await axios.get(`/api/game-state`, {
                params: {
                    teamName: teamName,
                    currentGame: 'hygiene'
                }
            });

            console.log("before sync, completedMap is: ", completedMap, ", and gameScore is, ", gameScore);
            const {
                completedMap: newCompletedMap,
                gameScore: newGameScore,
                shouldPopupOpen: shouldPopupOpen
            } = response.data;
            if (shouldPopupOpen) {
                setIsPopupOpen(true);
            }

            const newItemsRemaining = Object.values(newCompletedMap).filter(completed => !completed).length;

            if (!isLeader || (isLeader && newItemsRemaining < itemsRemaining)) {
                setCompletedMap(newCompletedMap);
                setGameScore(newGameScore);
                setItemsRemaining(newItemsRemaining);
            }

            if (newItemsRemaining === 0) {
                onCloseLastDialog(true);
            }

            console.log("Game details synced for homeHygiene");

        } catch (error) {
            console.log("error getting game details for policyGame");
        }

    };

    // if time runs out
    const onTimeUp = () => {
        setTimerCanCount(false);
        handleEndGame(false);
    };

    // emit message to open question popup
    const handleOpenQuestionPopup = (area: string) => {
        if (socket && isLeader) {
            socket.emit("broadcastAskQuestion", {open: true, question: area});
            socket.emit("broadcastQuestionStatus", {status: "neutral"});
        }
    }

    // emit message to close question popup
    const handleCloseQuestionPopup = () => {
        if (socket && isLeader) {
            socket.emit("broadcastAskQuestion", {open: false, question: ""});
        }
    }

    const handleDecision = (decision: boolean) => {
        console.log("called handleDecision function in PolicyGame.");
        if (!pendingAction) console.log("no pendingAction, early returning");
        if (!pendingAction) return;

        if (decision) {
            handleEval();
        }

        // Clear the pending action and close the popup
        setPendingAction(null);
        setIsPopupOpen(false);
    };

    const handleSubmit = async (question: string, answer: string) => {
        if (!socket || !isLeader) return;

        let team_size = 1;

        try {
            const response = await axios.get(`/api/team-in-game`, {
                params: {
                    teamName: teamName
                }
            });

            const {team_size_response} = response.data;

            team_size = team_size_response;
        } catch (e) {
            console.error("couldn't get teamsize: ", e);
        }

        if (team_size === 1) {
            handleEval(question, answer);
            return
        } else {
            socket.emit("leaderActionSubmit", {
                currQuestion: question,
                selectedAnswer: answer
            });
        }

    }
    const handleEval = (questionDirect?: string, answerDirect?: string) => {

        if (!socket) return;
        console.log("called handleEval function in HomeHygiene.");
        let questionFinal, answerFinal;
        if (!pendingAction) {
            if (questionDirect !== undefined && answerDirect !== undefined) {
                questionFinal = questionDirect;
                answerFinal = answerDirect;
            } else {
                console.log("no pending action and no direct data");
                return;
            }
        } else {
            const {question, answer} = pendingAction;
            questionFinal = question;
            answerFinal = answer;
        }


        if (answerFinal === answers[questionFinal]) {
            socket.emit("broadcastQuestionStatus", {status: "success"});
            handleCorrectSubmit(questionFinal, answerFinal);
        } else {
            socket.emit("broadcastQuestionStatus", {status: "error"});
            handleMistake(questionFinal);
        }

    }
    // what to do when question is correctly guessed. change completion map, then emit message with change in game elements
    const handleCorrectSubmit = (question: string, answer: string) => {
        if (socket && isLeader) {
            let newCompletionMap = completedMap;
            newCompletionMap[question] = true;

            let newScore = 4;
            if (attemptsUsed[question] === 1) {
                newScore = 2;
            } else if (attemptsUsed[question] === 2) {
                newScore = 1;
            } else if (attemptsUsed[question] >= 3) {
                newScore = 0;
            }

            socket.emit("broadcastHHChange", {
                attemptsUsed: attemptsUsed,
                map: newCompletionMap,
                scoreChange: newScore,
                gameScore: gameScore + newScore,
                correct: correct + 1
            })
        }
    }

    // when question is incorrect, increment attempts used for that question, then emit message with changes in game elements
    const handleMistake = (question: string) => {
        if (socket && isLeader) {
            let newAttemptsUsed = attemptsUsed;
            newAttemptsUsed[question] = newAttemptsUsed[question] + 1;
            socket.emit("broadcastHHChange", {
                attemptsUsed: newAttemptsUsed,
                map: completedMap,
                scoreChange: 0,
                gameScore: gameScore,
                correct: correct
            })
        }
    }

    // alerts the facilitator
    const handleAlertFacilitator = () => {
        if (socket) {
            socket.emit('alertFacilitator', {teamName});
            setAlerted(true);
            setTimeout(() => {
                setAlerted(false);
            }, 1000)
        }
    };


    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <GameHeader
                    isLeader={isLeader}
                    setIsInstructionPopupOpen={setIsInstructionPopupOpen}
                    handleAlertFacilitator={handleAlertFacilitator}
                    refreshLeaderboard={refreshLeaderboard}
                    alerted={alerted}
                    timerCanCount={timerCanCount}
                    teamName={teamName}
                    TIME_LIMIT={TIME_LIMIT}
                    handleTimeUpdate={handleTimeUpdate}
                    onTimeUp={onTimeUp}
                />
                <div onClick={() => setDoEndGame(endButtonVisible)}>
                    {endButtonVisible ? (
                        <Button
                            variant="contained"
                            color="turquoise"
                            onClick={() => handleEndGame(true)}
                            style={{display: 'block', margin: '20px auto', fontWeight: 'bold', textAlign: 'center'}}
                            data-cy="end-game-button"
                        >
                            You found the devices! Click here to escape the room!
                        </Button>
                    ) : (
                        <Typography variant="h5" style={{fontWeight: 'bold', textAlign: 'center'}}>
                            Select 7 devices that are connected to your home network, from your home office, that pose a
                            cybersecurity risk.
                        </Typography>
                    )}
                </div>
                <Container disableGutters>
                    <Grid paddingTop={3} item xs={10} gap={5}
                          style={{display: "flex", justifyContent: "center", alignContent: "center"}}>
                        <Typography variant="h6">Score: {gameScore}</Typography>
                        <Typography variant="h6">Items remaining: {itemsRemaining} / 7</Typography>
                    </Grid>
                    <p style={{textAlign: "center"}}>
                        <div style={{
                            position: "relative",
                            display: "inline-block",
                            transition: "transform 150ms ease-in-out"
                        }}>
                            <img src={officeImage}/>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                 xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1208 684"
                                 style={{position: "absolute", top: 0, left: 0}}>
                                <a onClick={() => handleOpenQuestionPopup("Router")} style={{
                                    cursor: "pointer",
                                    pointerEvents: !isLeader || completedMap["Router"] === true ? "none" : "auto"
                                }} data-cy="router-popup">
                                    <rect x="422" y="137" fill="#fff" opacity="0" width="72" height="63"></rect>
                                </a><a onClick={() => handleOpenQuestionPopup("Printer")} style={{
                                cursor: "pointer",
                                pointerEvents: !isLeader || completedMap["Printer"] === true ? "none" : "auto"
                            }} data-cy="printer-popup">
                                <rect x="906" y="483" fill="#fff" opacity="0" width="100" height="100"></rect>
                            </a><a onClick={() => handleOpenQuestionPopup("Computer")} style={{
                                cursor: "pointer",
                                pointerEvents: !isLeader || completedMap["Computer"] === true ? "none" : "auto"
                            }} data-cy="computer-popup">
                                <rect x="566" y="119" fill="#fff" opacity="0" width="195" height="171"></rect>
                            </a><a onClick={() => handleOpenQuestionPopup("Smart Thermostat")} style={{
                                cursor: "pointer",
                                pointerEvents: !isLeader || completedMap["Smart Thermostat"] === true ? "none" : "auto"
                            }} data-cy="thermostat-popup">
                                <rect x="771" y="123" fill="#fff" opacity="0" width="59" height="56"></rect>
                            </a><a onClick={() => handleOpenQuestionPopup("Google Home")} style={{
                                cursor: "pointer",
                                pointerEvents: !isLeader || completedMap["Google Home"] === true ? "none" : "auto"
                            }} data-cy="google-popup">
                                <rect x="492" y="226" fill="#fff" opacity="0" width="54" height="77"></rect>
                            </a>
                                <a onClick={() => handleOpenQuestionPopup("Nanny Cam")} style={{
                                    cursor: "pointer",
                                    pointerEvents: !isLeader || completedMap["Nanny Cam"] === true ? "none" : "auto"
                                }} data-cy="nannycam-popup">
                                    <rect x="125" y="374" fill="#fff" opacity="0" width="78" height="60"></rect>
                                </a><a onClick={() => handleOpenQuestionPopup("Smart Garbage Can")} style={{
                                cursor: "pointer",
                                pointerEvents: !isLeader || completedMap["Smart Garbage Can"] === true ? "none" : "auto"
                            }} data-cy="garbagecan-popup">
                                <rect x="983" y="392" fill="#fff" opacity="0" width="100" height="88"></rect>

                                <circle r={36} cx={458} cy={168.5} fill="none" stroke="green" stroke-width="2"
                                        visibility={completedMap["Router"] === true ? "visible" : "hidden"}/>
                                <circle r={28} cx={589} cy={565} fill="none" stroke="green" stroke-width="2"
                                        visibility={completedMap["USB Drive"] === true ? "visible" : "hidden"}/>
                                <circle r={50} cx={956} cy={533} fill="none" stroke="green" stroke-width="2"
                                        visibility={completedMap["Printer"] === true ? "visible" : "hidden"}/>
                                <circle r={97.5} cx={663.5} cy={204.5} fill="none" stroke="green" stroke-width="2"
                                        visibility={completedMap["Computer"] === true ? "visible" : "hidden"}/>
                                <circle r={29.5} cx={800.5} cy={151} fill="none" stroke="green" stroke-width="2"
                                        visibility={completedMap["Smart Thermostat"] === true ? "visible" : "hidden"}/>
                                <circle r={38.5} cx={519} cy={264.5} fill="none" stroke="green" stroke-width="2"
                                        visibility={completedMap["Google Home"] === true ? "visible" : "hidden"}/>
                                <circle r={39} cx={164} cy={404} fill="none" stroke="green" stroke-width="2"
                                        visibility={completedMap["Nanny Cam"] === true ? "visible" : "hidden"}/>
                                <circle r={50} cx={1033} cy={436} fill="none" stroke="green" stroke-width="2"
                                        visibility={completedMap["Smart Garbage Can"] === true ? "visible" : "hidden"}/>
                            </a>
                            </svg>
                        </div>
                    </p>

                    <QuestionPopup open={isQuestionPopupOpen} isLeader={isLeader} question={currentQuestion}
                                   onSubmit={handleSubmit} onClose={handleCloseQuestionPopup}
                                   onMistake={handleMistake}/>
                </Container>
                <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                          open={snackbarOpen}
                          autoHideDuration={6000}
                          onClose={handleCloseSnackbar}
                >
                    <Alert onClose={handleCloseSnackbar} severity="info" sx={{width: '100%'}}>
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
                <InstructionPopup
                    open={isInstructionPopupOpen}
                    onClose={handleInstructionClose}
                    title="Home Hygiene Instructions"
                    boldText={`The team leader this round is: ${leaderName}`}
                    instructions={[
                        "1. Find 7 devices in the room that are connected to your home network, from your home office, that pose a security risk.",
                        "2. Correctly answer the question about the best practice associated with the device.",
                        "3. The more attempts you use, the less points you will get for answering the question.",
                        "4. The game ends when all 7 devices are found and all questions correctly answered, or when time runs out.",
                        "5. Alert the facilitator if you need assistance.",
                        "",
                        "The team leader controls what happens on-screen.",
                        "Team members can see what the team leader does.",
                        "",
                        "Scoring: 1st try: 4 points, 2nd try: 2 points, 3rd try: 1 point, 4th try: 0 points",
                    ]}
                />
                {isPopupOpen && socket && (
                    <UnanimousYesPopup
                        open={isPopupOpen}
                        leaderAction={""}
                        isLeader={isLeader}
                        sid={socket.id || ""}
                        teamName={teamName}
                        onDecision={handleDecision}
                        onClose={() => setIsPopupOpen(false)}
                    />
                )}

                <GameCompleteDialog open={finalAlertOpen} onClose={onCloseLastDialog} success={displaySuccess}
                                    score={displayScore} bp={displayBP}/>

                <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto'}}>
                    <Logos/>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default HomeHygieneGame;