import { BrowserRouter, Routes, Route } from "react-router-dom";
import OnboardingPage from "../lobbies/OnboardingPage";
import SampleRoom from "../lobbies/SampleRoom";
import FacilitatorDashboard from "../FacilitatorDashboard";
import {TeamProvider} from "../contexts/TeamContext"
import PolicyGame from '../rooms/PuzzlingPolicies/PolicyGame';
import PolicyEnd from "../rooms/PuzzlingPolicies/PolicyEnd";
import { SocketProvider } from '../contexts/SocketContext';
import SecondLobby from '../lobbies/SecondLobby';
import InsiderInsightsIntro from '../rooms/InsiderInsights/InsiderInsightsIntro';
import InsiderInsightsGame from '../rooms/InsiderInsights/InsiderInsightsGame';
import InsiderInsightsEnd from "../rooms/InsiderInsights/InsiderInsightsEnd";
import ThirdLobby from "../lobbies/ThirdLobby";
import HomeHygieneGame from "../rooms/HomeHygiene/HomeHygieneGame";
import FinaleGameIntro from "../rooms/FinaleGame/FinaleGameIntro";
import FinaleGame from "../rooms/FinaleGame/FinaleGame";
import HomeHygieneEnd from "../rooms/HomeHygiene/HomeHygieneEnd";
import EnterUsernameRoom from "../lobbies/EnterUsernameRoom";
import FourthLobby from "../lobbies/FourthLobby";
import PhantomLines from "../rooms/PhantomLines/PhantomLines";
import FifthLobby from "../lobbies/FifthLobby";
import SixthLobby from "../lobbies/SixthLobby";
import LockdownLegendsGame from "../rooms/LockdownLegends/LockdownLegendsGame";
import LockdownLegendsEnd from "../rooms/LockdownLegends/LockdownLegendsEnd";
import PhantomEnd from "../rooms/PhantomLines/PhantomEnd";
import EndScreenPodium from "../lobbies/EndScreen";
import GlobalEventListener from "../contexts/GlobalEventListener";

// all of the app pages go here.

function App() {

  return (
    <TeamProvider>
      <SocketProvider>
      <BrowserRouter>
      <GlobalEventListener />
        <Routes>
          <Route path="/" element={<OnboardingPage />} />
          <Route path="/email-entry" element={<EnterUsernameRoom />} />
          <Route path="/sample-room" element={<SampleRoom />} />
          <Route path="/facilitator-dashboard" element={<FacilitatorDashboard />} />
          <Route path="/policy-game" element={<PolicyGame />} />
          <Route path="/policy-end" element={<PolicyEnd />} />
          <Route path="/lobby" element={<SecondLobby />} />
          <Route path="/insider-insights" element={<InsiderInsightsIntro />} />
          <Route path="/insider-insights-game" element={<InsiderInsightsGame />} />
          <Route path="/insider-insights-end" element={<InsiderInsightsEnd />} />
          <Route path="/lobby-hygiene" element={<ThirdLobby />} />
          <Route path="/home-hygiene" element={<HomeHygieneGame />}/>
          <Route path="/hygiene-end" element={<HomeHygieneEnd />} />
          <Route path="/finale-game" element={<FinaleGameIntro />} />
          <Route path="/finale-game-start" element={<FinaleGame />} />
          <Route path="/fourth-lobby" element={<FourthLobby />} />
          <Route path="/phantom-lines" element={<PhantomLines />} />
          <Route path="/fifth-lobby" element={<FifthLobby />} />
          <Route path="/lockdown-legends-game" element={<LockdownLegendsGame />} />
          <Route path="/lockdown-end" element={<LockdownLegendsEnd />} />
          <Route path="/sixth-lobby" element={<SixthLobby />} />
          <Route path="/phantom-end" element={<PhantomEnd />} />
          <Route path="/end-screen" element={<EndScreenPodium />} />

        </Routes>
      </BrowserRouter>
      </SocketProvider>
      </TeamProvider>

  );
}

export default App;