import React, { useEffect, useState } from 'react';
import { Container, Typography, AppBar, Toolbar, Button, Box, Grid, Collapse, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import escapeImage from './img/escaped.png';
import { theme } from '../../palette';
import { ThemeProvider } from '@mui/material/styles';
import Leaderboard from '../../components/Leaderboard';
import InstructionPopup from '../../components/InstructionPopup';
import Logos from '../../components/Logos';
import { useTeam } from '../../contexts/TeamContext';
import usePreventNavigation from '../../components/PreventNavigation';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { useSocket } from '../../contexts/SocketContext';
import InfoIcon from '@mui/icons-material/Info';
import TeamList from '../../components/TeamList';

const PolicyEnd: React.FC = () => {
  const navigate = useNavigate();
  usePreventNavigation();
  const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(false);
  const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
  const [leaderboardOpen, setLeaderboardOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const {teamName} = useTeam();
  const { socket } = useSocket();
  const { width, height } = useWindowSize(); // For responsive confetti
  const [confettiActive, setConfettiActive] = useState(true);

  useEffect(() => {
    if (socket) {
      socket.on('broadcastMessage', (data: { message: React.SetStateAction<string>; }) => {
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
      });

      return () => {
        socket.off('broadcastMessage');
      };
    }
  }, [socket]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiActive(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const handleNextPage = () => {
    navigate('/lobby'); // Update the path to the next page as needed
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {confettiActive && <Confetti width={width} height={height} />}
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar style={{top: 0}} position="sticky" color="turquoise">
          <Toolbar style={{ justifyContent: 'space-between' }}>
            {/* <Typography variant="h6" component="div">
              {teamName} Puzzling Policies
            </Typography> */}
            <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }} gap={2}>
              <TeamList isLeader={false} />
              <Leaderboard refresh={refreshLeaderboard} />
              <Button variant="contained" color="tur2" onClick={() => setIsInstructionPopupOpen(true)} startIcon={<InfoIcon />}>
                Instructions
              </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" component="div" sx={{ mr: 2 }} visibility={"hidden"}>
                  {teamName} Escape Room Lobby
                </Typography>
              </Box>
          </Toolbar>
        </AppBar>

        <Container maxWidth="lg" sx={{ flex: '1 0 auto' }}>
          <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginTop: '50px' }}>
            <Grid item xs={12} md={4}>
              <img src={escapeImage} alt="escape" style={{ maxWidth: '100%', height: 'auto' }} />
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
              <Typography variant="h4" component="div" style={{ fontWeight: 'bold' }}>
                Congratulations! You are a Policy Master!
              </Typography>
              <Button
                variant="contained"
                color="turquoise"
                style={{ marginTop: '20px' }}
                onClick={handleNextPage}
              >
                Continue
              </Button>
            </Grid>
          </Grid>

          {/* <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
            <img src={visierLogo} alt="Visier Logo" style={{ height: '50px', marginLeft: '20px' }} />
            <img src={cyberiumLogo} alt="Cyberium Logo" style={{ height: '60px', marginRight: '20px' }} />
          </Box> */}
        </Container>

        <Collapse in={leaderboardOpen}>
          <Leaderboard refresh={refreshLeaderboard} />
        </Collapse>

        <InstructionPopup
          open={isInstructionPopupOpen}
          onClose={() => setIsInstructionPopupOpen(false)}
          title="Puzzling Policies Instructions"
          instructions={[
              "1. Read each policy card carefully.",
              "2. Drag and drop each card into the correct policy bucket.",
              "3. Correct matches increase your score, while incorrect matches decrease it.",
              "4. The game ends when all cards are sorted or time runs out.",
              "5. Alert the facilitator if you need assistance.", 
              "",
              "The team leader controls what happens on-screen.", 
              "Other players can see what the team leader does."
            ]}
        />
        <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto' }}>
          <Logos />
        </Box>
        
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default PolicyEnd;
