import {styled} from "@mui/material/styles";
import {Box, Typography} from "@mui/material";
import React from "react";
import {QuestionContainerProps} from "../types";
import {theme} from "../../../palette";

const QuestionBox = styled(Box)(({theme}) => ({
    width: '65%',
    height: 'auto',
    padding: theme.spacing(1.5),
    backgroundColor: 'white', // You can adjust the background color as needed
    color: 'black',
    textAlign: 'center',
    margin: '0 auto', // Center the box horizontally
    borderRadius: '25px', // Making the box rounded
    border: '1px solid black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '0.72rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
}));


export const QuestionContainer: React.FC<QuestionContainerProps> = ({question}) => {
    return (
        <QuestionBox theme={theme}>
            <Typography variant="h6">
                {question}
            </Typography>
        </QuestionBox>
    );
};