import React from 'react';
import {AppBar, Toolbar, Box, Button, Typography, Divider} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Timer from './Timer';
import Leaderboard from "./Leaderboard";
import TeamList from "./TeamList"; // Assuming Timer is already a separate component

import LeaderStar from '../assets/LeaderStar.svg';
import PlayerIcon from '../assets/Player.svg';

import Siren from '../assets/Siren.svg';
import SirenPressed from '../assets/SirenPressed.svg';

interface GameHeaderProps {
    isLeader: boolean;
    setIsInstructionPopupOpen: (open: boolean) => void;
    handleAlertFacilitator: () => void;
    alerted: boolean;
    refreshLeaderboard: boolean;
    timerCanCount: boolean;
    teamName: string;
    TIME_LIMIT: number;
    handleTimeUpdate: (remainingTime: number) => void;
    onTimeUp: () => void;
}

const GameHeader: React.FC<GameHeaderProps> =
    ({
         isLeader,
         setIsInstructionPopupOpen,
         handleAlertFacilitator,
         alerted,
         refreshLeaderboard,
         timerCanCount,
         teamName,
         TIME_LIMIT,
         handleTimeUpdate,
         onTimeUp,
     }) => {
        return (
            <AppBar style={{top: 0}} position="sticky" color="turquoise">
                <Toolbar style={{justifyContent: 'space-between'}}>
                    <Box sx={{display: 'flex', justifyContent: 'center', flexGrow: 1}} gap={2}>
                        <Leaderboard refresh={refreshLeaderboard}/>
                        <TeamList isLeader={isLeader}/>
                        <Button
                            variant="contained"
                            color="tur2"
                            onClick={() => setIsInstructionPopupOpen(true)}
                            startIcon={<InfoIcon/>}
                        >
                            Instructions
                        </Button>
                    </Box>


                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box
                            sx={{
                                backgroundColor: 'white',
                                paddingX: '10px',
                                paddingY: '4px',
                                borderRadius: '14px',  // Adjust to make the corners more or less rounded
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',  // adds a subtle shadow for a floating effect
                                display: 'flex',  // Keeps the box size compact to the text
                                justifyContent: 'space-evenly',
                                textAlign: 'center'
                            }}
                        >


                            {/* Leader/Player Icon Section */}
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Box
                                    component="img"
                                    src={isLeader ? LeaderStar : PlayerIcon}
                                    alt={isLeader ? "Leader Icon" : "Player Icon"}
                                    sx={{
                                        height: isLeader ? '40px' : '35px',
                                        width: '40px',
                                        mb: isLeader ? '-6px' : '-1px'
                                    }}  // Adjust size as needed
                                />
                                <Typography variant="body1"
                                            sx={{
                                                fontSize: '8.5px',
                                                color: 'turquoise.main',
                                                fontWeight: 550,
                                                width: '35px',
                                                textAlign: 'center'
                                            }}>
                                    {isLeader ? "LEADER" : "PLAYER"}
                                </Typography>
                            </Box>

                            <Divider orientation="vertical" variant="middle" flexItem sx={{
                                mx: 1,
                                borderRightWidth: 2,

                            }} />

                            {/* Alert Facilitator Icon Section */}
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer'}}
                                 onClick={handleAlertFacilitator}>

                                <Box
                                    component="img"
                                    src={alerted ? SirenPressed : Siren}
                                    alt={"Siren"}
                                    sx={{
                                        width: '40px',
                                        mb: '-6px'
                                    }}
                                />
                                <Typography variant="body1"
                                            sx={{
                                                fontSize: '8.5px',
                                                color: alerted? '#6D6E71' : '#DA1C5C',
                                                fontWeight: 550,
                                                width: '35px',
                                                textAlign: 'center'
                                            }}>
                                    {alerted ? "ALERTED" : "HELP"}
                                </Typography>
                            </Box>

                        </Box>
                        <Timer
                            durationInSeconds={TIME_LIMIT}
                            isLeader={isLeader}
                            teamName={teamName}
                            onTimeUpdate={handleTimeUpdate}
                            startTimer={timerCanCount}
                            onTimeUp={onTimeUp}

                        />
                    </Box>
                </Toolbar>
            </AppBar>
        );
    };

export default GameHeader;