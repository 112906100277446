import {Question} from "./types";

export const questions: Question[] = [
    {
        question: "What is a common technique used in phishing emails?",
        options: ["Using lots of graphics to distract the recipient", "Embedding malicious links", "Include no text in the body of the email", "Sending motivational quotes"],
        answer: "Embedding malicious links"
    },
    {
        question: "What is an important action to take on devices connected to your home network?",
        options: ["Use common passwords for all devices", "Turn all devices off at night", "Update the device software/firmware regularly", "Make the Wi-Fi network they connect to public"],
        answer: "Update the device software/firmware regularly"
    },
    {
        question: "Which of the following is your part in protecting the physical security of the office?",
        options: ["Not sharing your access badge", "Not allowing tailgating/piggybacking", "Identifying unknown individuals in the office", "All of the above"],
        answer: "All of the above"
    },
    {
        question: "Where should Visier information be stored?",
        options: ["Personal Dropbox", "Visier owned/approved storage", "Personal Google Cloud", "All of the above"],
        answer: "Visier owned/approved storage"
    },
    {
        question: "What type of information is suitable to input into AI systems?",
        options: ["Visier Restricted information", "Generic questions on software development", "Client names", "Bugs in code developed for Visier"],
        answer: "Generic questions on software development"
    },
    {
        question: "How can travelling with Visier devices pose a risk to Visier security?",
        options: ["Public Wi-Fi may decrease the performance of your devices", "The devices must be carried in a special container when travelling", "Losing the device could result in malicious actors obtaining confidential information", "Devices may be exposed to harmful magnetic fields during pre-flight screening"],
        answer: "Losing the device could result in malicious actors obtaining confidential information"
    },
    {
        question: "What is the most important reason for laptops to be secured when unattended?",
        options: ["To adhere to the clean desk policy", "To protect against unauthorized access", "To protect against water damage", "To protect against a build-up of dust in the laptop fan"],
        answer: "To protect against unauthorized access"
    },
    {
        question: "Why should information be classified and handled as per the Data classification policy?",
        options: ["To maintain organization of customer facing files", "To ensure that documents are handled correctly per the Document Retention Policy", "To ensure that 'need-to-know' access is maintained", "To make documents look official when they are sent to clients"],
        answer: "To ensure that 'need-to-know' access is maintained"
    },
    {
        question: "What is a common method malicious telephone callers use to collect sensitive information?",
        options: ["Express a sense of urgency and imply legal actions may be taken", "Provide callers with their own credit card number to verify their identity", "Use a personal connection to become friends with the victim", "Threaten the victim’s family"],
        answer: "Express a sense of urgency and imply legal actions may be taken"
    },
    {
        question: "What is your role in protecting Visier against security attacks?",
        options: ["Verify any communications that may seem suspicious (e.g., telephone, email)", "Report any suspicious behaviour within the Visier office", "Follow Visier’s Information Security Policies", "All of the above"],
        answer: "All of the above"
    }
];

class QuestionManager {
    private questionsList: Question[];
    private currentQuestionIndex: number;

    constructor() {
        this.questionsList = questions;
        this.shuffleQuestions();
        this.currentQuestionIndex = 0;
    }

    private shuffleQuestions(): void {
        let currentIndex = this.questionsList.length;

        while (currentIndex != 0) {
            let randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // Swap elements
            [this.questionsList[currentIndex], this.questionsList[randomIndex]] = [
                this.questionsList[randomIndex], this.questionsList[currentIndex]];
        }
    }

    getCurrentQuestionIndex(): number {
        return this.currentQuestionIndex;
    }

    getCurrentQuestion(): Question {
        return this.questionsList[this.currentQuestionIndex];
    }

    getTotalQuestions(): number {
        return this.questionsList.length;
    }

    isLastQuestion(): boolean {
        return this.currentQuestionIndex >= this.questionsList.length - 1;
    }

    goToNextQuestion(): void {
        if (!this.isLastQuestion()) {
            this.currentQuestionIndex += 1;
        }
    }
}

export default new QuestionManager();