import React, {useState, useEffect, useRef} from 'react';
import {
    Typography,
    Box,
    Card,
    CardContent,
    AppBar,
    Toolbar,
    Button,
    Snackbar,
    Alert
} from '@mui/material';
import {jwtDecode} from "jwt-decode";

import {useNavigate} from 'react-router-dom';
import {ThemeProvider, styled} from '@mui/material/styles';
import {useSocket} from '../contexts/SocketContext';
import {useTeam} from '../contexts/TeamContext';
import {theme} from '../palette';
import Logos from '../components/Logos';
import TeamList from '../components/TeamList';
import StaticLeaderboard from '../components/StaticLeaderboard';
import image from '../assets/lobby1.png'
import usePreventBackNavigation from '../components/PreventNavigation';
import {ENV} from "../config"
import axios from "axios";

interface MyToken {
    role: string;
    teamName: string;
}

const StyledCard = styled(Card)(({theme}) => ({
    padding: theme.spacing(2),
}));

const StyledForm = styled('form')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

const SubmitButton = styled(Button)(({theme}) => ({
    marginTop: theme.spacing(2),
    flexGrow: 1,
}));

const SampleRoom = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const navigate = useNavigate();
    const {socket} = useSocket();
    const [messages, setMessages] = useState<string[]>([]);
    const [users, setUsers] = useState<string[]>([]);
    const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
    const {teamName, setTeamName} = useTeam();
    const [accessGranted, setAccessGranted] = useState(false);
    const [alerted, setAlerted] = useState(false);
    usePreventBackNavigation();

    useEffect(() => {
        const token = localStorage.getItem('jwt');
        if (token) {
            const decoded = jwtDecode<MyToken>(token);
        }

        if (accessGranted && socket) {
            const sid = socket.id;
            axios.put('/api/update-room', {
                sid: sid,
                room: 'policy'
            }).then((response) => {
                if (response.data.success) {
                    console.log('Room updated successfully');
                    navigate('/policy-game');
                } else {
                    console.log('Error updating room');
                }
            }).catch((error) => {
                console.error('Error updating room:', error);
            });


        }

        if (socket) {
            socket.on('broadcastMessage', (data) => {
                setMessages((prevMessages) => [...prevMessages, data.message]);
                setUsers((prevUsers) => [...prevUsers, 'Facilitator']);
                setSnackbarMessage(data.message);
                setSnackbarOpen(true);
            });

            socket.on('message', (data) => {
                console.log('message from ', teamName);
                setMessages((prevMessages) => [...prevMessages, data.message]);
                setUsers((prevUsers) => [...prevUsers, data.user]);
            });


            return () => {
                socket.off('broadcastMessage');
                socket.off('message');
                socket.off('selection update');
            };
        }
    }, [socket, accessGranted, navigate, setTeamName]);

    const handleAlertFacilitator = () => {
        if (socket) {
            socket.emit('alertFacilitator', {teamName});
            setAlerted(true);
            setTimeout(() => {
                setAlerted(false);
            }, 1000)
        }
    };

    const handleNoPasswordSubmit = (e: any) => {
        e.preventDefault();
        setAccessGranted(true);
    }

    const handleGoToPolicyGame = () => {
        navigate('/policy-game');
    };

    const handleGoToHygieneGame = () => {
        if (!socket) return;
        axios.put('/api/update-room', {
            sid: socket.id,
            room: 'hygiene'
        }).then((response) => {
            if (response.data.success) {
                console.log('Room updated successfully');
                navigate('/home-hygiene');
            } else {
                console.log('Error updating room for hygiene');
            }
        }).catch((error) => {
            console.error('Error updating room:', error);
        });
    }

    const debugLockdown = () => {

        if (!socket) return;
        axios.put('/api/update-room', {
            sid: socket.id,
            room: 'lockdown'
        }).then((response) => {
            if (response.data.success) {
                console.log('Room updated successfully');
                navigate('/lockdown-legends-game');
            } else {
                console.log('Error updating room for hygiene');
            }
        }).catch((error) => {
            console.error('Error updating room:', error);
        });

    }

    const debugPL = () => {
        if (!socket) return;
        axios.put('/api/update-room', {
            sid: socket.id,
            room: 'hygiene'
        }).then((response) => {
            if (response.data.success) {
                console.log('Room updated successfully');
                navigate('/phantom-lines');
            } else {
                console.log('Error updating room for hygiene');
            }
        }).catch((error) => {
            console.error('Error updating room:', error);
        });
    }

    const debugII = () => {
        navigate('/insider-insights-game')
    }

    const debugend = () => {
        navigate('/end-screen');
    }

    const debugFG = () => {
        navigate('/finale-game');
    }

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                <AppBar style={{top: 0}} position="sticky" color="turquoise">
                    <Toolbar style={{justifyContent: 'space-between'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h6" component="div" sx={{mr: 2}}>
                                Escape Room Lobby
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center', flexGrow: 1}} gap={2}>
                            <TeamList isLeader={false}/>
                            <Button variant="contained" color="tur2" onClick={handleAlertFacilitator}
                                    sx={{width: 200, ml: 2}}>
                                {alerted ? "Facilitator Alerted" : "Alert Facilitator"}
                            </Button>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h6" component="div" sx={{mr: 2}} visibility={"hidden"}>
                                Escape Room Lobby
                            </Typography>
                        </Box>
                    </Toolbar>
                </AppBar>

                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', marginTop: theme.spacing(2)}}>
                    <img src={image} alt="First Lobby" style={{width: '100%', maxHeight: '300px', objectFit: 'cover'}}/>
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    padding: theme.spacing(2),
                    flexGrow: 1
                }}>
                    <Box sx={{
                        flex: 1,
                        marginRight: theme.spacing(2),
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'stretch'
                    }}>
                        <StyledCard
                            sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <CardContent>
                                <StaticLeaderboard refresh={refreshLeaderboard}/>
                            </CardContent>
                        </StyledCard>
                    </Box>

                    <Box sx={{
                        flex: 1,
                        marginLeft: theme.spacing(2),
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'stretch'
                    }}>
                        <StyledCard
                            sx={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom textAlign={"center"}>
                                    Advance to the Next Room
                                </Typography>
                                <StyledForm onSubmit={handleNoPasswordSubmit}>
                                    <SubmitButton variant="contained" color="turquoise" onClick={handleNoPasswordSubmit}
                                                  data-cy="continue-button">
                                        Continue
                                    </SubmitButton>
                                </StyledForm>
                            </CardContent>
                        </StyledCard>
                    </Box>
                    {/* <Button onClick={debugII}>GO TO II</Button> */}
                </Box>
                {ENV === 'local' && (
                    <Box>
                        <Button onClick={debugLockdown}>Go to LL</Button>
                        <Button onClick={debugPL}>Go to PL</Button>
                        <Button onClick={handleGoToPolicyGame}>Go to PP</Button>
                        <Button onClick={debugFG}>Go to FG</Button>
                        <Button onClick={handleGoToHygieneGame}>Go to HH</Button>
                        <Button onClick={debugend}>Go to end</Button>
                        <Button onClick={debugII}>GO TO II</Button>
                    </Box>
                )}
                <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto'}}>
                    <Logos/>
                </Box>
            </Box>

            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                open={snackbarOpen}
                autoHideDuration={null}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="info" sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default SampleRoom;
