import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";

interface TeamContextType {
  teamName: string;
  setTeamName: (teamName: string) => void;
}
/* 
this keeps track of the team state ensuring that a user stays on the same team. Uses JWT authenication tokens. 
*/

const TeamContext = createContext<TeamContextType | undefined>(undefined);

export const useTeam = () => {
  const context = useContext(TeamContext);
  if (!context) {
    throw new Error('useTeam must be used within a TeamProvider');
  }
  return context;
};

interface MyToken {
  role: string;
  teamName: string;
}

export const TeamProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [teamName, setTeamName] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
        const decoded = jwtDecode<MyToken>(token);
        setTeamName(decoded.teamName);
    }
  }, []);

  return (
    <TeamContext.Provider value={{ teamName, setTeamName }}>
      {children}
    </TeamContext.Provider>
  );
};
