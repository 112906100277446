import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, TextField, Button, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../contexts/SocketContext';
import { useTeam } from '../contexts/TeamContext';
import { theme } from '../palette';
import { ThemeProvider } from '@mui/material/styles';
import Logos from '../components/Logos';
import Background from './Background';
import usePreventBackNavigation from '../components/PreventNavigation';

interface APIResponse {
  token: string;
  role: string;
}

const OnboardingPage: React.FC = () => {
  usePreventBackNavigation();
  const navigate = useNavigate();
  const [teamNameInput, setTeamNameInput] = useState('');
  const [password, setPassword] = useState('');
  const { connectSocket } = useSocket();
  const { setTeamName } = useTeam();
  const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 2000); // Clear the error message after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setErrorMessage('');
    try {
      const response = await fetch('/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ teamName: teamNameInput, password }),
      });

      const data: APIResponse = await response.json();
      if (response.ok) {
        console.log("Received response.");
        localStorage.setItem('jwt', data.token);
        localStorage.setItem('teamName', teamNameInput);
        localStorage.setItem('role', data.role);

        setTeamName(teamNameInput);
        connectSocket();

        if (data.role === 'facilitator') {
          navigate('/facilitator-dashboard');
        } else {
          navigate('/email-entry');
        }
      } else {
        setErrorMessage('Wrong username or password');
        throw new Error(data.token || 'Authentication failed');
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error("Authentication error:", error.message);
        setErrorMessage('Wrong username or password');
        // alert(teamNameInput + " " + password + " " + error.message);
      }
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
      }}>
        <Background />
        <Box sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: { xs: '100%', md: '50%' },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          background: '#2BD2D2', // Corrected turquoise color
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            textAlign: 'center',
            mb: 4,
          }}>
            <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', mt: 2 }}>
              Welcome to the Visier Escape Room!
            </Typography>
          </Box>
          <Container maxWidth="sm" sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: 'white',
            padding: 4,
            borderRadius: 2,
            width: '80%',
            maxWidth: '400px',
          }}>
            <Typography variant="h5" align="center" gutterBottom>
              Enter your Team Login Information
            </Typography>
            {errorMessage && (
<Alert severity="error">{errorMessage}</Alert>
)}
            <form onSubmit={handleSubmit} data-cy="login-form">
              <TextField
                label="Team Name"
                fullWidth
                variant="outlined"
                margin="normal"
                value={teamNameInput}
                onChange={(e) => setTeamNameInput(e.target.value)}
                data-cy="team-name-input"
              />
              <TextField
                label="Password"
                fullWidth
                variant="outlined"
                margin="normal"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                data-cy="password-input"
              />
              <Button type="submit" variant="contained" color = "turquoise" fullWidth data-cy="login-button">
  Login
</Button>
            </form>
          </Container>
        </Box>
        {/* <Box sx={{ mt: 'auto', width: '100%' }}>
          <Logos />
        </Box> */}
      </Box>
    </ThemeProvider>
  );
};

export default OnboardingPage;
