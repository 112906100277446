import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, TextField, Button, CircularProgress, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../contexts/SocketContext';
import { theme } from '../palette';
import { ThemeProvider } from '@mui/material/styles';
import Logos from '../components/Logos';
import Background from './Background'; // Assuming Background component is the same
import usePreventBackNavigation from '../components/PreventNavigation';

// this is where they enter their visier email address. 

const EnterUsernameRoom = () => {
  usePreventBackNavigation();
  const navigate = useNavigate();
  const [playerName, setPlayerName] = useState('');
  const { socket } = useSocket();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    if (socket) {
      socket.emit("userName", { sid: socket.id, username: playerName }, () => {
        console.log("Set username");
        socket.emit("getGameName", (currGame: string) => {
          console.log("Got game name: " + currGame);
          setLoading(false);
          if (currGame === "policy") {
            navigate('/sample-room');
          } else if (currGame === "hygiene") {
            navigate('/lobby');
          } else if (currGame === "phantom-lines") {
            navigate('/lobby-hygiene');
          } else if (currGame === "lockdown") {
            navigate('/fourth-lobby')
          } else if (currGame === "insider") {
            navigate('/fifth-lobby');
          }
        });
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        position: 'relative',
      }}>
        <Background />
        <Box sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: { xs: '100%', md: '50%' },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          background: '#2BD2D2', // Corrected turquoise color
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            textAlign: 'center',
            mb: 4,
          }}>
            <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', mt: 2 }}>
              Welcome to the Visier Escape Room!
            </Typography>
          </Box>
          <Container maxWidth="sm" sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            background: 'white',
            padding: 4,
            borderRadius: 2,
            width: '80%',
            maxWidth: '400px',
          }}>
            <Typography variant="h5" align="center" gutterBottom data-cy="email-label">
              Please Enter Your Player Name
            </Typography>
            <form onSubmit={handleSubmit} data-cy="email-form">
              <TextField
                label="player name"
                fullWidth
                variant="outlined"
                margin="normal"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
                data-cy="playername-input"
              />
              <Button type="submit" variant="contained" color = 'turquoise' fullWidth data-cy="continue-button">
                Continue
              </Button>
              <LinearProgress style={{visibility: loading ? "visible" : "hidden"}} color='tur2'/>
            </form>
          </Container>
        </Box>
        {/* <Box sx={{ mt: 'auto', width: '100%' }}>
          <Logos />
        </Box> */}
      </Box>
    </ThemeProvider>
  );
};

export default EnterUsernameRoom;
