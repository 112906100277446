import { createTheme } from '@mui/material/styles';
declare module '@mui/material/styles' {
    interface Palette {
      turquoise: Palette['primary'];
      tur2: Palette['primary'];
    }
  
    interface PaletteOptions {
      turquoise?: PaletteOptions['primary'];
      tur2?: PaletteOptions['primary'];
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
      turquoise: true;
      tur2: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
      turquoise: true;
      tur2: true;
    }
}

declare module '@mui/material/AppBar' {
    interface AppBarPropsColorOverrides {
      turquoise: true;
      tur2: true;
    }
}

declare module '@mui/material/TextField' {
    interface TextFieldPropsColorOverrides {
      turquoise: true;
      tur2: true;
    }
}

declare module '@mui/material/OutlinedInput' {
    interface OutlinedInputPropsColorOverrides {
      turquoise: true;
      tur2: true;
    }
}

declare module '@mui/material/Select' {
    interface SelectPropsColorOverrides {
      turquoise: true;
      tur2: true;
    }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    turquoise: true;
    tur2: true;
  }
}


export const theme = createTheme ({
    palette: {
        turquoise: {
            main: '#6FDBE0',
            light: '#A4DDE0',
            dark: '#40BABF',
            contrastText: "#194a4d",
        },

        tur2: {
            main: '#2D8286',
            light: '#40BABF',
            dark: '#194a4d',
            contrastText: "#FFFFFF"
        }
    }
})
