import React, { createContext, useContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';

/*
This keeps track of the socket, ensuring the same socket is present throguhout the appication and that that socket stays connected
it is initialized when the user joins. Connect socket is called. When the user disconnects, disconnect socket is called
*/

interface SocketContextType {
    socket: Socket<DefaultEventsMap, DefaultEventsMap> | null;
    connectSocket: () => void;
    disconnectSocket: () => void;
}


const SocketContext = createContext<SocketContextType>({
    socket: null,
    connectSocket: () => {},
    disconnectSocket: () => {},
});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [socket, setSocket] = useState<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null);

    const connectSocket = () => {
        const token = localStorage.getItem('jwt');
        if (!token || socket) {
            console.log("Failed to create socket.")
            return;
        }
        console.log("Creating new socket...");

        /*
        MAKE SURE TO CHANGE THESE WHEN SWITCHING FROM LOCAL AND DEV. THIS IS IMPORTANT. ONE USES LOCAL SOCKETS, ONE USES AZURE SOCKETS
        */
        // to local: comment out


        const newSocket = io('https://vsecesc-deploy-socket.webpubsub.azure.com', {
            path: "/clients/socketio/hubs/Hub",
            auth: { token },
            withCredentials: true,
            transportOptions: {
                polling: {
                    extraHeaders: {
                        'Access-Control-Allow-Origin': 'http://localhost:5000'
                    }
                }
            }
        });

        // to local: uncomment
        // const newSocket = io('http://localhost', {
        //   auth: { token },
        //   withCredentials: true,
        //   transportOptions: {
        //     polling: {
        //       extraHeaders: {
        //         'Access-Control-Allow-Origin': 'http://localhost:3000' //:3000
        //       }
        //     }
        //   }
        // });

        newSocket.on('connect', () => {
            console.log('Connected to server');
        });

        newSocket.on('disconnect', () => {
            console.log('Disconnected from server');
            alert(`Whoops! You have been disconnected. Please re-open the game and log in again with the same username.`);
        });

        setSocket(newSocket);
    };

    const disconnectSocket = () => {
        if (socket) {
            socket.disconnect();
            setSocket(null);
        }
    };


    return (
        <SocketContext.Provider value={{ socket, connectSocket, disconnectSocket }}>
            {children}
        </SocketContext.Provider>
    );
};