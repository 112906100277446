import React, {useState, useEffect} from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import {ThemeProvider} from '@mui/material/styles';
import {Box, Container, Typography, Grid, Paper, Snackbar, Alert} from '@mui/material';
import {useSocket} from '../../contexts/SocketContext';
import {useNavigate} from 'react-router-dom';
import {useTeam} from '../../contexts/TeamContext';
import InstructionPopup from '../../components/InstructionPopup';
import axios from 'axios';
import {theme} from '../../palette';
import Logos from '../../components/Logos';
import usePreventNavigation from '../../components/PreventNavigation';
import GameCompleteDialog from '../../components/GameCompleteDialog';
import LinearProgressWithLabel from "./components/LinearProgressWithLabel";
import CardComponent from "./components/CardComponent";
import Bucket from './components/Bucket'
import UnanimousYesPopup from "../../components/UnanimousYesPopup";

import p1 from './img/p1.png';
import p2 from './img/p2.png';
import p3 from './img/p3.png';
import p4 from './img/p4.png';
import p5 from './img/p5.png';
import p6 from './img/p6.png';
import p7 from './img/p7.png';
import p8 from './img/p8.png';
import p9 from './img/p9.png';
import p10 from './img/p10.png';
import p11 from './img/p11.png';
import p12 from './img/p12.png';
import p13 from './img/p13.png';
import p14 from './img/p14.png';
import p15 from './img/p15.png';
import p16 from './img/p16.png';
import p17 from './img/p17.png';
import p18 from './img/p18.png';
import p19 from './img/p19.png';
import p20 from './img/p20.png';
import p21 from './img/p21.png';
import {Card} from "./types";
import GameHeader from "../../components/GameHeader";


// # policies. change if number of policies changes

const NUM_POLICIES = 21;

const letterMap: { [key: string]: string } = {
    "Cloud Services Policy": "A",
    "Email and Communication Policy": "B",
    "Acceptable Use Policy": "C",
    "Information Classification Handling": "D",
    "Physical Security Policy": "E",
    "Social Media Policy": "F"
}

// policy cards and their corresponding policy statements
const cards: Card[] = [
    {
        id: 1,
        text: "Employees' personal cloud services accounts must not be used for processing Visier information.",
        policy: 'Cloud Services Policy',
        img: p1
    },
    {
        id: 2,
        text: 'Users must not use their personal email (e.g. janedoe@hotmail.com) or any other personal electronic communication accounts (e.g. Facebook, Whatsapp, Skype) to transmit Visier information.',
        policy: 'Email and Communication Policy',
        img: p2
    },
    {
        id: 3,
        text: 'Changes to the original scope of the acquisition must be reviewed by the vendor risk management team prior to being implemented, and managed via the change management process.',
        policy: 'Cloud Services Policy',
        img: p3
    },
    {
        id: 4,
        text: 'All users are responsible and accountable for using social media (both professionally and personally) in a manner that will not tarnish or degrade Visier\'s reputation. Users must not disclose confidential or proprietary Visier information, or information about Visier\'s customers (including customer names that are not publicly available on the Visier corporate website).',
        policy: 'Social Media Policy',
        img: p4
    },
    {
        id: 5,
        text: 'Only Visier computing resources can be used to access Customer Data and/or Visier\'s data centers. Personal devices must not be used to connect to Visier\'s data centers.',
        policy: 'Acceptable Use Policy',
        img: p5
    },
    {
        id: 6,
        text: 'Employees should wear their access card on their outer garments to clearly identify themselves as employees when inside Visier offices.',
        policy: 'Physical Security Policy',
        img: p6
    },
    {
        id: 7,
        text: 'Information that is not classified explicitly is "Confidential" by default.',
        policy: 'Information Classification Handling',
        img: p7
    },
    {
        id: 8,
        text: 'Users must not discuss confidential company information outside of Visier offices and/or with Visier teams that are not authorized to access such information.',
        policy: 'Acceptable Use Policy',
        img: p8
    },
    {
        id: 9,
        text: 'If a file containing Restricted information is authorized for external distribution, it must be protected from modification and printing whenever possible and technically feasible.',
        policy: 'Information Classification Handling',
        img: p9
    },
    {
        id: 10,
        text: 'Employees must not sign-up for or agree to Terms of Service or other click-through agreements for any cloud services unless it has been reviewed and processed.',
        policy: 'Cloud Services Policy',
        img: p10
    },
    {
        id: 11,
        text: 'All email, electronic files, and information created, sent, and/or recieved using Visier\'s systems and networks are considered Visier property.',
        policy: 'Email and Communication Policy',
        img: p11
    },
    {
        id: 12,
        text: 'Users must exercise good judgement regarding the reasonable personal use of Visier computing resources. Personal use is permitted on a limited or incidental basis (e.g. during work breaks) provided it does not interfere with or impact Visier\'s business operations and work quality / productivity.',
        policy: 'Acceptable Use Policy',
        img: p12
    },
    {
        id: 13,
        text: 'Avoid inputting Visier data or information classified as "Restricted" or "Confidential" into AI systems.',
        policy: 'Acceptable Use Policy',
        img: p13
    },
    {
        id: 14,
        text: 'Each person must only use their own access card to enter controlled doors within Visier offices. The sharing of access cards and tailgating is prohibited.',
        policy: 'Physical Security Policy',
        img: p14
    },
    {
        id: 15,
        text: 'Visier laptops must be secured to prevent unauthorized access, theft and damages (e.g. locked in file cabinets and desks) when they are no longer used at the end of the day.',
        policy: 'Physical Security Policy',
        img: p15
    },
    {
        id: 16,
        text: 'Integration between new and existing cloud services, including the installation and use of plugins and APIs, must not be implemented without review and authorization via Visier\'s vendor management process',
        policy: 'Cloud Services Policy',
        img: p16
    },
    {
        id: 17,
        text: 'Users must not store Visier information on third-party storage locations (e.g. personal Dropbox) that have not been approved by Visier.',
        policy: 'Acceptable Use Policy',
        img: p17
    },
    {
        id: 18,
        text: 'Employees must take reasonable steps to protect their access cards to prevent unauthorized access and misuse. Stolen or lost access cards must be reported to Facilities as soon as possible.',
        policy: 'Physical Security Policy',
        img: p18
    },
    {
        id: 19,
        text: 'Users must use extreme caution when receiving emails from unknown senders, or emails that request sensitive company or personal information.',
        policy: 'Email and Communication Policy',
        img: p19
    },
    {
        id: 20,
        text: 'Users must ensure that contents within Visier electronic communications contain professional language.',
        policy: 'Email and Communication Policy',
        img: p20
    },
    {
        id: 21,
        text: 'When creating and sharing content on social media platforms, users must disclose that they are a Visier employee or work for Visier, especially if they are creating content relating to Visier\'s industry.',
        policy: 'Social Media Policy',
        img: p21
    }
];

const TIME_LIMIT = 10 * 60;

// component representing Puzzling Policies game

const PolicyGame: React.FC = () => {
    const {socket} = useSocket();
    usePreventNavigation();
    const {teamName} = useTeam();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const [attemptsUsed, setAttemptsUsed] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    const [score, setScore] = useState(0);
    const [gameScore, setGameScore] = useState(0);
    const [remainingTime, setRemainingTime] = useState<number>(TIME_LIMIT);
    const [halfTimeAlertShown, halfTimeSecondAlertShown] = useState(false);
    const [thirtySecondAlertShown, setThirtySecondAlertShown] = useState(false);
    const [isLeader, setIsLeader] = useState(false);
    const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(true);
    const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);

    const [gameWon, setGameWon] = useState(false);
    const [correct, setCorrect] = useState(0);
    const [alerted, setAlerted] = useState(false);
    const [finalAlertOpen, setFinalAlertOpen] = useState(false);
    const [displayScore, setDisplayScore] = useState(0);
    const [displaySuccess, setDisplaySuccess] = useState(false);
    const [displayBP, setDisplayBP] = useState(0);
    const [timerCanCount, setTimerCanCount] = useState(false);
    const [leaderName, setLeaderName] = useState("Not Selected");

    const [currCard, setCurrCard] = useState(0);

    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [pendingAction, setPendingAction] = useState<{ policy: string } | null>(null);


    // end game sequence when game is won

    useEffect(() => {
        if (gameWon) {
            handleEndGame(true);
        }
    }, [gameWon])


    // randomly select team leader
    useEffect(() => {
        if (socket) {
            socket.emit("randomSelect", {game: "policy", sid: socket.id});
            socket.on('randomSelect', (data) => {
                console.log(`Picked: ${data.picked}, username: ${data.username}, recipient: ${data.recipient}, id: ${socket.id}`);
                if (data.username === data.picked && data.recipient === socket.id) {
                    console.log("Username " + data.username + " matches the picked socket.");
                    setIsLeader(true);
                    socket.emit("broadcastTeamList");
                } else if (data.username !== data.picked && data.recipient === socket.id) {
                    setIsLeader(false);
                }
                setLeaderName(data.picked);
            });
            return () => {
                socket.off('randomSelect');
            };
        }
    }, [socket])

    useEffect(() => {
        if (socket) {

            // receiving message with core game elements change
            socket.on('broadcastPPChange', (data) => {
                console.log("data received by server on PPchange is:", data);

                if (isLeader && data.scoreChange !== 0) {
                    socket.emit('broadcastScoreChange', {teamName: teamName, score: data.scoreChange});
                } else if (isLeader && data.scoreChange === 0) {
                    setCorrect(data.correct);
                }


                console.log("The current card is now set to: ", data.currCard);
                setCurrCard(data.currCard);

                checkIfAllCorrect(data.currCard);
                setGameScore(data.gameScore);
                setAttemptsUsed(data.attemptsUsed);

            })

            // receiving message to open snackbar
            socket.on('broadcastMessage', (data) => {
                setSnackbarMessage(data.message);
                setSnackbarOpen(true);
            });

            // receiving message with score change
            socket.on('broadcastScoreChange', (data) => {
                setScore(data.score);
            });

            socket.on('startTimer', () => {
                if (isLeader) {
                    setTimerCanCount(true);
                }
            });

            socket.on('manualSubmit', async (data) => {
                console.log("test suite dropping card now, currCard is: ", currCard);
                await handleDrop(data.policy);
            });

            // receiving message to end game
            socket.on('completePolicy', (data) => {
                setDisplayScore(data.score);
                setDisplayBP(data.bp);
                setDisplaySuccess(data.success);
                setTimerCanCount(false);
                setFinalAlertOpen(true);
            });

            return () => {
                socket.off('broadcastPPChange');
                socket.off('broadcastMessage');
                socket.off('broadcastScoreChange');
                socket.off('startTimer');
                socket.off('manualSubmit');
                socket.off('completePolicy');
            };
        }
    }, [socket, isLeader, teamName]);

    useEffect(() => {
        if (socket) {
            socket.on("leaderActionSubmitted", ({policy}) => {
                // Set the pending action state based on the leader's submission
                console.log("received leaderActionSubmitted in PolicyGame.");
                console.log("policy is:", policy);
                setPendingAction({policy: policy});
                setIsPopupOpen(true); // Open the popup for team members
            });

            return () => {
                socket.off("leaderActionSubmitted");
            };
        }
    }, [socket]);

    // when points dialog is closed
    const onCloseLastDialog = (success: boolean) => {
        setFinalAlertOpen(false);

        if (socket) {
            const sid = socket.id;
            axios.put('/api/update-room', {
                sid: sid,
                room: 'lobby'
            }).then((response) => {
                if (response.data.success) {
                    console.log('Room updated successfully');
                } else {
                    console.log('Error updating room');
                }
            }).catch((error) => {
                console.error('Error updating room:', error);
            });
        }

        if (success) {
            navigate('/policy-end');
        } else {
            navigate('/lobby');
        }
    }


    // handle dropping a card into a bucket. score starts at 4, goes to 2 then 1 then 0 for each incorrect guess on that card
    // then, emit message containing core game element changes
    const handleDrop = async (policy: string) => {

        if (!isLeader || !socket) return;

        let team_size = 1;

        try {
            const response = await axios.get(`/api/team-in-game`, {
                params: {
                    teamName: teamName
                }
            });

            const {team_size_response} = response.data;

            team_size = team_size_response;
        } catch (e) {
            console.error("couldn't get teamsize: ", e);
        }


        if (team_size === 1) {
            console.log("card is: ", currCard, " skipping leaderActionSubmit since teamsize is 1, eval directly");
            handleEval(true, policy);
            return;
        } else {
            console.log("card is: ", currCard, " now emitting leaderActionSubmit");
            socket.emit("leaderActionSubmit", {
                policy: policy
            });
            // Store the pending action
            setPendingAction({policy: policy});

        }
    };

    const handleDecision = (decision: boolean) => {

        console.log("called handleDecision function in PolicyGame.");
        if (!pendingAction) console.log("no pendingAction, early returning");
        if (!isLeader) {
            console.log("not leader, early returning")
            return;
        }
        handleEval(decision);
    };


    const handleEval = (decision: boolean, directPolicy?: string) => {
        if (!socket) return;
        let policyAnswer;
        if (!pendingAction) {
            if (directPolicy !== undefined) {
                policyAnswer = directPolicy;
            } else {
                console.log("no pending action and no direct policy");
                return;
            }
        } else {
            const {policy} = pendingAction;
            policyAnswer = policy;
        }
        if (decision) {

            console.log("pendingAction is: ", pendingAction);

            console.log("reached decision: ", decision, "proceeding with og eval logic");

            // 0, 1, 2, 3, 4
            // numPolicies = 5
            // Proceed with the original card drop logic
            const card = cards[currCard];
            if (card.policy === policyAnswer) {
                console.log("In handledecision, the bucket was the right bucket.");

                let scoreIncrement = 4;

                const currAttempts = attemptsUsed[cards[currCard].id]
                if (currAttempts === 1) {
                    scoreIncrement = 2;
                } else if (currAttempts == 2) {
                    scoreIncrement = 1;
                } else if (currAttempts >= 3) {
                    scoreIncrement = 0;
                }

                let newCurrCard = currCard;
                if (currCard < NUM_POLICIES) {
                    newCurrCard += 1;
                    console.log("not the last card, so incrementing current Card.")
                }


                socket.emit("broadcastPPChange", {
                    attemptsUsed: attemptsUsed,
                    rippleCVisible: true,
                    rippleIVisible: false,
                    currCard: newCurrCard,
                    policy: policyAnswer,
                    scoreChange: scoreIncrement,
                    gameScore: gameScore + scoreIncrement,
                    correct: correct + 1,
                });

            } else {
                const id = cards[currCard].id;
                let newAttemptsUsed = attemptsUsed;
                newAttemptsUsed[id]++;


                socket.emit("broadcastPPChange", {
                    attemptsUsed: newAttemptsUsed,
                    rippleCVisible: false,
                    rippleIVisible: true,
                    currCard: currCard,
                    policy: policyAnswer,
                    scoreChange: 0,
                    gameScore: gameScore,
                    correct: correct,
                });

            }
        }

        // Clear the pending action and close the popup
        setPendingAction(null);
        setIsPopupOpen(false);
    }

    // calculate bonus points based on remaining time
    const calculateBonusPoints = () => {
        return Math.max(Math.floor(remainingTime / 30), 0);
    };

    // if all cards correctly guessed, trigger game end sequence
    const checkIfAllCorrect = (currCard: number) => {
        console.log("currCard is: ", currCard, "and needed to endgame is: ", NUM_POLICIES);
        if (currCard === NUM_POLICIES) {
            setGameWon(true);
        }
    };

    // alert facilitator
    const handleAlertFacilitator = () => {
        if (socket) {
            socket.emit('alertFacilitator', {teamName});
            setAlerted(true);
            setTimeout(() => {
                setAlerted(false);
            }, 1000)
        }
    };

    // the end game sequence. update leaderboard, emit event for displaying final popup
    // success = whether the game was completed before time ran out
    const handleEndGame = async (success: boolean) => {
        const bonusPoints = calculateBonusPoints();
        const totalScore = score + bonusPoints;

        if (isLeader) {
            try {
                await axios.post('/api/update-score', {teamName, score: totalScore - score});
                setRefreshLeaderboard(!refreshLeaderboard); // Toggle refresh state to reload leaderboard
            } catch (error) {
                console.error('Error updating score:', error);
            }

            if (socket) {
                socket.emit('completePolicy', {success: success, score: gameScore + bonusPoints, bp: bonusPoints});
            }
        } else {
            setFinalAlertOpen(true);
        }

    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleInstructionClose = async () => {
        setIsInstructionPopupOpen(false);
        if (isLeader) {
            setTimerCanCount(true);
        }

        try {
            const response = await axios.get(`/api/game-state`, {
                params: {
                    teamName: teamName,
                    currentGame: 'policy'
                }
            });

            console.log("before sync, currCard is: ", currCard, ", and gameScore is, ", gameScore);
            const {currCard: newCurrCard, gameScore: newGameScore, shouldPopupOpen: shouldPopupOpen} = response.data;
            if (shouldPopupOpen) {
                setIsPopupOpen(true);
            }

            if (isLeader){
                if (newCurrCard > currCard) setCurrCard(newCurrCard);
                if (newGameScore > gameScore) setGameScore(newGameScore);
            } else {
                setCurrCard(newCurrCard);
                setGameScore(newGameScore);
            }

            console.log("after sync, currCard is: ", currCard, ", and gameScore is, ", gameScore);
            console.log("Game details synced for policyGame");

            checkIfAllCorrect(newCurrCard);

        } catch (error) {
            console.log("error getting game details for policyGame");
        }

    };

    const onTimeUp = () => {
        setTimerCanCount(false);
        handleEndGame(false);
    };


    // update timer, show half time and 60 sec alerts if necessary
    const handleTimeUpdate = (timeLeft: number) => {
        if (timerCanCount) {
            setRemainingTime(timeLeft);
        }

        if (timeLeft <= (TIME_LIMIT) / 2 && !halfTimeAlertShown) {
            setSnackbarMessage('Half the time remaining!');
            setSnackbarOpen(true);
            halfTimeSecondAlertShown(true);
        }

        if (timeLeft <= 60 && !thirtySecondAlertShown) {
            setSnackbarMessage('60 seconds remaining!');
            setSnackbarOpen(true);
            setThirtySecondAlertShown(true);
        }
    };


    return (
        <ThemeProvider theme={theme}>
            <DndProvider backend={HTML5Backend}>
                <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
                    <GameHeader
                        isLeader={isLeader}
                        setIsInstructionPopupOpen={setIsInstructionPopupOpen}
                        handleAlertFacilitator={handleAlertFacilitator}
                        refreshLeaderboard={refreshLeaderboard}
                        alerted={alerted}
                        timerCanCount={timerCanCount}
                        teamName={teamName}
                        TIME_LIMIT={TIME_LIMIT}
                        handleTimeUpdate={handleTimeUpdate}
                        onTimeUp={onTimeUp}
                    />

                    <Container maxWidth={false} sx={{flex: '1 0 auto'}}>


                        <Grid container spacing={2} paddingTop={'4vh'}>
                            <Grid item xs={4}>
                                <Paper elevation={2}>
                                    <Box p={1}>
                                        <Box mt={1}>
                                            {['Cloud Services Policy', 'Email and Communication Policy', 'Acceptable Use Policy', 'Information Classification Handling', 'Physical Security Policy', 'Social Media Policy'].map((policy) => (
                                                <Bucket key={policy} policy={policy} label={letterMap[policy]}
                                                        onDrop={handleDrop}
                                                        data-cy={`bucket-${policy.replace(/\s+/g, '-').toLowerCase()}`}
                                                />
                                            ))}
                                        </Box>
                                    </Box>
                                </Paper>
                            </Grid>
                            <Grid item xs={8}>
                                <Paper elevation={2}>
                                    <Box p={1} display="flex" flexDirection="column" alignItems="center">
                                        <Box width="100%">
                                            <LinearProgressWithLabel color='turquoise' variant="determinate"
                                                                     value={100 * (1 - ((NUM_POLICIES - currCard) / NUM_POLICIES))}/>
                                        </Box>
                                        <Box width="100%" textAlign="left" mt={1}>
                                            <Typography variant="h6"
                                                        data-cy="game-score">Score: {gameScore}</Typography>
                                        </Box>
                                        {!gameWon && cards[currCard] && (
                                            <Grid item xs={6} key={cards[currCard].id}>
                                                <CardComponent id={cards[currCard].id} text={cards[currCard].text}
                                                               policy={cards[currCard].policy} img={cards[currCard].img}
                                                               isLeader={isLeader}
                                                               data-cy="policy-card"/>
                                            </Grid>
                                        )}
                                    </Box>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                    <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto'}}>
                        <Logos/>
                    </Box>
                    <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                              open={snackbarOpen}
                              autoHideDuration={6000}
                              onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="info" sx={{width: '100%'}}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
                {isPopupOpen && socket && (
                    <UnanimousYesPopup
                        open={isPopupOpen}
                        leaderAction={pendingAction ? `Place card "${cards[currCard].text}" in bucket "${pendingAction.policy}"` : ""}
                        isLeader={isLeader}
                        sid={socket.id || ""}
                        teamName={teamName}
                        onDecision={handleDecision}
                        onClose={() => setIsPopupOpen(false)}
                    />
                )}

                <InstructionPopup
                    open={isInstructionPopupOpen}
                    onClose={handleInstructionClose}
                    title="Room Instructions"
                    boldText={`The team leader this round is: ${leaderName}`}
                    instructions={[
                        "1. Read each policy card carefully.",
                        "2. Drag and drop each card into the correct policy bucket.",
                        "3. Correct matches increase your score, while incorrect matches decrease it.",
                        "4. The game ends when all cards are sorted or time runs out.",
                        "5. Alert the facilitator if you need assistance.",
                        "",
                        "The team leader controls what happens on-screen.",
                        "Other players can see what the team leader does.",
                        "",
                        "Scoring: 1st try: 4 points, 2nd try: 2 points, 3rd try: 1 point, 4th try: 0 points, 5th try: 0 points",
                    ]}
                />

                <GameCompleteDialog open={finalAlertOpen} onClose={onCloseLastDialog} success={displaySuccess}
                                    score={displayScore} bp={displayBP}/>
            </DndProvider>

        </ThemeProvider>
    );
};

export default PolicyGame;
