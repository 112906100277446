import React, { useState, useEffect } from 'react';
import { Container, Typography, AppBar, Toolbar, Button, Box, Grid, Collapse, Snackbar, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../palette';
import { ThemeProvider } from '@mui/material/styles';
import Logos from '../../components/Logos';
import Leaderboard from '../../components/Leaderboard';
import InstructionPopup from '../../components/InstructionPopup';
import TeamList from '../../components/TeamList';
import { useSocket } from '../../contexts/SocketContext';
import { useTeam } from '../../contexts/TeamContext';
import usePreventNavigation from '../../components/PreventNavigation';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import doorimage from './img/Opendoor4.png';
import InfoIcon from '@mui/icons-material/Info';

// end page for HH, looks the same as everything else, basically a copy paste for all end pages. Basic UI

const HomeHygieneEnd: React.FC = () => {
  const navigate = useNavigate();
  usePreventNavigation();
  const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(false);
  const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
  const [leaderboardOpen, setLeaderboardOpen] = useState(false);
  const { socket } = useSocket();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const {teamName} = useTeam();
  const { width, height } = useWindowSize(); // For responsive confetti
  const [confettiActive, setConfettiActive] = useState(true);

  useEffect(() => {
    if (socket) {
      socket.on('broadcastMessage', (data) => {
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
      });

      return () => {
        socket.off('broadcastMessage');
      };
    }
  }, [socket]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setConfettiActive(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  const handleNextPage = () => {
    navigate('/lobby-hygiene');
    // Update the path to the next page as needed
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      {confettiActive && <Confetti width={width} height={height} />}
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
    <AppBar style={{top: 0}} position="sticky" color="turquoise">
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }} gap={2}>
            <Leaderboard refresh={refreshLeaderboard} />
            <TeamList isLeader={false}/>
            <Button variant="contained" color="tur2" onClick={() => setIsInstructionPopupOpen(true)} startIcon={<InfoIcon />}>
              Instructions
            </Button>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" component="div" sx={{ mr: 2 }} visibility={"hidden"}>
                {teamName} Escape Room Lobby
              </Typography>
            </Box>
        </Toolbar>
      </AppBar>

        <Container maxWidth="lg" sx={{ flex: '1 0 auto' }}>
          <Grid container spacing={3} justifyContent="center" alignItems="center" style={{ marginTop: '50px' }}>
            <Grid item xs={12} md={4}>
              <img src={doorimage} alt="door" style={{ maxWidth: '100%', height: 'auto' }} />
            </Grid>
            <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
              <Typography variant="h4" component="div" style={{ fontWeight: 'bold' }}>
                Congratulations! You have escaped the room.
              </Typography>
              <Button
                variant="contained"
                color="turquoise"
                style={{ marginTop: '20px' }}
                onClick={handleNextPage}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Container>

        <Collapse in={leaderboardOpen}>
          <Leaderboard refresh={refreshLeaderboard} />
        </Collapse>

        <InstructionPopup
          open={isInstructionPopupOpen}
          onClose={() => setIsInstructionPopupOpen(false)}
          title="Home Hygiene Instructions"
          instructions={[
            "1. Find 7 devices in the room that are connected to your home network, from your home office, that pose a security risk.",
            "2. Correctly answer the question about the best practice associated with the device.",
            "3. The more attempts you use, the less points you will get for answering the question.",
            "4. The game ends when all 7 devices are found and all questions correctly answered, or when time runs out.",
            "5. Alert the facilitator if you need assistance.",
            "",
            "The team leader controls what happens on-screen.",
            "Team members can see what the team leader does."
          ]}
        />
        <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto' }}>
          <Logos />
        </Box>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={snackbarOpen} autoHideDuration={null} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default HomeHygieneEnd;
