import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, AppBar, Toolbar, Button, Box, Snackbar, Alert, Collapse } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import suspectsImage from './img/Suspects.png';
import { useTeam } from '../../contexts/TeamContext';
import { useSocket } from '../../contexts/SocketContext';
import { theme } from '../../palette';
import {ThemeProvider} from '@mui/material/styles';
import Leaderboard from '../../components/Leaderboard';
import InstructionPopup from '../../components/InstructionPopup';
import Logos from '../../components/Logos';
import TeamList from '../../components/TeamList';
import usePreventBackNavigation from '../../components/PreventNavigation';
import InfoIcon from '@mui/icons-material/Info';
// Intro page showing all the suspects. Basic UI to get users familiar with how II works

const suspects = [
  {
    name: "Alice",
    description: "Disgruntled employee spreading company rumours."
  },
  {
    name: "Bob",
    description: "Recent hire who knows more than he should about the company."
  },
  {
    name: "Eve",
    description: "Data Analyst who feels underappreciated."
  },
  {
    name: "Malory",
    description: "Contractor with privileged access."
  },
  {
    name: "Oscar",
    description: "System administrator with too much power."
  },
  {
    name: "Trent",
    description: "Software developer paying top dollar for rent."
  }
];

const InsiderInsightsIntro: React.FC = () => {
  usePreventBackNavigation();
  const navigate = useNavigate();
  const { socket } = useSocket();
  const { teamName } = useTeam();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isInstructionPopupOpen, setIsInstructionPopupOpen] = useState(false);
  const [refreshLeaderboard, setRefreshLeaderboard] = useState(false);
  const [leaderboardOpen, setLeaderboardOpen] = useState(false); // State to control leaderboard visibility
  const [alerted, setAlerted] = useState(false);

  useEffect(() => {
    if (socket) {
      socket.on('broadcastMessage', (data: any) => {
        setSnackbarMessage(data.message);
        setSnackbarOpen(true);
      });

      return () => {
        socket.off('broadcastMessage');
      };
    }
  }, [socket]);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleNavigation = () => {
    navigate('/insider-insights-game'); // Adjust this to your next game route
  };
  
  const handleAlertFacilitator = () => {
    if (socket) {
      socket.emit('alertFacilitator', { teamName });
      setAlerted(true);
      setTimeout(() => {
        setAlerted(false);
      }, 1000)
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar style={{top: 0}} position="sticky" color="turquoise">
          <Toolbar style={{ justifyContent: 'space-between' }}>
            {/* <Typography variant="h6" component="div">
              {teamName} Insider Insights
            </Typography> */}
            <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }} gap={2}>
              <Leaderboard refresh={refreshLeaderboard} />
              <TeamList isLeader={false}/>
              <Button variant="contained" color="tur2" onClick={() => setIsInstructionPopupOpen(true)} startIcon={<InfoIcon />}>
              Instructions
            </Button>
            <Button variant="contained" color="tur2" onClick={handleAlertFacilitator} sx={{width: 200,  ml: 2}}>
                {alerted ? "Facilitator Alerted" : "Alert Facilitator"}
              </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" component="div" sx={{ mr: 2 }} visibility={"hidden"}>
                {teamName} Escape Room Lobby
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>

        <Container maxWidth="lg" sx={{ flex: '1 0 auto' }}>
          <Typography variant="h4" gutterBottom align="center" mt={5}>
            Meet the Suspects
          </Typography>
          <Typography variant="body1" align="center" paragraph>
            It is suspected that a trusted employee has been stealing sensitive data from your company.
            It has been narrowed down to six suspects. Your team must piece together the evidence and determine who has been stealing the sensitive data, and how they did it.
          </Typography>

          <Box display="flex" justifyContent="center" mt={3} mb={1}>
            <img src={suspectsImage} alt="Suspects" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>

          <Grid container spacing={2} justifyContent="center">
            {suspects.map((suspect, index) => (
              <Grid item xs={12} sm={4} md={2} key={index} style={{ textAlign: 'center' }}>
                <Typography variant="h6">{suspect.name}</Typography>
                <Typography variant="body2" color="textSecondary">{suspect.description}</Typography>
              </Grid>
            ))}
          </Grid>

        <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
          <Button variant="contained" color="turquoise" onClick={handleNavigation}>
            Continue
          </Button>
        </Box>
      </Container>
      <Box sx={{bottom: 0, position: 'sticky', flexShrink: 0, mt: 'auto' }}>
          <Logos />
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={snackbarOpen}
          autoHideDuration={null}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <InstructionPopup
  open={isInstructionPopupOpen}
  onClose={() => setIsInstructionPopupOpen(false)}
  title="Insider Insights Instructions"
  instructions={[
    <span><b>1. Read the clues:</b></span>,
    "Each clue will help you determine which Suspect matches with which Room and Weapon, and which Room matches which Weapon.",
    "",
    "The clues may be direct (e.g., “Alice is not in the office”) or indirect (e.g., “The person in the kitchen is not Bob”).",
    "",
    <span><b>2. Mark what you know:</b></span>,
    "Use symbols like an \"X\" to indicate what is not true and a checkmark “Y\" for what is true.\n",
    "Example:",
    "If the clue is \"Alice is not in HR’s Office,\" put an \"X\" where Alice and HR’s Office intersect.",
    "",
    "Use the intersections of Suspects, Rooms and Weapons to make your accusation."
  ]}
/>

      </Box>
    </ThemeProvider>
  );
};

export default InsiderInsightsIntro;