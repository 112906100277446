import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import { theme } from '../palette';
import { useSocket } from '../contexts/SocketContext';

/* 
This is the leaderboard used in the finale game since we needed a static version of it since in the finale it is shown at all times. 
Updating this leaderboard should be instant in the finale game.
*/

interface Team {
  teamName: string;
  points: number;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  border: '1px solid #ddd',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2),
  maxHeight: 400,
  overflowY: 'auto',
  borderRadius: '8px',
  width: '100%',
  boxSizing: 'border-box',
}));

const StyledTable = styled(Table)(({ theme }) => ({
  '& thead th': {
    backgroundColor: theme.palette.turquoise.main,
    color: theme.palette.common.white,
  },
  '& tbody tr:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StaticLeaderboard: React.FC<{ refresh: boolean }> = ({ refresh }) => {
  const [leaderboard, setLeaderboard] = useState<Team[]>([]);
  const { socket } = useSocket();

  const fetchLeaderboard = async () => {
    try {
      const response = await axios.get('/api/leaderboard');
      setLeaderboard(response.data);
      console.log("Leaderboard Fetched: ", response.data);
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, [refresh]);

  useEffect(() => {
    if (socket) {
      socket.on('scoreUpdated', (updatedLeaderboard: Team[]) => {
        setLeaderboard(updatedLeaderboard);
      });

      return () => {
        socket.off('scoreUpdated');
      };
    }
  }, [socket]);

  return (
    <ThemeProvider theme={theme}>
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Leaderboard
        </Typography>
        <StyledTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>Team Name</TableCell>
              <TableCell align="right">Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderboard.map((team, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {team.teamName}
                </TableCell>
                <TableCell align="right">{team.points}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </StyledTable>
      </StyledPaper>
    </ThemeProvider>
  );
};

export default StaticLeaderboard;
