import React, {useState, useEffect} from "react";
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography} from "@mui/material";
import {useSocket} from "../contexts/SocketContext";

interface UnanimousYesPopupProps {
    open: boolean;
    leaderAction: string;
    isLeader: boolean;
    sid: string;
    teamName: string;
    onDecision: (decision: boolean) => void // true => confirmation; false => rejection
    onClose: () => void;
}

const UnanimousYesPopup: React.FC<UnanimousYesPopupProps> =
    ({
         open,
         leaderAction,
         isLeader,
         sid,
         teamName,
         onDecision,
         onClose
     }) => {
        const {socket} = useSocket();
        const [voted, setVoted] = useState(false);
        const [waitingMessage, setWaitingMessage] = useState("team members");
        const [showOverrideButton, setShowOverrideButton] = useState(false);


        useEffect(() => {
            // console.log('useEffect triggered: open:', open, 'socket:', socket);
            if (socket && open) {
                console.log("Socket connected and popup is open. Listening for server responses...");
                console.log("sid is: ", sid);
                // Listen for final decision from the server
                socket.on('actionRejected', () => {
                    console.log("Action rejected by server.");
                    onDecision(false);
                    onClose();
                });

                socket.on('actionConfirmed', () => {
                    console.log("Action confirmed by server.");
                    onDecision(true);
                    onClose();
                });

                socket.on('updateVoteStatus', (data) => {
                    console.log("updateVoteStatus received");
                    console.log("data is: ", data);
                    const { notYetVoted } = data;
                    console.log("notYetVoted is: ", notYetVoted);
                    if (notYetVoted.length > 0) {
                        const waitingMessage = `${notYetVoted.join(', ')}`;
                        console.log("waitingMessage is: ", waitingMessage);
                        setWaitingMessage(waitingMessage);
                    }
                });

                // Cleanup listeners when component unmounts or popup closes
                return () => {
                    console.log("Cleaning up listeners for actionRejected and actionConfirmed...");
                    socket.off('actionRejected');
                    socket.off('actionConfirmed');
                    socket.off('updateVoteStatus');
                };
            }
        }, [socket]);


        useEffect(() => {
            if (open && isLeader) {
                const timer = setTimeout(() => {
                    setShowOverrideButton(true);
                }, 15000); // 15 seconds in milliseconds

                // Cleanup the timer when the component unmounts or dependencies change
                return () => clearTimeout(timer);
            }
        }, [open, isLeader]);


        const handleResponse = (response: boolean) => {
            console.log("Team member response received:", response);
            if (socket) {
                console.log(`Emitting response to server: { response: ${response}, teamName: ${teamName}, sid: ${sid} }`);
                socket.emit('teamMemberResponse', {response, teamName, sid});
                setVoted(true);
            }
        };

        const handleDialogClose = (event: object, reason: string) => {

            if (reason === "backdropClick" || reason === "escapeKeyDown") {
                return;
            }
            onClose();

        }

        const handleOverride = () => {
            if (socket) {
                socket.emit('overrideVote', { teamName });
                console.log(`Override vote emitted: { teamName: ${teamName}.`);
            }
        };

        return (
            <Dialog open={open} onClose={handleDialogClose} disableEscapeKeyDown>
                <DialogTitle>Team Decision Required</DialogTitle>
                <DialogContent>
                    {isLeader || voted ? (
                        <Typography>Waiting for {waitingMessage} to vote...</Typography>
                    ) : (
                        <>
                            <Typography>{leaderAction}</Typography>
                            <Typography>Do you agree with this action?</Typography>
                        </>
                    )}
                </DialogContent>
                {!isLeader && !voted && (
                    <DialogActions>
                        <Button onClick={() => handleResponse(true)} color="primary"
                                data-cy="agree-button">Agree</Button>
                        <Button onClick={() => handleResponse(false)} color="secondary"
                                data-cy="disagree-button">Disagree</Button>
                    </DialogActions>
                )}
                {isLeader && showOverrideButton && (
                    <DialogActions>
                        <Button onClick={handleOverride} color="primary">
                            Override
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    };

export default UnanimousYesPopup