import React, {useEffect, useState} from "react";
import {useSocket} from "../../../contexts/SocketContext";
import {useDrop} from "react-dnd";
import {styled, ThemeProvider} from "@mui/material/styles";
import {theme} from "../../../palette";
import {Box, Paper, Typography} from "@mui/material";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LockIcon from "@mui/icons-material/Lock";
import SecurityIcon from "@mui/icons-material/Security";
import InstagramIcon from "@mui/icons-material/Instagram";
import {BucketProps, ItemTypes} from "../types";


const StyledBucket = styled(Paper)(({ theme }) => ({
    minHeight: '60px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: '#007bff',
    color: '#40BABF',
    fontSize: '0.8rem',
    textAlign: 'center',
    transition: 'background-color 0.2s ease',
    border: 'solid #3CA8AD',
}));

const Bucket: React.FC<BucketProps> = ({ policy, onDrop, label}) => {
    const {socket} = useSocket();
    const [rippleVisible, setRippleVisible] = useState(false);
    const [incorrectRippleVisible, setIncorrectRippleVisible] = useState(false);

    // emit events for color ripples, etc. so all players in team can see them
    useEffect(() => {
        if(socket) {
            socket.on('broadcastRippleChange', (data) => {
                if(policy === data.policy) {
                    setRippleVisible(data.rippleCVisible);
                    setIncorrectRippleVisible(data.rippleIVisible);

                    setTimeout(() => {
                        setRippleVisible(false);
                        setIncorrectRippleVisible(false);
                    }, 1000)
                }
            })
        }
    }, [socket]);

    const [{ isOver, canDrop }, ref] = useDrop({
        accept: ItemTypes.CARD,
        drop: (item: { id: number; policy: string }) => {
            if (socket) {
                socket.emit('broadcastCardDrag', { dragged: false, id: item.id });
            }
            onDrop(policy);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <ThemeProvider theme={theme}>
            <StyledBucket
                ref={ref}
                elevation={2}
                style={{
                    backgroundColor: rippleVisible ? '#97DBB3' : (incorrectRippleVisible ? '#F09A9A' : (isOver && canDrop ? '#A4DDE0' :'white')),
                    alignContent: "center"
                }}
                data-cy={`bucket-${label}`}
            >
                <Box display={"flex"} gap={5}>
                    <CloudQueueIcon style={{display: label === "A" ? "inline" : "none"}}/>
                    <MailOutlineIcon style={{display: label === "B" ? "inline" : "none"}}/>
                    <CheckCircleOutlineIcon style={{display: label === "C" ? "inline" : "none"}}/>
                    <LockIcon style={{display: label === "D" ? "inline" : "none"}}/>
                    <SecurityIcon style={{display: label === "E" ? "inline" : "none"}}/>
                    <InstagramIcon style={{display: label === "F" ? "inline" : "none"}}/>
                    <Typography color="#3CA8AD" variant="body2" style={{
                        fontSize: 'min(0.9rem, 1.2vw)'
                    }}>{label + ". " + policy}</Typography>
                </Box>
            </StyledBucket>
        </ThemeProvider>
    );
};

export default Bucket;