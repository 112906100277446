
// Props taken by the question popup (whenever the user clicks on an object)
import React, {ChangeEvent, useEffect, useState} from "react";
import {useSocket} from "../../contexts/SocketContext";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "../../palette";
import {
    Alert, Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography
} from "@mui/material";


// The reasons for each answer, for display once the player gets a question right.
const whys: {[key: string]: string[]} = {"Smart Thermostat": [
        "Firmware Updates: Regular updates often include patches for security vulnerabilities that could be exploited by hackers. Keeping the firmware up-to-date helps protect against potential cyber attacks.",
        "Strong Passwords: Using a strong, unique password reduces the risk of unauthorized access. Weak or reused passwords can be easily guessed or cracked, compromising your system's security."
    ],
    "Router": [
        "WPA3 Encryption:  WPA3 is the latest Wi-Fi security protocol, offering stronger encryption and better protection against attacks such as brute force attempts. It provides more robust security than its predecessors (WPA2).",
        "Strong, Unique Password: A strong, unique password is harder for attackers to guess or crack, reducing the likelihood of unauthorized access."
    ],
    "Google Home": [
        "Prevent Unauthorized Listening: Even though Google Home devices are designed to activate only when they detect the wake word, there is a possibility of accidental activation. Turning off the microphone ensures that your conversations remain private and are not inadvertently recorded.",
        "Data Security: Sensitive information discussed in your home could be potentially captured and transmitted. Disabling the microphone minimizes the risk of this data being stored or accessed by unauthorized parties."
    ],
    "Computer": [
        "Protection Against Vulnerabilities: Security patches fix known vulnerabilities in the operating system and software that could be exploited by hackers. Keeping your system updated helps protect against these threats.",
        "Defense Against Malware: Many updates include improvements to security features that help protect against viruses, spyware, ransomware, and other malicious software."
    ],
    "Printer": [
        "Protection Against Vulnerabilities: Firmware updates often address security vulnerabilities that could be exploited by hackers. Keeping your printer's firmware updated helps protect it from unauthorized access and attacks.",
        "Prevention of Data Breaches: Printers can store sensitive information such as print jobs and network credentials. Updated firmware helps safeguard this data against breaches."
    ],
    "Smart Garbage Can": [
        "Improved Encryption: Updates may include enhanced encryption methods, making it harder for attackers to intercept or manipulate data transmitted by the device.",
        "Strong Passwords: Using a strong, unique password reduces the risk of unauthorized access. Weak or reused passwords can be easily guessed or cracked, compromising your system's security. Default passwords are often the same on all of the devices produced by the manufacturer, making it easy for an attacker to access your device."
    ],
    "Nanny Cam": [
        "Limit Exposure: By segregating smart home devices from your primary network, you reduce the risk of them being a potential entry point for attackers to access more sensitive devices like your personal computers or smartphones.",
        "Contain Breaches: If a smart device on the dedicated network is compromised, the attacker's access is limited to that network, preventing the breach from spreading to your primary network."
    ],
    "": []};

// The answer letters for each object's question
const answers : {[key: string]: string} = {"Smart Thermostat": "A", "Router": "B", "Computer": "D", "Printer": "B", "Google Home": "C", "Smart Garbage Can": "A", "Nanny Cam": "B"};

// The options for each object's question
const questions : {[key: string]: string[]} = {"Smart Thermostat": [
        "A. Regularly update the smart thermostat's firmware and use a strong, unique password.",
        "B. Keep the smart thermostat on its factory settings to ensure it functions correctly.",
        "C. Connect the smart thermostat to a public Wi-Fi network for better performance.",
        "D. Use the default password provided by the manufacturer. "
    ],
    "Router": [
        "A. Use the default SSID and password that come with the router.",
        "B. Use WPA3 encryption and a strong, unique password for your Wi-Fi network.",
        "C. Disable the router's firewall to improve connection speed.",
        "D. Keep the Wi-Fi network open and unencrypted for easier access. "
    ],
    "Google Home": [
        "A. Use Google Home to take notes on the work you are performing.",
        "B. Always keep the microphone on for quick responses.",
        "C. Turn off the microphone when discussing sensitive/confidential information.",
        "D. Connect your Google Home device to an unencrypted Wi-Fi network for better compatibility."
    ],
    "Computer": [
        "A. Install only trusted software from reputable sources and disable automatic updates for stability.",
        "B. Use the same password across multiple accounts for convenience.",
        "C. Ensure your day-to-day user account has administrative access. ",
        "D. Keep your operating system and software up to date with the latest security patches."
    ],
    "Printer": [
        "A. Keep the printer's default settings for ease of use.",
        "B. Regularly update the printer's firmware to ensure the latest security patches are installed.",
        "C. Connect the printer to your home network without a password for quick access.  ",
        "D. Allow remote printing from any device to enhance convenience."
    ],
    "Smart Garbage Can": [
        "A. Install the latest firmware updates and change the default password to something more secure, like 'Tr@shC4n$ecure'.",
        "B. Only connect it to an open WiFi network, keeping it separate from your main devices.",
        "C. Disable the WiFi feature entirely and just use it as a regular garbage can, because who really needs a smart garbage can?",
        "D. Wrap the garbage can in aluminum foil to block all WiFi signals and prevent hacking."
    ],
    "Nanny Cam": [
        "A. Disable the nanny cam's WiFi feature and use an Ethernet cable instead.",
        "B. Set up a separate, secured WiFi network that's dedicated to smart home devices.",
        "C. Never update the nanny cam, as it was secure when you bought it.",
        "D. Back up your nanny cam to the public cloud."
    ],
    "" : ["", "", "", "",""]};


interface QuestionPopupProps {
    question: string,
    open: boolean,
    isLeader: boolean,
    onClose: () => void;
    onSubmit: (question: string, answer: string) => void,
    onMistake: (question: string) => void
}

// The popup that displays when the player clicks on an object. Shows the question, option radio buttons, and submit / cancel buttons.
const QuestionPopup: React.FC<QuestionPopupProps> = ({question, open, isLeader, onClose, onSubmit, onMistake}) => {
    const[selectedAnswer, setSelectedAnswer] = useState("");
    const[questionStatus, setQuestionStatus] = useState("neutral");


    const { socket } = useSocket();

    // listen to server's messages, both when leader and not leader
    useEffect(() => {
        // TODO: implement leaderActionSubmitted logic
        if(socket) {
            // when selected answer changes
            socket.on("broadcastMCQChoice", (data) => {
                setSelectedAnswer(data.choice);
            })
            // when question status (correct / incorrect / neutral) changes
            socket.on("broadcastQuestionStatus", (data) => {
                setQuestionStatus(data.status);
            })


            return () => {
                socket.off('broadcastMCQChoice');
                socket.off('broadcastQuestionStatus');
                socket.off('leaderActionSubmitted');
            };
        }

    }, [])



    // change the selected answer. should only have effect when leader
    const handleChangeAnswer = (event: ChangeEvent<HTMLInputElement>) => {
        if(socket && isLeader) {
            socket.emit("broadcastMCQChoice", {choice: (event.target as HTMLInputElement).value});
        }
    }

    // submit the answer. should only have effect when leader
    const handleSubmitAnswer = (question: string, answer: string) => {
        console.log("At the first initial(popup) stage, question is: ", question, " and answer is: ", answer);
        if(socket && isLeader) {
            onSubmit(question, answer);
        }
    }

    // when the popup is closed, clear currently selected choice. should only have effect when leader
    const handleOnClose = () => {
        if(socket && isLeader) {
            socket.emit("broadcastMCQChoice", {choice: ""});
        }
        onClose();
    }


    return(
        <ThemeProvider theme={theme}>
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>{question}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <Typography>Select the best practice associated with this device.</Typography>
                            <RadioGroup name="answers-group" value={selectedAnswer} onChange={handleChangeAnswer}>
                                <FormControlLabel value="A"
                                                  control={<Radio />}
                                                  label={questions[question].at(0)}
                                                  data-cy="answer-A"/>
                                <FormControlLabel value="B" control={<Radio />}
                                                  label={questions[question].at(1)}
                                                  data-cy="answer-B"/>
                                <FormControlLabel value="C"
                                                  control={<Radio />}
                                                  label={questions[question].at(2)}
                                                  data-cy="answer-C"/>
                                <FormControlLabel value="D"
                                                  control={<Radio />}
                                                  label={questions[question].at(3)}
                                                  data-cy="answer-D"/>
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Alert variant={"outlined"} severity={"success"} style={{display: questionStatus === "success" ? "flex" : "none"}}>
                        <Typography sx={{paddingBottom: 2}}>Correct! Here's why...</Typography>
                        {whys[question].map((why) => (
                            <Typography>{why}</Typography>
                        ))}
                    </Alert>
                    <Alert variant={"outlined"} severity={"error"} style={{display: questionStatus === "error" ? "flex" : "none"}}>
                        <Typography>Incorrect, please try again.</Typography>
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose} variant={questionStatus === "success" ? "contained": "text"} color="tur2" disabled={!isLeader} data-cy="continue-button">{questionStatus === "success" ? "Continue" : "Cancel"}</Button>
                    <Button onClick={() => handleSubmitAnswer(question, selectedAnswer)} color="turquoise" style={{display: questionStatus === "success" ? "none" : "flex"}} disabled={!isLeader || questionStatus === "success"} data-cy="submit-button">Submit</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}

export default QuestionPopup;