import React from 'react';
import { Box, Typography } from '@mui/material';
import leftSideImage from '../assets/leftside.png'; // Update the path to your image

// UI for the login page showing the visier logo and background
const Background: React.FC = () => {
  return (
    <Box sx={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'row',
      background: '#00A6A6',
    }}>
      <Box sx={{
        width: { xs: '100%', md: '50%' },
        backgroundImage: `url(${leftSideImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: { xs: 'none', md: 'block' },
      }} />
      <Box sx={{
        width: { xs: '100%', md: '50%' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 2,
        pt: 8, // Padding top for spacing
      }}>
        <Typography variant="h2" sx={{ color: 'white', textAlign: 'center', fontWeight: 'bold' }}>
          Welcome to the Visier Escape Room!
        </Typography>
      </Box>
    </Box>
  );
};

export default Background;
